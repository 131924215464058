import {
    getProvince,
    getAmphurs,
    getTambons,
    getEquipment,
    getMemberPosition,
    getPersonalProject,
    getPrefix,
    getProjectRD
} from '../services/Dropdown'
import LocalStorage from './LocalStorage'
import jwt_decode from "jwt-decode";

export const findRowInDict = (obj, id) => {
    for (let row of obj) {
        if (row.id === id) {
            return row
        }
    }
}

export const prepareRCCPersonalProjectDropdown = async (setRCCPersonalProjectDropDown) => {
    getPersonalProject().then(res => {
        let temp_list = []
        let res_data = res.data.data
        for (let row_data of res_data) {
            temp_list.push({
                id: row_data.id,
                name: row_data.attributes.project_name,
            })
        }
        setRCCPersonalProjectDropDown(temp_list)
    })
}

export const prepareRCCMemberDropdown = async (setRCCMemberDropDown, roles) => {
    getMemberPosition().then(res => {
        let temp_list = []
        let res_data = res.data.data

        for (let row_data of res_data) {
            let name = row_data?.attributes?.community_position_name

            temp_list.push({
                id: row_data?.id,
                name: name,
                max_count: row_data?.attributes?.max_count - roles.filter(role => role === name).length
            })
        }
        setRCCMemberDropDown(temp_list)
    })
}

export const prepareEquipmentDropdown = async (setEquipmentDropDown) => {
    getEquipment().then(res => {
        let temp_list = []
        let res_data = res.data.data
        // console.log(res_data);
        for (let row_data of res_data) {
            temp_list.push({
                id: row_data.id,
                name: row_data.attributes.equipment_name,
            })
        }
        setEquipmentDropDown(temp_list)
    })
}

export const perpareProvinceDropdown = async (setProvinceDropDown) => {
    let access_token = LocalStorage.getAccessToken();
    var decoded_token = jwt_decode(access_token);

    let res = await getProvince(decoded_token?.user_id)
    // console.log('res',res)
    let temp_list = []
    let res_data = res.data.data
    for (let row_data of res_data) {
        temp_list.push({
            id: row_data.id,
            name: row_data.attributes.province_name,
            code: row_data.attributes.province_code,
        })
    }
    setProvinceDropDown(temp_list)
    return temp_list
}

export const perparePrefixDropdown = async (setPrefixDropDown) => {
    getPrefix().then(res => {
        let temp_list = []
        let res_data = res.data.data
        for (let row_data of res_data) {
            temp_list.push({
                id: row_data.id,
                name: row_data.attributes.prefix_name,
            })
        }
        setPrefixDropDown(temp_list)
    })
}

export const handleProvince = async (
    value,
    setAmphurInit,
    setAmphurList,
    setTambonInit,
    setTambonList,
) => {
    // setSelectedProvince(value)
    if (value === 0) {
        setAmphurInit("กรุณาเลือกจังหวัด");
        setTambonInit("กรุณาเลือกจังหวัด");
        setAmphurList([])
        setTambonList([])
    } else {
        setAmphurInit("กรุณาเลือกอำเภอ");
        setTambonInit("กรุณาเลือกอำเภอ");
        setTambonList([])
        let res = await getAmphurs(value)
        let temp_list = []
        let res_data = res.data.data
        for (let row_data of res_data) {
            temp_list.push({
                id: row_data.id,
                name: row_data.attributes.amphur_name,
                code: row_data.attributes.amphur_code,
            })
        }
        setAmphurList(temp_list)
        return temp_list
    }
}

export const handleAmphur = async (
    value,
    setTambonInit,
    setTambonList,

) => {
    if (value === 0) {
        setTambonInit("กรุณาเลือกอำเภอ");
        setTambonList([])
        return []
    } else {
        setTambonInit("กรุณาเลือกตำบล");
        let res = await getTambons(value)
        // console.log('res', res)
        let temp_list = []
        let res_data = res.data.data
        for (let row_data of res_data) {
            temp_list.push({
                id: row_data.id,
                name: row_data.attributes.tambon_name,
                code: row_data.attributes.tambon_code,
                zipcode: row_data.attributes.postcode
            })
        }
        setTambonList(temp_list)
        // console.log('templist', temp_list)
        return temp_list
    }
}

export const prepareProjectRDDropdown = async (setProjectRDDropdown) => {
    let access_token = LocalStorage.getAccessToken();
    var decoded_token = jwt_decode(access_token);

    let res = await getProjectRD()
    console.log("project rd", res.data);
    let temp_list = []
    let res_data = res.data.data

    for (let row_data of res_data) {
        let name = row_data?.attributes?.project_name
        temp_list.push({
            id: row_data?.id,
            name: name,
        })
    }
    console.log("project rd dropdown",  temp_list);
    setProjectRDDropdown(temp_list)
}