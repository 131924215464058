import axios from 'axios';
import config from '../config.json';
import LocalStorage from '../utils/LocalStorage';

export const getCommittee = async (filters) => {
    let token = LocalStorage.getAccessToken();
    return axios.post(
        config.BASE_API + "/new/rcc/committee/list",
        {
            filter: filters
        },
        {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        }
    )
}

export const addRCCCommittee = async (data) => {
    let token = LocalStorage.getAccessToken();
    return axios.post(
      config.BASE_API + "/new/rcc/committee/add",
      data,
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        }
      }
    )

  }

  export const deleteRCCCommittee = async (id) => {
    let token = LocalStorage.getAccessToken();
    return axios.delete(
      config.BASE_API + "/new/rcc/committee/"+id,
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        }
      }
    )
  }