import React, { useState, useEffect } from 'react';
import {
    Dialog,
    DialogContent,
    DialogTitle,
    Grid,
    FormControl,
    Button,
    DialogActions,
    TextField,
    Select,
    MenuItem,
} from '@mui/material';

import {
    getEquipment,
} from '../services/Dropdown'

const RCCEquipmentForm = ({ equipment_list, setEquipmentList, is_open, setIsOpen, mode }) => {

    const [community_center_equipment_dropdown, setCommunityCenterEquipmentDropdown] = useState([])
    const [community_center_equipment_selected, setCommunityCenterEquipmentSelected] = useState(0)
    const [community_center_equipment_number, setCommunityCenterEquipmentNumber] = useState(0)
    const [community_center_equipment_registation_year, setCommunityCenterEquipmentRegistationYear] = useState(0)
    const [community_center_equipment_acquired_project, setCommunityCenterEquipmentAcquiredProject] = useState("")

    const findRowInDict = (obj, id) => {
        for (let row of obj) {
          if (row.id === id) {
            return row
          }
        }
      }

    const handleAddEquipment = () => {
        if (community_center_equipment_selected === 0) {
            alert("คุณยังไม่ได้เลือกอุปกรณื \n กรุณาเลือกอุปกรณ์")
          } else if (community_center_equipment_number === 0) {
            alert("คุณยังไม่ได้เพิ่มจำนวนอุปกรณ์ \n กรุณาเพิ่มจำนวนอุปกรณ์")
          } else {
            let equipment = findRowInDict(community_center_equipment_dropdown, community_center_equipment_selected)
            let temp_list = [];
            let index = 0;
            for (let data of equipment_list) {
              index++;
              temp_list.push({
                index: index,
                id: data.id,
                equipment_name: data.equipment_name,
                equipment_number: data.equipment_number,
                equipment_registation_year: data.equipment_registation_year,
                equipment_acquired_project: data.equipment_acquired_project,
              })
            }
            index++;
            temp_list.push({
              index: index,
              id: equipment.id,
              equipment_name: equipment.name,
              equipment_number: community_center_equipment_number,
              equipment_registation_year: community_center_equipment_registation_year,
              equipment_acquired_project: community_center_equipment_acquired_project,
            })
            setEquipmentList(temp_list)
            setIsOpen(false)
          }
    }
    useEffect(async () => {

        getEquipment().then(res => {
            let temp_list = []
            let res_data = res.data.data
            // console.log(res_data);
            for (let row_data of res_data) {
                temp_list.push({
                    id: row_data.id,
                    name: row_data.attributes.equipment_name,
                })
            }
            setCommunityCenterEquipmentDropdown(temp_list)
        })
    }, []);

    return (
        <Dialog
            open={is_open}
            onClose={(event) => setIsOpen(false)}
            fullWidth
            aria-labelledby="AddUser-Dialog-title"
            aria-describedby="AddUser-Dialog-Content"
            sx={{
                "& .MuiDialog-container": {
                    "& .MuiPaper-root": {
                        width: "100%",
                        maxWidth: "800px",  // Set your width here
                    },
                },
            }}
        >
            <DialogTitle id="AddUser-Dialog-title">เพิ่มอุปกรณ์</DialogTitle>
            <DialogContent id="AddUser-Dialog-Content">
                <Grid container spacing={2} sx={{ paddingTop: 2 }}>
                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={community_center_equipment_selected}
                                variant="outlined"
                                label="อุปกรณ์"
                                fullWidth
                                onChange={(event) => {
                                    setCommunityCenterEquipmentSelected(event.target.value);
                                }}
                            >
                                <MenuItem key={0} value={0}>กรุณาเลือกอุปกรณ์</MenuItem>
                                {community_center_equipment_dropdown.map((item, index) => {
                                    return (<MenuItem key={index + 1} value={item.id}>{item.name}</MenuItem>)
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <TextField
                                fullWidth
                                id="outlined-basic"
                                label="จำนวน"
                                variant="outlined"
                                type="number"
                                value={community_center_equipment_number}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onChange={event => setCommunityCenterEquipmentNumber(event.target.value)}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <TextField
                                fullWidth
                                id="outlined-basic"
                                label="ปีที่ได้รับ"
                                variant="outlined"
                                type="number"
                                value={community_center_equipment_registation_year}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onChange={event => setCommunityCenterEquipmentRegistationYear(event.target.value)}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl fullWidth>
                            <TextField
                                fullWidth
                                id="outlined-basic"
                                label="ได้รับจากโครงการ"
                                variant="outlined"
                                value={community_center_equipment_acquired_project}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onChange={event => setCommunityCenterEquipmentAcquiredProject(event.target.value)}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={handleAddEquipment}
                    variant="contained"
                    color="success">
                    เพิ่มอุปกรณ์
                </Button>

                <Button
                    variant="contained"
                    onClick={(event) => setIsOpen(false)}
                    color="error">
                    ยกเลิก
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default RCCEquipmentForm;