import axios from 'axios';
import config from '../config.json';
import LocalStorage from '../utils/LocalStorage';

export const callService = async (uri, obj) => {
  let token = LocalStorage.getAccessToken();
  return axios.post(
    config.BASE_API + uri,
    obj,
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    }
  )
}

export const getEquipment = async () => {
  let token = LocalStorage.getAccessToken();
  return axios.get(
    config.BASE_API + "/equipment",
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    }
  )
}

export const getMemberPosition = async () => {
  let token = LocalStorage.getAccessToken();
  return axios.get(
    config.BASE_API + "/memberposition",
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    }
  )
}

export const getRCCStatus = async () => {
  let token = LocalStorage.getAccessToken();
  return axios.get(
    config.BASE_API + "/rccstatus",
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    }
  )
}

export const getPersonals = async () => {
  let token = LocalStorage.getAccessToken();
  return axios.get(
    config.PAF_API + "/personals",
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    }
  )
}

export const getProvince = async (user_id) => {
  let token = LocalStorage.getAccessToken();
  return axios.get(
    config.PAF_API + "/provinces/" + user_id,
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    }
  )
}

export const getAmphurs = async (id) => {
  let token = LocalStorage.getAccessToken();
  return axios.get(
    config.PAF_API + "/amphurs/" + id,
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    }
  )
}

export const getTambons = async (id) => {
  let token = LocalStorage.getAccessToken();
  return axios.get(
    config.PAF_API + "/tambons/" + id,
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    }
  )
}

export const getPersonalProject = async () => {
  let token = LocalStorage.getAccessToken();
  return axios.get(
    config.PAF_API + "/projects",
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    }
  )
}

export const getFarmer = async () => {
  let token = LocalStorage.getAccessToken();
  return axios.get(
    config.PAF_API + "/farmers",
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    }
  )
}

export const getRiceType = async () => {
  let token = LocalStorage.getAccessToken();
  return axios.get(
    config.BASE_API + "/rice-type",
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    }
  )
}

export const getRiceSeedType = async () => {
  let token = LocalStorage.getAccessToken();
  return axios.get(
    config.BASE_API + "/rice-seed-type",
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    }
  )
}

export const getPrefix = async () => {
  let token = LocalStorage.getAccessToken();
  return axios.get(
    config.PAF_API + "/prefixes",
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    }
  )
}

export const getProjectRD = async () => {
  let token = LocalStorage.getAccessToken();
  return axios.get(
    config.BASE_API + "/project-rd",
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    }
  )
}