import axios from 'axios';
import config from '../../config.json';

export const adminLogin = async (obj) => {
  console.log(obj);
  return axios.post(
    config.AUTH_API + '/authen/login/',
    obj,
    {
      headers: {
        'Content-Type': 'application/json'
      }
    }
  )
} 
