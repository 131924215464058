export const timeDifference = (previous) => {

    var msPerMinute = 60 * 1000;
    var msPerHour = msPerMinute * 60;
    var msPerDay = msPerHour * 24;
    var msPerMonth = msPerDay * 30;
    var msPerYear = msPerDay * 365;
    var now = new Date();
    var last = new Date(previous)
    var elapsed = now - last;
    // console.log(now);
    // console.log(last);
    // console.log(elapsed);

    if (elapsed < msPerMinute) {
        return Math.round(elapsed / 1000) + ' วินาทีที่แล้ว';
    }

    else if (elapsed < msPerHour) {
        return Math.round(elapsed / msPerMinute) + ' นาทีที่แล้ว';
    }

    else if (elapsed < msPerDay) {
        return Math.round(elapsed / msPerHour) + ' ชั่วโมงที่แล้ว';
    }

    else if (elapsed < msPerMonth) {
        return Math.round(elapsed / msPerDay) + ' วันที่แล้ว';
    }

    else if (elapsed < msPerYear) {
        return Math.round(elapsed / msPerMonth) + ' เดือนที่แล้ว';
    }

    else {
        return Math.round(elapsed / msPerYear) + ' ปีที่แล้ว';
    }
}

export const toThaiDateString = (date) => {
    let monthNames = [
        "มกราคม", "กุมภาพันธ์", "มีนาคม", "เมษายน",
        "พฤษภาคม", "มิถุนายน", "กรกฎาคม", "สิงหาคม.",
        "กันยายน", "ตุลาคม", "พฤศจิกายน", "ธันวาคม"
    ];

    let year = date.getFullYear() + 543;
    let month = monthNames[date.getMonth()];
    let numOfDay = date.getDate();


    return `${numOfDay} ${month} ${year} `;
}