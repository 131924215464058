import React, { useState, useEffect } from 'react';
import AdminLayout from '../../components/admin/AdminLayout'
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField'
import { DataGrid } from '@mui/x-data-grid';

import {
  callService,
  callGetService,
  callPutService,
  callPostService
} from '../../services/Service'

export default function CenterList() {
  const [user_list, setUserList] = useState([])
  const [selected_user_id, setSelectedUserID] = useState(0)
  const [selected_status, setSelectedStatus] = useState(false)
  const [alert_header, setAlertHeader] = useState("")
  const [alert_body, setAlertBody] = useState("")
  const [alert_mode, setAlertMode] = useState("")
  const [open_alert, setOpenAlert] = useState(false);
  const [search_rcc, setSearchRCC] = useState("");

  const [page_count, setPageCount] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [row_count, setRowCount] = useState(0);
  const [max_lasted_page_update, setMaxLastedPageUpdate] = useState(0);

  const handleClose = () => {
    setOpenAlert(false);
    setSelectedUserID(0)
    setSelectedStatus(false)
  };

  const columns = [
    {
      field: 'index',
      headerName: 'ลำดับ',
      maxWidth: 50,
      flex: 1
    },
    {
      field: 'rcc_code',
      headerName: 'รหัสศูนย์ข้าวชุมชน',
      minWidth: 150,
      flex: 1
    },
    {
      field: 'rcc_name',
      headerName: 'ชื่อศูนย์ข้าวชุมชน',
      minWidth: 250,
      flex: 1
    },
    {
      field: 'rcc_status',
      headerName: 'สถานะ',
      minWidth: 150,
      flex: 1
    },
    {
      field: 'rcc_continue_date',
      headerName: 'วันที่ต่ออายุล่าสุด',
      minWidth: 150,
      flex: 1
    },
    // { 
    //   field: 'rcc_action', 
    //   headerName: 'เปลี่ยนสถานะ', 
    //   minWidth: 150,
    //   flex:1
    // },
    {
      field: "edit",
      headerName: "รายละเอียด",
      sortable: false,
      maxWidth: 100,
      flex: 1,
      disableClickEventBubbling: true,
      renderCell: (cellValues) => {
        return <IconButton
          color="secondary"
          aria-label="add an alarm"
          onClick={(event) => {
            handleEditClick(event, cellValues);
          }} >
          <EditIcon />
        </IconButton>
      }
    },
    // {
    //   field: "delete",
    //   headerName: "ลบ",
    //   sortable: false,
    //   maxWidth: 100,
    //   flex:1,
    //   disableClickEventBubbling: true,
    //   renderCell: (cellValues) => {
    //     return <IconButton
    //       color="secondary"
    //       aria-label="add an alarm"
    //       onClick={(event) => {
    //         handleDeleteClick(event, cellValues);
    //       }} >
    //       <DeleteIcon />
    //     </IconButton>
    //   }
    // }
  ];

  const handleEditClick = (event, cellValues) => {
    event.preventDefault();
    window.location.href = "/rcc/edit/" + cellValues.row.id
  }

  const handleAddClick = (event) => {
    event.preventDefault();
    window.location.href = "/rcc/add";
  }

  const handleAlertButton = () => {
    if (alert_mode === "delete") {
      let obj = {
        data: {
          is_active: !selected_status,
        }
      }
      console.log(obj);
      callPutService("/rcc/" + selected_user_id, obj)
        .then(res => {
          setOpenAlert(false)
          createRCCList()
        })
        .catch(err => {
          console.log(err);
        })
      // deleteRCC(obj)
      // callService("/users/delete", obj)
      //   .then(res => {
      //     handleClose()
      //     setUserList([])
      //     createUserList()
      //   }).catch(error => {
      //     console.log(error.response);
      //     if (error.response) {
      //       if (error.response.status === 401) {
      //         window.location.href = "/auth/login";
      //       }
      //     }
      //   })
    } else {
      let obj = {
        user_id: selected_user_id,
        active: !selected_status
      }
    }
  }

  const createRCCList = () => {
    callGetService("/rcc").then(res => {
      let temp_list = []
      let res_data = res.data.data
      // console.log(res.data);
      setRowCount(res.data.meta.pagination.total)
      setPageCount(res.data.meta.pagination.pageSize)
      // console.log(res_data);
      let index = 0;
      for (let row_data of res_data) {
        // console.log(row_data.attributes);
        index++;
        temp_list.push({
          index: index,
          id: row_data.id,
          rcc_code: row_data.attributes.old_rcc_id,
          rcc_name: row_data.attributes.rice_community_center_name,
          rcc_status: row_data.attributes.rice_community_center_status.data.attributes.community_center_status_name,
          rcc_continue_date: row_data.attributes.community_center_continue_date,
          is_active: row_data.attributes.is_active,
        })
      }
      setUserList(temp_list)
    })
  }

  const handleSearchClick = (keyword) => {
    callPostService("/rcc/search", { 'search': keyword }).then(res => {
      let temp_list = []
      let res_data = res.data.data
      console.log(res_data);
      setRowCount(res.data.meta.pagination.total)
      let index = 0;
      for (let row_data of res_data) {
        // console.log(row_data.attributes);
        index++;
        temp_list.push({
          index: index,
          id: row_data.id,
          rcc_code: row_data.attributes.old_rcc_id,
          rcc_name: row_data.attributes.rice_community_center_name,
          rcc_status: row_data.attributes.rice_community_center_status.data.attributes.community_center_status_name,
          rcc_continue_date: row_data.attributes.community_center_continue_date,
          is_active: row_data.attributes.is_active,
        })
      }
      setUserList(temp_list)
    })
  }
  
  const changePage = (new_page) => {
    if (new_page > max_lasted_page_update) {
      setMaxLastedPageUpdate(new_page)
      callPostService(
        "/rcc/search",
        {
          'pagination': {
            'page': new_page + 1,
            'page_size': page_count
          }
        }
      ).then(res => {
        let temp_list = [];
        let index = 0;
        for (let data of user_list) {
          index++;
          temp_list.push({
            index: index,
            id: data.id,
            rcc_code: data.rcc_code,
            rcc_name: data.rcc_name,
            rcc_status: data.rcc_status,
            rcc_continue_date: data.rcc_continue_date,
            is_active: data.is_active,
          })
        }

        let res_data = res.data.data
        for (let row_data of res_data) {
          index++;
          temp_list.push({
            index: index,
            id: row_data.id,
            rcc_code: row_data.attributes.old_rcc_id,
            rcc_name: row_data.attributes.rice_community_center_name,
            rcc_status: row_data.attributes.rice_community_center_status.data.attributes.community_center_status_name,
            rcc_continue_date: row_data.attributes.community_center_continue_date,
            is_active: row_data.attributes.is_active,
          })
        }
        setUserList(temp_list)
      })
    }
  }

  useEffect(() => {
    createRCCList()
  }, []);

  return (
    <div style={{ margin: '2rem' }}>
      <AdminLayout />
      <Grid 
        container 
        className='content-box' 
        spacing={1}
        sx={{mt:5}}
      >
        <Grid item xs>
          <Typography variant='h4'>รายการศูนย์ข้าวชุมชน</Typography>
        </Grid>
        <Grid item >
          <Button
            variant="contained"
            onClick={handleSearchClick}
          >
            Export รายการศูนย์ข้าวชุมชน
          </Button>
        </Grid>

        <Grid item >
          <Button
            variant="contained"
            onClick={handleSearchClick}
          >
            Export รายการสมาชิกศูนย์ข้าวชุมชน
          </Button>
        </Grid>
        <Grid item >
          <Button
            variant="contained"
            onClick={handleAddClick}
          >
            เพิ่มศูนย์ข้าวชุมชน
          </Button>
        </Grid>
        <br />
        <br />
        <br />
        <Grid item xs={12}>
          <TextField
            fullWidth
            id="outlined-basic"
            label="คำค้นหา"
            variant="outlined"
            InputLabelProps={{
              shrink: true,
              autoComplete:'off'
          }}
            value={search_rcc}
            onChange={event => {
              setSearchRCC(event.target.value)
              handleSearchClick(event.target.value)
            }}
          />
        </Grid>
        <Grid item xs={12} >
          <div style={{ height: 600, width: '100%', marginTop: '2rem' }}>
            <DataGrid
              style={{ backgroundColor: "#FFFFFF" }}
              rows={user_list}
              rowCount={row_count}
              columns={columns}
              pageSize={page_count}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              onPageChange={(newPage) => changePage(newPage)}
              rowsPerPageOptions={[]}
            />
          </div>
        </Grid>
      </Grid>
      <Dialog
        open={open_alert}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {alert_header}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {alert_body}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAlertButton} variant="contained" color="success">
            ตกลง
          </Button>
          <Button onClick={handleClose} variant="contained" color="error">
            ยกเลิก
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}