import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'
import { DataGrid } from '@mui/x-data-grid';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import RCCMemberForm from './RCCMemberForm';
import RCCEquipmentForm from './RCCEquipmentList';
import RCCQuestionnairForm from './RCCQuestionnairForm';
import RCCQuestionnairHistory from './RCCQuestionnairHistory';
import { v4 as uuid } from 'uuid';
import jwt_decode from "jwt-decode";

import {
  useLocation
} from 'react-router-dom';

import {
  getProvince,
  getAmphurs,
  getTambons,
  getRCCStatus
} from '../services/Dropdown'

import {
  callGetService,
  callService,
  callPutService,
  callPaFGetService,
  callPaFPostService
} from '../services/Service'
import LocalStorage from '../utils/LocalStorage';

const RCCForm = ({ mode }) => {
  const location = useLocation();
  const [button_title, setButtonTitle] = useState("")
  const [rcc_continue, setRCCContinue] = useState(false);

  // component variable
  const [is_member_modal_open, setIsMemberModalOpen] = useState(false)
  const [community_center_member_list, setCommunityCenterMemberList] = useState([])
  const member_columns = [
    { field: 'index', headerName: 'ลำดับ', width: 50 },
    { field: 'name', headerName: 'ชื่อ สมาชิก', width: 250 },
    { field: 'rcc_name', headerName: 'ตำแหน่งสมาชิก', width: 250 },
    { field: 'rcc_status', headerName: 'สถานะสมาชิก', width: 250 },
    { field: 'rcc_farm', headerName: 'จำนวนแปลงที่เข้าร่วม', width: 250 },
    {
      field: "delete",
      headerName: "ลบ",
      sortable: false,
      width: 100,
      disableClickEventBubbling: true,
      renderCell: (cellValues) => {
        return <IconButton
          color="secondary"
          aria-label="add an alarm"
          onClick={(event) => {
            handleDeleteMemberList(event, cellValues);
          }} >
          <DeleteIcon />
        </IconButton>
      }
    }
  ];

  const [is_equipment_modal_open, setIsEquipmentModalOpen] = useState(false);
  const [community_center_equipment_list, setCommunityCenterEquipmentList] = useState([])
  const equipment_columns = [
    { field: 'index', headerName: 'ลำดับ', width: 50 },
    { field: 'equipment_name', headerName: 'ชื่ออุปกรณ์', width: 250 },
    { field: 'equipment_number', headerName: 'จำนวน', width: 250 },
    { field: 'equipment_registation_year', headerName: 'ปีที่ได้รับ', width: 250 },
    { field: 'equipment_acquired_project', headerName: 'โครงการที่จัดซื้อ', width: 250 },
    {
      field: "delete",
      headerName: "ลบ",
      sortable: false,
      width: 100,
      disableClickEventBubbling: true,
      renderCell: (cellValues) => {
        return <IconButton
          color="secondary"
          aria-label="add an alarm"
          onClick={(event) => {
            handleDeleteEquipmentList(event, cellValues);
          }} >
          <DeleteIcon />
        </IconButton>
      }
    }
  ];

  const [is_questionair_form_open, setIsQestionnairForm] = useState(false)
  const [is_questionair_history_open, setIsQestionnairHistory] = useState(false)

  const [community_center_name, setCommunityCenterName] = useState("")
  const [community_center_established_date, setCommunityCenterEstablishedDate] = useState("")
  const [community_center_register_year, setCommunityCenterRegisterYear] = useState(0)
  const [community_center_address, setCommunityCenterAddress] = useState("")
  const [community_center_tambon_list, setCommunityCenterTambonList] = useState([])
  const [community_center_tambon_selected, setCommunityCenterTambonSelected] = useState(0)
  const [community_center_tambon_name, setCommunityCenterTambonName] = useState("กรุณาเลือกจังหวัด")
  const [community_center_amphur_list, setCommunityCenterAmphurList] = useState([])
  const [community_center_amphur_selected, setCommunityCenterAmphurSelected] = useState(0)
  const [community_center_amphur_name, setCommunityCenterAmphurName] = useState("กรุณาเลือกจังหวัด")
  const [community_center_province_list, setCommunityCenterProvinceList] = useState([])
  const [community_center_province_selected, setCommunityCenterProvinceSelected] = useState(0)
  const [community_center_province_name, setCommunityCenterProvinceName] = useState("")
  const [community_center_post_code, setCommunityCenterPostCode] = useState("")
  const [community_center_UTM_x, setCommunityCenterUTMX] = useState(0)
  const [community_center_UTM_y, setCommunityCenterUTMY] = useState(0)
  const [community_contract_address, setCommunityContractAddress] = useState("")
  const [community_contract_tambon_list, setCommunityContractTambonList] = useState([])
  const [community_contract_tambon_selected, setCommunityContractTambonSelected] = useState(0)
  const [community_contract_tambon_name, setCommunityContractTambonName] = useState("กรุณาเลือกจังหวัด")
  const [community_contract_amphur_list, setCommunityContractAmphurList] = useState([])
  const [community_contract_amphur_selected, setCommunityContractAmphurSelected] = useState(0)
  const [community_contract_amphur_name, setCommunityContractAmphurName] = useState("กรุณาเลือกจังหวัด")
  const [community_contract_province_list, setCommunityContractProvinceList] = useState([])
  const [community_contract_province_selected, setCommunityContractProvinceSelected] = useState(0)
  const [community_contract_province_name, setCommunityContractProvinceName] = useState("")
  const [community_contract_post_code, setCommunityContractPostCode] = useState("")
  const [community_center_founder_number, setCommunityCenterFounderNumber] = useState(0)
  const [community_center_funds, setCommunityCenterFunds] = useState(0)
  const [community_center_continue_date, setCommunityContinueDate] = useState("")
  const [is_have_certificate, setIsHaveCertificate] = useState(false)
  const [is_have_permise, setIsHavePermise] = useState(false)
  const [permise_code, setPermiseCode] = useState("")

  ///////////////////////////
  // handle button section //
  ///////////////////////////
  const handleBackButton = () => window.location.href = "/center/list";;

  const handleSaveButton = () => {
    console.log(community_center_member_list);
    if (mode === "add") {
      let acc_add = {
        data: {
          "community_center_code": uuid(),
          "rice_community_center_name": community_center_name,
          "community_center_established_date": community_center_established_date,
          "community_center_register_year": community_center_register_year,
          "community_center_address": community_center_address,
          "community_center_tambon_id": community_center_tambon_selected,
          "community_center_tambon_name": community_center_tambon_name,
          "community_center_amphur_id": community_center_amphur_selected,
          "community_center_amphur_name": community_center_amphur_name,
          "community_center_province_id": community_center_province_selected,
          "community_center_province_name": community_center_province_name,
          "community_center_post_code": community_center_post_code,
          "community_center_UTM_x": community_center_UTM_x,
          "community_center_UTM_y": community_center_UTM_y,
          "community_contract_address": community_contract_address,
          "community_contract_tambon_id": community_contract_tambon_selected,
          "community_contract_tambon_name": community_contract_tambon_name,
          "community_contract_amphur_id": community_contract_amphur_selected,
          "community_contract_amphur_name": community_contract_amphur_name,
          "community_contract_province_id": community_contract_province_selected,
          "community_contract_province_name": community_contract_province_name,
          "community_contract_postcode": community_contract_post_code,
          "community_center_founder_number": community_center_founder_number,
          "community_center_funds": community_center_funds,
          "community_center_continue_date": community_center_continue_date,
          "rice_community_center_status": 1,
          "community_center_continue_date": community_center_established_date,
          "is_have_certificate": is_have_certificate,
          "is_have_permise": is_have_permise,
          "permise_code": permise_code,
        }
      }
      callService("/rcc", acc_add)
      .then(res => {
        let rcc_id = res.data.data.id;
        for (let member_data of community_center_member_list) {
          let member_add = {
            data: {
              "personal_uid": member_data.uid,
              "rice_community_position": member_data.rcc_id,
              "rice_community_center": rcc_id
            }
          }
          callService("/rcc-member", member_add).then(res => {
            let rcc_member_id = res.data.data.id
            for (let farm_list of member_data.farm_list) {
              console.log(farm_list);
              let farm_add = {
                data: {
                  "rice_community_member": rcc_member_id,
                  "rice_type": farm_list.rice_type_id,
                  "rice_seed_type": farm_list.rice_seed_type_id,
                  "rice_plan_rai": farm_list.farm_rai,
                  "rice_plan_ngan": farm_list.farm_nga,
                  "rice_plan_wa": farm_list.farm_wa,
                }
              }
              callService("/rcc-member/plant", farm_add)
                .then(res => { })
                .catch(err => {
                  console.log(err);
                })
            }
          })
          for (let project_data in member_data.project) {
            // console.
            let project_add = {
              data: {
                "farmers": member_data.farmer_id,
                "projects": member_data.project[project_data].id,
              }
            }
            callPaFPostService("/farmer-projects", project_add).then(res => { })
          }
        }
        for (let equipment_data of community_center_equipment_list) {
          let equipment_add = {
            data: {
              "equipment_master_datum": equipment_data.id,
              "equipment_quantity": equipment_data.equipment_number,
              "rice_community_center": rcc_id,
              "equipment_registation_year": equipment_data.equipment_registation_year,
              "equipment_acquired_project": equipment_data.equipment_acquired_project,
            }
          }
          // console.log(equipment_add);
          callService("/rcc-equipment", equipment_add).then(res => {

          })
        }

        alert("เพิ่มข้อมูลสำเร็จ")
        window.location.href = "/center/list";
      })
      .catch(err => {
        console.log(err);
      })

      // callService("/rcc", acc_add)
      // .then(res => {
      //   let rcc_id = res.data.data.id;
      //   // console.log(community_center_equipment_list);
      //   // for (let equipment_data of community_center_equipment_list) {
      //   //   let equipment_add = {
      //   //     data: {
      //   //       "equipment_master_datum": equipment_data.id,
      //   //       "equipment_quantity": equipment_data.equipment_number,
      //   //       "rice_community_center": rcc_id,
      //   //       "equipment_registation_year": equipment_data.equipment_registation_year,
      //   //       "equipment_acquired_project": equipment_data.equipment_acquired_project
      //   //     }
      //   //   }
      //   //   callService("/rcc-equipment", equipment_add).then(res => {

      //   //   })
      //   }
      //   alert("เพิ่มข้อมูลสำเร็จ")
      //   window.location.href = "/center/list";
      // })
      // .catch(err=>{

      // })
    } else {
      let rcc_id = location.pathname.split('/')[3]
      let acc_add = {
        data: {
          "community_center_code": uuid(),
          "rice_community_center_name": community_center_name,
          "community_center_established_date": community_center_established_date,
          "community_center_register_year": community_center_register_year,
          "community_center_address": community_center_address,
          "community_center_tambon_id": community_center_tambon_selected,
          "community_center_tambon_name": community_center_tambon_name,
          "community_center_amphur_id": community_center_amphur_selected,
          "community_center_amphur_name": community_center_amphur_name,
          "community_center_province_id": community_center_province_selected,
          "community_center_province_name": community_center_province_name,
          "community_center_post_code": community_center_post_code,
          "community_center_UTM_x": community_center_UTM_x,
          "community_center_UTM_y": community_center_UTM_y,
          "community_contract_address": community_contract_address,
          "community_contract_tambon_id": community_contract_tambon_selected,
          "community_contract_tambon_name": community_contract_tambon_name,
          "community_contract_amphur_id": community_contract_amphur_selected,
          "community_contract_amphur_name": community_contract_amphur_name,
          "community_contract_province_id": community_contract_province_selected,
          "community_contract_province_name": community_contract_province_name,
          "community_contract_postcode": community_contract_post_code,
          "community_center_founder_number": community_center_founder_number,
          "community_center_funds": community_center_funds,
          "community_center_continue_date": community_center_continue_date,
          "rice_community_center_status": 1,
          "community_center_continue_date": community_center_established_date,
          "is_have_certificate": is_have_certificate,
          "is_have_permise": is_have_permise,
          "permise_code": permise_code,
        }
      }
      callPutService('/rcc/'+rcc_id, acc_add)
      for (let member_data of community_center_member_list) {
        if(member_data.is_old === false){
          console.log(member_data);
          // break
          let member_add = {
            data: {
              "personal_uid": member_data.uid,
              "rice_community_position": member_data.rcc_id,
              "rice_community_center": rcc_id
            }
          }
          callService("/rcc-member", member_add).then(res => {
            let rcc_member_id = res.data.data.id
            for (let farm_list of member_data.farm_list) {
              console.log(farm_list);
              let farm_add = {
                data: {
                  "rice_community_member": rcc_member_id,
                  "rice_type": farm_list.rice_type_id,
                  "rice_seed_type": farm_list.rice_seed_type_id,
                  "rice_plan_rai": farm_list.farm_rai,
                  "rice_plan_ngan": farm_list.farm_nga,
                  "rice_plan_wa": farm_list.farm_wa,
                }
              }
              callService("/rcc-member/plant", farm_add)
                .then(res => { })
                .catch(err => {
                  console.log(err);
                })
            }
          })
          for (let project_data in member_data.project) {
            // console.
            let project_add = {
              data: {
                "farmers": member_data.farmer_id,
                "projects": member_data.project[project_data].id,
              }
            }
            callPaFPostService("/farmer-projects", project_add).then(res => { })
          }
        }
        
      }
      for (let equipment_data of community_center_equipment_list) {
        if(equipment_data.is_old === false){

        let equipment_add = {
          data: {
            "equipment_master_datum": equipment_data.id,
            "equipment_quantity": equipment_data.equipment_number,
            "rice_community_center": rcc_id,
            "equipment_registation_year": equipment_data.equipment_registation_year,
            "equipment_acquired_project": equipment_data.equipment_acquired_project,
          }
        }
        console.log(equipment_add);
        callService("/rcc-equipment", equipment_add).then(res => {

        })
        }
        alert("แก้ไขข้อมูลสำเร็จ")
        window.location.href = "/center/list";
      }
    }
  }

  const handleDeleteMemberList = (event, cellValues) => {
    console.log(cellValues.row.index);
    let temp_list = [];
    let index = 0;
    for (let data of community_center_member_list) {
      if (data.index !== cellValues.row.index) {
        index++;
        temp_list.push({
          index: index,
          id: data.id,
          name: data.name,
          uid: data.uid,
          rcc_id: data.rcc_id,
          rcc_name: data.rcc_name,
          rcc_status: "สมาชิก"
        })
      }
    }
    setCommunityCenterMemberList(temp_list)
  }

  const handleDeleteEquipmentList = (event, cellValues) => {
    let temp_list = [];
    let index = 0;
    for (let data of community_center_equipment_list) {
      if (data.index !== cellValues.row.index) {
        index++;
        temp_list.push({
          index: index,
          id: data.id,
          equipment_name: data.equipment_name,
          equipment_number: data.equipment_number,
        })
      }
    }
    setCommunityCenterEquipmentList(temp_list)
  }

  const handleRCCContinue = (event) =>{
    let data = {
      data:{
        community_center_continue_date: new Date().toLocaleDateString()
      }
    }
    console.log(new Date().toLocaleDateString());
    let rcc_id = location.pathname.split('/')[3]
    callPutService('/rcc/'+rcc_id, data)
  }
  ///////////////////////////
  // handle button section //
  ///////////////////////////

  //////////////////////////////
  // handle drop down section //
  //////////////////////////////
  const handleChangeCenterTambon = (value) => {
    setCommunityCenterTambonName(findValueInDict(community_center_tambon_list, value))
    setCommunityCenterTambonSelected(value)
  }

  const handleChangeCenterAmphur = (value) => {
    setCommunityCenterAmphurName(findValueInDict(community_center_amphur_list, value))
    setCommunityCenterAmphurSelected(value);
    if (value === 0) {
      setCommunityCenterAmphurName("กรุณาเลือกอำเภอ");
      setCommunityCenterTambonName("กรุณาเลือกอำเภอ");
      setCommunityCenterTambonSelected(0)
    } else {
      setCommunityCenterTambonName("กรุณาเลือกตำบล");
      getTambons(value).then(res => {
        let temp_list = []
        let res_data = res.data.data
        console.log(res_data);
        for (let row_data of res_data) {
          temp_list.push({
            id: row_data.id,
            name: row_data.attributes.tambon_name
          })
        }
        setCommunityCenterTambonList(temp_list)
      })
    }
  }

  const handleChangeCenterProvince = (value) => {
    setCommunityCenterProvinceName(findValueInDict(community_center_province_list, value))
    setCommunityCenterProvinceSelected(value);
    if (value === 0) {
      setCommunityCenterProvinceName("กรุณาเลือกจังหวัด");
      setCommunityCenterAmphurName("กรุณาเลือกจังหวัด");
      setCommunityCenterTambonName("กรุณาเลือกจังหวัด");
      setCommunityCenterAmphurSelected(0);
      setCommunityCenterTambonSelected(0)
    } else {
      setCommunityCenterAmphurName("กรุณาเลือกอำเภอ");
      setCommunityCenterTambonName("กรุณาเลือกอำเภอ");
      getAmphurs(value).then(res => {
        let temp_list = []
        let res_data = res.data.data
        console.log(res_data);
        for (let row_data of res_data) {
          temp_list.push({
            id: row_data.id,
            name: row_data.attributes.amphur_name
          })
        }
        setCommunityCenterAmphurList(temp_list)
      })
    }
  }

  const handleChangeContractTambon = (value) => {
    setCommunityContractTambonName(findValueInDict(community_center_tambon_list, value))
    setCommunityContractTambonSelected(value)
  }

  const handleChangeContractAmphur = (value) => {
    setCommunityContractAmphurName(findValueInDict(community_contract_amphur_list, value))
    setCommunityContractAmphurSelected(value);
    if (value === 0) {
      setCommunityContractAmphurName("กรุณาเลือกอำเภอ");
      setCommunityContractTambonName("กรุณาเลือกอำเภอ");
      setCommunityContractTambonSelected(0)
    } else {
      setCommunityContractTambonName("กรุณาเลือกตำบล");
      getTambons(value).then(res => {
        let temp_list = []
        let res_data = res.data.data
        console.log(res_data);
        for (let row_data of res_data) {
          temp_list.push({
            id: row_data.id,
            name: row_data.attributes.tambon_name
          })
        }
        setCommunityContractTambonList(temp_list)
      })
    }
  }

  const handleChangeContractProvince = (value) => {
    setCommunityContractProvinceName(findValueInDict(community_center_province_list, value))
    setCommunityContractProvinceSelected(value);
    if (value === 0) {
      setCommunityContractProvinceName("กรุณาเลือกจังหวัด");
      setCommunityContractAmphurName("กรุณาเลือกจังหวัด");
      setCommunityContractTambonName("กรุณาเลือกจังหวัด");
      setCommunityContractAmphurSelected(0);
      setCommunityContractTambonSelected(0)
    } else {
      setCommunityContractAmphurName("กรุณาเลือกอำเภอ");
      setCommunityContractTambonName("กรุณาเลือกอำเภอ");
      getAmphurs(value).then(res => {
        let temp_list = []
        let res_data = res.data.data
        console.log(res_data);
        for (let row_data of res_data) {
          temp_list.push({
            id: row_data.id,
            name: row_data.attributes.amphur_name
          })
        }
        setCommunityContractAmphurList(temp_list)
      })
    }
  }
  //////////////////////////////
  // handle drop down section //
  //////////////////////////////

  //////////////////////
  // utility  section //
  //////////////////////
  const findValueInDict = (obj, id) => {
    for (let row of obj) {
      if (row.id === id) {
        return row.name
      }
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      let access_token = LocalStorage.getAccessToken();
      var decoded_token = jwt_decode(access_token);

      try {
        getProvince(decoded_token?.user_id).then(res => {
          let temp_list = []
          let res_data = res.data.data
          for (let row_data of res_data) {
            temp_list.push({
              id: row_data.id,
              name: row_data.attributes.province_name
            })
          }
          setCommunityCenterProvinceList(temp_list)
          setCommunityContractProvinceList(temp_list)
        })
        if (mode === "edit") {
          setButtonTitle("แก้ไขศูนย์ข้าวชุมชน")
          let rcc_id = location.pathname.split('/')[3]
          let res = await callGetService('/rcc/' + rcc_id)
          let questionair = await callGetService('/rcc-question-sums/'+rcc_id)
          console.log("question");
          if(questionair.data.meta.pagination.total > 0){
            console.log("eiei");
            setRCCContinue(true)
          }
          
          // console.log(res.data.data);
          let rcc_data = res.data.data[0].attributes
          setCommunityCenterName(rcc_data.rice_community_center_name)
          setCommunityCenterEstablishedDate(rcc_data.community_center_established_date)
          setCommunityCenterRegisterYear(rcc_data.community_center_register_year)
          setCommunityCenterAddress(rcc_data.community_center_address)
          setCommunityCenterProvinceSelected(rcc_data.community_center_province_id)
          setCommunityCenterProvinceName(rcc_data.community_contract_province_name)
          setCommunityCenterPostCode(rcc_data.community_center_post_code)
          setCommunityContractAddress(rcc_data.community_contract_address)
          setCommunityContractProvinceSelected(rcc_data.community_contract_province_id)
          setCommunityContractPostCode(rcc_data.community_contract_postcode)
          setCommunityCenterUTMX(rcc_data.community_center_UTM_x)
          setCommunityCenterUTMY(rcc_data.community_center_UTM_y)
          setCommunityCenterFounderNumber(rcc_data.community_center_founder_number)
          setCommunityCenterFunds(rcc_data.community_center_funds)
          setIsHaveCertificate(rcc_data.is_have_certificate)
          setIsHavePermise(rcc_data.is_have_permise)
          setPermiseCode(rcc_data.permise_code)

          await getAmphurs(rcc_data.community_center_province_id).then(res => {
            let temp_list = []
            let res_data = res.data.data
            console.log(res_data);
            for (let row_data of res_data) {
              temp_list.push({
                id: row_data.id,
                name: row_data.attributes.amphur_name
              })
            }
            setCommunityCenterAmphurList(temp_list)
            setCommunityCenterAmphurSelected(rcc_data.community_center_amphur_id)
            setCommunityCenterAmphurName(rcc_data.community_center_amphur_name)
          })

          await getAmphurs(rcc_data.community_contract_province_id).then(res => {
            let temp_list = []
            let res_data = res.data.data
            console.log(res_data);
            for (let row_data of res_data) {
              temp_list.push({
                id: row_data.id,
                name: row_data.attributes.amphur_name
              })
            }
            setCommunityContractAmphurList(temp_list)
            setCommunityContractAmphurSelected(rcc_data.community_contract_amphur_id)
            setCommunityContractAmphurName(rcc_data.community_contract_amphur_name)
          })

          await getTambons(rcc_data.community_center_amphur_id).then(res => {
            let temp_list = []
            let res_data = res.data.data
            // console.log(res_data);
            for (let row_data of res_data) {
              temp_list.push({
                id: row_data.id,
                name: row_data.attributes.tambon_name
              })
            }
            setCommunityCenterTambonList(temp_list)
            setCommunityCenterTambonSelected(rcc_data.community_center_tambon_id)
            setCommunityCenterTambonName(rcc_data.community_center_tambon_name)
          })

          await getTambons(rcc_data.community_contract_amphur_id).then(res => {
            let temp_list = []
            let res_data = res.data.data
            // console.log(res_data);
            for (let row_data of res_data) {
              temp_list.push({
                id: row_data.id,
                name: row_data.attributes.tambon_name
              })
            }
            setCommunityContractTambonList(temp_list)
            setCommunityContractTambonSelected(rcc_data.community_contract_tambon_id)
            setCommunityContractTambonName(rcc_data.community_contract_tambon_name)
          })

          let temp_list = [];
          let index = 0;

          for (let data of rcc_data.rice_community_members.data) {
            console.log(data);
            let personal_data = await callPaFGetService("/farmers/uid/" + data.attributes.personal_uid)
            console.log("personal data")
            console.log(personal_data.data.data[0]);
            index++;
            temp_list.push({
              index: index,
              id: data.id,
              name:
                // personal_data.data.data[0].attributes.personal.personal_prefix.data.attributes.prefix_name + " " +
                personal_data.data.data[0].attributes.personal.data.attributes.personal_name + " " +
                personal_data.data.data[0].attributes.personal.data.attributes.personal_surname,
              uid: data.attributes.farmer_uid,
              rcc_id: data.attributes.rice_community_position.data.id,
              rcc_name: data.attributes.rice_community_position.data.attributes.community_position_name,
              rcc_status: "สมาชิก",
              is_old: true
            })
          }
          setCommunityCenterMemberList(temp_list)

          temp_list = [];
          index = 0;
          for (let data of rcc_data.rice_community_equipments.data) {
            index++;
            temp_list.push({
              index: index,
              id: data.attributes.equipment_master_datum.data.id,
              equipment_name: data.attributes.equipment_master_datum.data.attributes.equipment_name,
              equipment_number: data.attributes.equipment_quantity,
              is_old: true,
              is_old_id: data.id
            })
          }
          setCommunityCenterEquipmentList(temp_list)


        } else {
          setButtonTitle("เพิ่มศูนย์ข้าวชุมชน")
        }
      } catch (error) {
        console.error('Error fetching data from Server:', error);
        // alert('Error fetching data from Server')
      }
    };

    fetchData();
  }, []);

  return (
    <Card style={{ marginTop: '1.5rem', marginRight: '1%', marginLeft: '1%' }}>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <TextField
              fullWidth
              id="outlined-basic"
              label="ชื่อศูนย์ข้าวชุมชน"
              variant="outlined"
              value={community_center_name}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={event => setCommunityCenterName(event.target.value)}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              type={"date"}
              id="outlined-basic"
              label="เริ่มก่อตั้งปี"
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              value={community_center_established_date}
              onChange={event => setCommunityCenterEstablishedDate(event.target.value)}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              id="outlined-basic"
              label="ปีที่ขึ้นทะเบียนกับกรมการข้าว"
              type="number"
              variant="outlined"
              value={community_center_register_year}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={event => setCommunityCenterRegisterYear(event.target.value)}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              id="outlined-basic"
              label="ที่อยู่"
              variant="outlined"
              value={community_center_address}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={event => setCommunityCenterAddress(event.target.value)}
            />
          </Grid>
          <Grid item xs={4}>
            {/* ตำบล */}
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-readonly-label">ตำบล</InputLabel>
              <Select
                labelId="demo-simple-select-required-label"
                id="demo-simple-select-required"
                value={community_center_tambon_selected}
                label="ตำบล"
                fullWidth
                onChange={(event) => {
                  handleChangeCenterTambon(event.target.value);
                }}
              >
                <MenuItem key={0} value={0}>{community_center_tambon_name}</MenuItem>
                {community_center_tambon_list.map((item, index) => {
                  return (<MenuItem key={index + 1} value={item.id}>{item.name}</MenuItem>)
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            {/* อำเภอ */}
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-readonly-label">อำเภอ</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={community_center_amphur_selected}
                variant="outlined"
                label="อำเภอ"
                fullWidth
                onChange={(event) => {
                  handleChangeCenterAmphur(event.target.value);
                }}
              >
                <MenuItem key={0} value={0}>{community_center_amphur_name}</MenuItem>
                {community_center_amphur_list.map((item, index) => {
                  return (<MenuItem key={index + 1} value={item.id}>{item.name}</MenuItem>)
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            {/* จังหวัด */}
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-readonly-label">จังหวัด</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={community_center_province_selected}
                variant="outlined"
                label="จังหวัด"
                fullWidth
                onChange={(event) => {
                  handleChangeCenterProvince(event.target.value);
                }}
              >
                <MenuItem key={0} value={0}>กรุณาเลือกจังหวัด</MenuItem>
                {community_center_province_list.map((item, index) => {
                  return (<MenuItem key={index + 1} value={item.id}>{item.name}</MenuItem>)
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              id="outlined-basic"
              label="รหัสไปรณีย์"
              variant="outlined"
              value={community_center_post_code}
              InputLabelProps={{
                shrink: true,
                maxLength: 5
              }}
              onChange={event => setCommunityCenterPostCode(event.target.value)}
            />
          </Grid>
          <Grid item xs={2}>
            <TextField
              fullWidth
              id="outlined-basic"
              label="พิกัดศูนย์ข้าวชุมชน X"
              variant="outlined"
              type="number"
              value={community_center_UTM_x}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={event => setCommunityCenterUTMX(event.target.value)}
            />
          </Grid>
          <Grid item xs={2}>
            <TextField
              fullWidth
              id="outlined-basic"
              label="พิกัดศูนย์ข้าวชุมชน Y"
              variant="outlined"
              type="number"
              value={community_center_UTM_y}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={event => setCommunityCenterUTMY(event.target.value)}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              id="outlined-basic"
              label="ที่อยู่ ผู้ติดต่อ"
              variant="outlined"
              value={community_contract_address}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={event => setCommunityContractAddress(event.target.value)}
            />
          </Grid>
          <Grid item xs={4}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-readonly-label">ตำบลผู้ติดต่อ</InputLabel>
              {/* ตำบลผู้ติดต่อ */}
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={community_contract_tambon_selected}
                variant="outlined"
                label="ตำบลผู้ติดต่อ"
                fullWidth
                onChange={(event) => {
                  handleChangeContractTambon(event.target.value);
                }}
              >
                <MenuItem key={0} value={0}>{community_contract_tambon_name}</MenuItem>
                {community_contract_tambon_list.map((item, index) => {
                  return (<MenuItem key={index + 1} value={item.id}>{item.name}</MenuItem>)
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            {/* อำเภอ ผู้ติดต่อ */}
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-readonly-label">อำเภอ ผู้ติดต่อ</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={community_contract_amphur_selected}
                variant="outlined"
                label="อำเภอ ผู้ติดต่อ"
                fullWidth
                onChange={(event) => {
                  handleChangeContractAmphur(event.target.value);
                }}
              >
                <MenuItem key={0} value={0}>{community_contract_amphur_name}</MenuItem>
                {community_contract_amphur_list.map((item, index) => {
                  return (<MenuItem key={index + 1} value={item.id}>{item.name}</MenuItem>)
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            {/* จังหวัดผู้ติดต่อ */}
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-readonly-label">จังหวัด ผู้ติดต่อ</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={community_contract_province_selected}
                variant="outlined"
                label="จังหวัด ผู้ติดต่อ"
                fullWidth
                onChange={(event) => {
                  handleChangeContractProvince(event.target.value);
                }}
              >
                <MenuItem key={0} value={0}>กรุณาเลือกจังหวัด</MenuItem>
                {community_contract_province_list.map((item, index) => {
                  return (<MenuItem key={index + 1} value={item.id}>{item.name}</MenuItem>)
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              id="outlined-basic"
              label="รหัสไปรณีย์ ผู้ติดต่อ"
              variant="outlined"
              value={community_contract_post_code}
              InputLabelProps={{
                shrink: true,
                maxLength: 5
              }}
              onChange={event => setCommunityContractPostCode(event.target.value)}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              id="outlined-basic"
              label="จำนวนสมาชิกเมื่อเริ่มก่อตั้ง"
              variant="outlined"
              type="number"
              value={community_center_founder_number}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={event => setCommunityCenterFounderNumber(event.target.value)}
            />
          </Grid>
          <Grid item xs={3}>
            <FormControlLabel
              sx={{ paddingTop: '10px' }}
              control={
                <Switch
                  checked={is_have_certificate}
                  onChange={event => setIsHaveCertificate(!is_have_certificate)}
                />
              }
              label="มีการขออนุญาตรวมรวมเมล็ดเพื่อการค้า?"
            />
          </Grid>
          <Grid item xs={2}>
            <FormControlLabel
              sx={{ paddingTop: '10px' }}
              control={
                <Switch
                  checked={is_have_permise}
                  onChange={event => setIsHavePermise(!is_have_permise)}
                />
              }
              label="มีการขออนุญาตขาวเมล็ด"
            />
          </Grid>
          <Grid item xs={3}>
            {
              is_have_permise ?
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label="พันธุ์/อนุญาตเลขที่"
                  variant="outlined"
                  value={permise_code}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={event => setPermiseCode(event.target.value)}
                />
                : null
            }
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              id="outlined-basic"
              label="จำนวนเงินกองทุนที่มีอยู่ทั้งหมด"
              variant="outlined"
              value={community_center_funds}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={event => setCommunityCenterFunds(event.target.value)}
            />
          </Grid>
          {/* Member Section */}
          <Grid item xs={12}>
            <Grid
              container
              spacing={2}
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
            >
              <Grid item ></Grid>
              {
                rcc_continue ?
                  <Grid item>
                    <Button
                      variant="contained"
                      color="success"
                      onClick={(event) => handleRCCContinue()}
                    >
                      ต่ออายุ
                    </Button>
                  </Grid>

                  : null
              }
              {
                mode === 'edit' ?
                  <Grid item>
                    <Button
                      variant="contained"
                      color="success"
                      onClick={(event) => setIsQestionnairForm(true)}
                    >
                      ทำแบบประเมิน
                    </Button>
                  </Grid>

                  : null
              }
              {
                mode === 'edit' ?
                  <Grid item>
                    <Button
                      variant="contained"
                      color="success"
                      onClick={(event) => setIsQestionnairHistory(true)}
                    >
                      เรียกดูแบบประเมิน
                    </Button>
                  </Grid>
                  : null
              }
              <Grid item>
                <Button
                  variant="contained"
                  color="success"
                  onClick={(event) => setIsMemberModalOpen(true)}
                >
                  เพิ่มสมาชิก
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <div style={{ height: 300, width: '100%', marginTop: '1rem' }}>
              <DataGrid
                style={{ backgroundColor: "#FFFFFF" }}
                rows={community_center_member_list}
                columns={member_columns}
                rowsPerPageOptions={[]}
              />
            </div>
          </Grid>
          {/* Equipment Section */}
          <Grid item xs={12}>
            <Grid
              container
              spacing={2}
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              style={{ marginTop: '2rem' }}
            >
              <Grid item ></Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="success"
                  onClick={(event) => setIsEquipmentModalOpen(true)}
                >
                  เพิ่มอุปกรณ์
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <div style={{ height: 300, width: '100%', marginTop: '1rem' }}>
              <DataGrid
                style={{ backgroundColor: "#FFFFFF" }}
                rows={community_center_equipment_list}
                columns={equipment_columns}
                rowsPerPageOptions={[]}
              />
            </div>
          </Grid>
        </Grid>
      </CardContent>
      <CardActions>
        <Grid
          container
          spacing={2}
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
        >
          <Grid item ></Grid>
          <Grid item>
            <Button
              variant="contained"
              color="success"
              onClick={handleSaveButton}
            >
              {button_title}
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="error"
              onClick={handleBackButton}
            >
              ย้อนกลับ
            </Button>
          </Grid>
        </Grid>
      </CardActions>

      <RCCQuestionnairHistory
        is_open={is_questionair_history_open}
        setIsOpen={setIsQestionnairHistory}
        rcc_id={location.pathname.split('/')[3]}
      />
      <RCCQuestionnairForm
        is_open={is_questionair_form_open}
        setIsOpen={setIsQestionnairForm}
        rcc_id={location.pathname.split('/')[3]}
      />
      <RCCMemberForm
        is_open={is_member_modal_open}
        setIsOpen={setIsMemberModalOpen}
        member_list={community_center_member_list}
        setMemberList={setCommunityCenterMemberList}
      />
      <RCCEquipmentForm
        is_open={is_equipment_modal_open}
        setIsOpen={setIsEquipmentModalOpen}
        equipment_list={community_center_equipment_list}
        setEquipmentList={setCommunityCenterEquipmentList}
      />
    </Card>

  )
}
export default RCCForm;