import React, { useState, useEffect } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Grid from '@mui/material/Grid'
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import Autocomplete from '@mui/material/Autocomplete'

export default function CommitteeListForm({
    is_open,
    postion_dropdown,
    handlePositionChange,
    handleSaveCommitteeButton,
    handleCloseCommitteeForm,
    farmer_dropdown,
    data,
    handleSelectedFarmer,
}) {
    return (
        <>
            <Dialog
                open={is_open}
                onClose={handleCloseCommitteeForm}
                fullWidth
                aria-labelledby="AddUser-Dialog-title"
                aria-describedby="AddUser-Dialog-Content"
                sx={{
                    "& .MuiDialog-container": {
                        "& .MuiPaper-root": {
                            width: "100%",
                            maxWidth: "800px",  // Set your width here
                        },
                    },
                    zIndex:10
                }}
            >
                <DialogTitle id="AddUser-Dialog-title">เพิ่มคณะกรรมการ</DialogTitle>
                <DialogContent id="AddUser-Dialog-Content">
                    <Grid container spacing={2} sx={{ mt: 1 }}>
                        <Grid item xs={4}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-readonly-label">ตำแหน่งสมาชิก</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="selected_position"
                                    value={data.selected_position}
                                    variant="outlined"
                                    label="ตำแหน่งสมาชิก"
                                    fullWidth
                                    onChange={(event) => {
                                        handlePositionChange(event.target.value);
                                    }}
                                >
                                    <MenuItem key={0} value={0}>กรุณาเลือกตำแหน่ง</MenuItem>
                                    {
                                        postion_dropdown.map((item, index) => {
                                            return (<MenuItem key={index + 1} value={item.id}>{item.name}</MenuItem>)
                                        })
                                    }
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={8}>
                            <FormControl fullWidth>
                                <Autocomplete
                                    onChange={(event, newValue) => {
                                        handleSelectedFarmer(newValue)
                                    }}
                                    id="controllable-states-demo"
                                    options={farmer_dropdown}
                                    sx={{ width: '100%' }}
                                    renderInput={
                                        (params) => 
                                        <TextField 
                                            {...params} 
                                            label="ชื่อสมาชิก" 
                                            // onChange={(event) => {
                                            //     handleChangeAutocompleteFarmer(event.target.value)
                                            // }} 
                                        />
                                    }
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                id="outlined-basic"
                                label="ศูนย์ข้าวชุมชนที่สังกัด"
                                variant="outlined"
                                inputProps={{
                                    autoComplete: 'off'
                                }}
                                value={data.farmer_result_rcc_name}
                                disabled
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleSaveCommitteeButton}
                        variant="contained"
                        color="success"
                    >
                        เพิ่มกรรมการ
                    </Button>

                    <Button
                        variant="contained"
                        onClick={handleCloseCommitteeForm}
                        color="error"
                    >
                        ยกเลิก
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}