import React, { useState, useEffect } from 'react';
import AdminLayout from '../components/admin/AdminLayout'
import {
    Typography,
    Grid,
    Card,
    CardContent,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';

import {
    callRccApi
} from '../services/Service'
import { toThaiDateString } from '../utils/DateTime';
import Chart from "react-apexcharts";
import { findValueInDict, numberWithCommas } from '../utils/utils';
import logoRcc from '../assets/images/rcc-logo.png'
import { Dashboard, Gif } from '@mui/icons-material';
import RegionForm from '../components/form/region-form';
import { getAmphurs, getProvince } from '../services/Dropdown';
import { getCommittee } from '../services/rcc-committee';
import { getRCCList, getRCCListDashboard } from '../services/rcc';

export default function CenterList() {

    const options = {
        chart: {
            height: 390,
            type: 'radialBar',
        },
        plotOptions: {
            radialBar: {
                offsetY: 0,
                startAngle: 0,
                endAngle: 270,
                hollow: {
                    margin: 5,
                    size: '30%',
                    background: 'transparent',
                    image: undefined,
                },
                dataLabels: {
                    name: {
                        show: true,
                    },
                    value: {
                        show: true,
                    }
                }
            }
        },
        colors: ['#129137', '#E9B621'],
        labels: ['ขึ้นทะเบียนแล้ว', 'ยังไม่ขึ้นทะเบียน'],
        legend: {
            show: true,
            floating: true,
            fontFamily: 'IBM Plex Sans Thai',
            fontSize: '16px',
            position: 'left',
            offsetX: 40,
            offsetY: 10,
            labels: {
                useSeriesColors: true,
            },
            markers: {
                size: 0
            },
            formatter: function (seriesName, opts) {
                return opts.w.globals.series[opts.seriesIndex] + "%"
            },
            itemMargin: {
                vertical: 3
            }
        },
        responsive: [{
            breakpoint: 480,
            options: {
                legend: {
                    show: false
                }
            }
        }]
    }

    const [rrcData, setRrcData] = useState({
        "all": 0,
        "accept": 0,
        "member": 0,
        "area": 0,
        "seed_rai": 0,
        "seedd_rai": 0
    })
    const [rccNumberSeries, setRccNumberSeries] = useState([0, 0])

    useEffect(() => {
        callRccApi().then(resp => {
            setRrcData(resp.data.data)
            setRccNumberSeries([
                (resp.data.data.accept * 100 / resp.data.data.all).toFixed(2),
                ((resp.data.data.all - resp.data.data.accept) * 100 / resp.data.data.all).toFixed(2)
            ])
        })

        getProvince(14).then(res => {
            let temp_list = []
            let res_data = res.data.data
            for (let row_data of res_data) {
                temp_list.push({
                    id: row_data.id,
                    name: row_data.attributes.province_name,
                    code: row_data.attributes.province_code,
                })
            }
            setProvinceDropdown(temp_list)
        })
    }, []);

    const [province_dropdown, setProvinceDropdown] = useState([])
    const [selected_province, setSelectedProvince] = useState(0)
    const [rcc_details_list, setRCCDetailsList] = useState([])

    const columns = [
        {
            field: 'index',
            headerName: 'ลำดับ',
            maxWidth: 50,
            flex: 1
        },
        {
            field: 'province_name',
            headerName: 'จังหวัด',
            minWidth: 150,
            flex: 1
        },
        {
            field: 'amphur_name',
            headerName: 'อำเภอ',
            minWidth: 250,
            flex: 1
        },
        {
            field: 'rcc_num',
            headerName: 'จำนวนศูนย์ข้าวชุมชน',
            minWidth: 250,
            flex: 1
        },
        {
            field: 'rcc_member_num',
            headerName: 'จำนวนสมาชิกศูนย์ข้าวทั้งหมด',
            minWidth: 250,
            flex: 1
        },
    ];

    const handleProvinceChange = (value) => {
        setSelectedProvince(value)
    }

    const handleSearchClick = async () => {
        if (selected_province === 0) {
            alert("คุณยังไม่ได้ทำการเลือกจังหวัด \n กรุณาเลือกจังหวัด")
        } else {
            // setIsLoading(true)
            setRCCDetailsList([])
            let selected_province_details = findValueInDict(province_dropdown, selected_province)
            let amphur = await getAmphurs(selected_province);
            let temp_list = []
            let index = 0
            let res_data = amphur.data.data
            for (let row_data of res_data) {
                // console.log(row_data);
                let filter = "&filters[province_id][$eq]=" + parseInt(selected_province_details.code)
                    + "&filters[amphur_id][$eq]=" + parseInt(row_data.attributes.amphur_code.slice(-2).slice(-2))
                // console.log(filter);
                let rcc_list = await getRCCListDashboard(filter)
                // console.log(rcc_list);
                // console.log(rcc_list.data.meta.pagination.total);
                let rcc_member_num = 0
                for (let rcc_item of rcc_list.data.data) {
                    console.log(rcc_item.attributes.rcc_members);
                    console.log(rcc_item.attributes.rcc_members.data.length);
                    rcc_member_num += parseInt(rcc_item.attributes.rcc_members.data.length)
                }
                // let tambon_committee_list = await getCommittee(filter)
                // filter = "&filters[province_id][$eq]=" + selected_province_details.code
                //     + "&filters[amphur_id][$eq]=" + row_data.attributes.amphur_code.slice(-2).slice(-2)
                // let committee_list = await getCommittee(filter)
                index += 1
                temp_list.push({
                    id: row_data.id,
                    index: index,
                    province_code: selected_province_details.code,
                    province_name: selected_province_details.name,
                    amphur_code: row_data.attributes.amphur_code,
                    amphur_name: row_data.attributes.amphur_name,
                    rcc_num: rcc_list.data.meta.pagination.total,
                    rcc_member_num: rcc_member_num,
                })
            }
            console.log(temp_list);
            setRCCDetailsList(temp_list)
            // setIsLoading(false)
        }
    }
    return (
        <div style={{ margin: '2rem' }}>
            <AdminLayout />
            <Grid container className='content-box'>
                <Grid item xs>
                    <Typography variant='h4'><Dashboard /> Dashboard สรุปข้อมูลศูนย์ข้าวชุมชน</Typography>
                </Grid>
                <Grid item xs>
                    <Typography variant='h6' align='right'>ข้อมูล ณ วันที่ {toThaiDateString(new Date())}</Typography>
                </Grid>
                <Grid container spacing={2} className='content'>
                    <Grid item xs={4}>
                        <div className='chart-box'>
                            {/* <img style={{ width: '100px' }} src={logoRcc} alt='' /> */}
                            <Typography variant='h6'>จำนวนศูนย์ข้าวชุมชน</Typography>
                            <Chart
                                options={options}
                                series={rccNumberSeries}
                                type='radialBar'
                            />
                            <p className='chart-desc'>
                                ศูนย์ข้าวชุมชนที่ขึ้นทะเบียนแล้ว {numberWithCommas(rrcData.accept)} ศูนย์<br />
                                ศูนย์ข้าวชุมชนที่ยังไม่ขึ้นทะเบียน {numberWithCommas((rrcData.all - rrcData.accept))} ศูนย์<br /><br />
                                จากจำนวนศูนย์ข้าวชุมชนทั้งหมด {numberWithCommas(rrcData.all)} ศูนย์
                            </p>
                        </div>
                    </Grid>
                    <Grid item xs={4}>
                        <div className='chart-box'>
                            <Typography variant='h6'>พื้นที่ผลิตรวมในศูนย์ข้าวชุมชน</Typography><br />
                            <Typography variant='h4'>{numberWithCommas(rrcData.area)} ไร่</Typography>
                        </div>
                        <div className='chart-box'>
                            <Typography variant='h6'>พื้นที่ผลิตเมล็ดพันธุ์ดีในศูนย์ข้าวชุมชน</Typography><br />
                            <Typography variant='h4'>{numberWithCommas(rrcData.seed_rai)} ไร่</Typography>
                        </div>
                        <div className='chart-box'>
                            <Typography variant='h6'>พื้นที่ผลิตข้าวคุณภาพดีภายในศูนย์ข้าวชุมชน</Typography><br />
                            <Typography variant='h4'>{numberWithCommas(rrcData.seedd_rai)} ไร่</Typography>
                        </div>
                    </Grid>
                    <Grid item xs={4}>
                        <div className='chart-box'>
                            <Typography variant='h6'>จำนวนสมาชิกศูนย์ข้าวชุมชน</Typography><br />
                            <Typography variant='h4'>{numberWithCommas(rrcData.member)} คน</Typography>
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                    <Card>
                        <CardContent>
                            <Grid
                                container
                                className='content-box'
                                spacing={1}
                                sx={{ width: '100%' }}
                            >
                                <Grid item xs>
                                    <Typography variant='h4'>รายละเอียดศูนย์ข้าวชุมชน</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <RegionForm
                                        mode={"commitee_amphur"}
                                        province_dropdown={province_dropdown}
                                        selected_province={selected_province}
                                        handleProvinceChange={handleProvinceChange}
                                        handleSearchClick={handleSearchClick}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                <div style={{ height: 600, width: '100%', marginTop: '2rem' }}>
                                    <DataGrid
                                        style={{ backgroundColor: "#FFFFFF" }}
                                        rows={rcc_details_list}
                                        // rowCount={row_count}
                                        columns={columns}
                                    />
                                    </div>  
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                    </Grid>
                </Grid>
                <Grid container spacing={2} className='content' sx={{ width: '100%' }}>
                    
                </Grid>
            </Grid>

        </div >
    )
}