import {
    getFarmerByCitizenId
} from '../services/personal-and-farmer'

import {
    getMemberByID
} from '../services/rcc-member'

const searchFarmerByCitizenID = async (
    keyword,
    filter
) => {
    let result = await getFarmerByCitizenId(keyword)
    if (result.data.meta.pagination.total === 0) {
        return ({
            error: "ไม่พบข้อมูลเกษตกร"
        })
    } else {
        let farmer_data = result.data.data
        filter += "&filters[personal_uid][$eq]=" + farmer_data[0].attributes.farmer_uid
        let member_result = await getMemberByID(filter)
        return ({
            farmer_data: farmer_data,
            member_result: member_result
        })
        // return member_result
    }
}

export const checkMemberByCitizenID = async (
    keyword,
    filter,
    mode
) => {
    let result = await searchFarmerByCitizenID(keyword, filter)
    if (result.error !== undefined) {
        return ({
            error: "ไม่พบข้อมูลเกษตกร"
        })
    } else {
        if (result.member_result.data.length > 0 && mode === 'add') {
            return ({
                error: "เกษตรกรท่านนี้สังกัดศูนย์ข้าวชุมชนแล้ว\nกรุณาค้นหาอีกครั้ง"
            })
        } else {
            let farmer_data = result.farmer_data
            return ({
                personal_uid: farmer_data[0].attributes.personal.data.attributes.personal_uid,

                id: farmer_data[0].attributes.personal.data.id,
                personal_prefix: farmer_data[0].attributes.personal.data.attributes.personal_prefix,
                personal_name: farmer_data[0].attributes.personal.data.attributes.personal_name,
                personal_surname: farmer_data[0].attributes.personal.data.attributes.personal_surname,
                personal_id: farmer_data[0].attributes.personal.data.attributes.personal_id,
                personal_birthday: farmer_data[0].attributes.personal.data.attributes.personal_birthday,
                personal_phone: farmer_data[0].attributes.personal.data.attributes.personal_phone,
                personal_addr: farmer_data[0].attributes.personal.data.attributes.personal_addr,
                personal_moo: farmer_data[0].attributes.personal.data.attributes.personal_moo,
                personal_alley: farmer_data[0].attributes.personal.data.attributes.personal_alley,
                personal_lane: farmer_data[0].attributes.personal.data.attributes.personal_lane,
                personal_road: farmer_data[0].attributes.personal.data.attributes.personal_road,
                province: farmer_data[0].attributes.personal.data.attributes.province,
                amphur: farmer_data[0].attributes.personal.data.attributes.amphur,
                tambon: farmer_data[0].attributes.personal.data.attributes.tambon,

                //farm id
                farmer_id: farmer_data[0].id,
                farmer_uid: farmer_data[0].attributes.farmer_uid,
                farm_list: farmer_data[0].attributes.farms,

                amphurs: farmer_data[0].attributes.amphurs,
                tambons: farmer_data[0].attributes.tambons,
            })
        }
    }
}

export const searchCommitteByCitizenID = async (
    keyword,
    filter
) => {
    let result = await searchFarmerByCitizenID(keyword, filter)
    if (result.error !== undefined) {
        return ({
            error: "ไม่พบข้อมูลเกษตกร\nกรุณาค้นหาอีกครั้ง"
        })
    } else {
        console.log(result);
        if (result.member_result.data.length === 0) {
            return ({
                error: "เกษตรกรท่านนี้ไม่ได้อยู่ในกลุ่มศูนย์ข้าวชุมชนที่ท่านเลือก\nกรุณาค้นหาอีกครั้ง"
            })
        } else {
            let farmer_data = result.farmer_data
            let rcc_member_data = result.member_result.data
            console.log(result);
            return ({
                error: "",
                farmer_result_name: farmer_data[0].attributes.personal.data.attributes.personal_name + " " + farmer_data[0].attributes.personal.data.attributes.personal_surname,
                farmer_result_rcc_name: rcc_member_data[0].attributes.rcc.data.attributes.name_center,
                farmer_result_UID: farmer_data[0].attributes.farmer_uid,
                farmer_result_RCC_ID: rcc_member_data[0].attributes.rcc.data.id
            })
        }
    }
}

export const old_searchCommitteByCitizenID = async (
    keyword,
    filter
) => {
    let result = await getFarmerByCitizenId(keyword)
    console.log(result);
    if (result.data.meta.pagination.total === 0) {
        return ({
            error: "ไม่พบข้อมูลเกษตกร\nกรุณาค้นหาอีกครั้ง"
        })
    } else {
        let farmer_data = result.data.data
        filter += "&filters[personal_uid][$eq]=" + farmer_data[0].attributes.farmer_uid
        console.log(filter);
        let member_result = await getMemberByID(filter)
        console.log(member_result);
        if (member_result.length === 0) {
            return ({
                error: "เกษตรกรท่านนี้ไม่ได้อยู่ในกลุ่มศูนย์ข้าวชุมชนที่ท่านเลือก\nกรุณาค้นหาอีกครั้ง"
            })
        } else {
            let rcc_member_data = member_result.data
            // console.log(rcc_member_data);
            return ({
                error: "",
                farmer_result_name: farmer_data[0].attributes.personal.data.attributes.personal_name + " " + farmer_data[0].attributes.personal.data.attributes.personal_surname,
                farmer_result_rcc_name: rcc_member_data[0].attributes.rcc.data.attributes.name_center,
                farmer_result_UID: farmer_data[0].attributes.farmer_uid,
                farmer_result_RCC_ID: rcc_member_data[0].attributes.rcc.data.id
            })
        }
    }
}