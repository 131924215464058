import React, { useState, useEffect } from 'react'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';

export default function RegionForm({
    province_dropdown,
    selected_province,
    amphur_dropdown,
    selected_amphur,
    amphur_init,
    tambon_dropdown,
    selected_tambon,
    tambon_init,
    handleProvinceChange,
    handleAmphurChange,
    handleTambonChange,
    handleSearchClick,
    mode,
}) {
    return (
        <Grid container spacing={2}>
            <Grid item xs={3.5}>
                <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-readonly-label">จังหวัด</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="province_id"
                        value={selected_province}
                        variant="outlined"
                        label="จังหวัด"
                        fullWidth
                        onChange={(event) => {
                            handleProvinceChange(event.target.value);
                        }}
                    >
                        <MenuItem key={0} value={0}>กรุณาเลือกจังหวัด</MenuItem>
                        {
                            province_dropdown.map((item, index) => {
                                return (<MenuItem key={index + 1} value={item.id}>{item.name}</MenuItem>)
                            })
                        }
                    </Select>
                </FormControl>
            </Grid>
            {
                mode === "commitee_amphur" ? null :
                    <Grid item xs={3.5}>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-readonly-label">อำเภอ</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="amphur_id"
                                value={selected_amphur}
                                variant="outlined"
                                label="อำเภอ"
                                fullWidth
                                onChange={(event) => {
                                    handleAmphurChange(event.target.value);
                                }}
                            >
                                <MenuItem key={0} value={0}>{amphur_init}</MenuItem>
                                {
                                    amphur_dropdown.map((item, index) => {
                                        return (<MenuItem key={index + 1} value={item.id}>{item.name}</MenuItem>)
                                    })
                                }
                            </Select>
                        </FormControl>
                    </Grid>
            }
            {
                mode === "committee_tambon" || mode === "commitee_amphur" ? null :
                    <Grid item xs={3.5}>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-readonly-label">ตำบล</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="tambon_id"
                                value={selected_tambon}
                                variant="outlined"
                                label="ตำบล"
                                fullWidth
                                onChange={(event) => {
                                    handleTambonChange(event.target.value);
                                }}
                            >
                                <MenuItem key={0} value={0}>{tambon_init}</MenuItem>
                                {
                                    tambon_dropdown.map((item, index) => {
                                        return (<MenuItem key={index + 1} value={item.id}>{item.name}</MenuItem>)
                                    })
                                }
                            </Select>
                        </FormControl>
                    </Grid>
            }
            <Grid item xs={1.5}>
                <Button
                    variant="contained"
                    onClick={handleSearchClick}
                >
                    ค้นหา
                </Button>
            </Grid>
        </Grid>
    )
}