function setAccessToken(token) {
  localStorage.setItem('ACCESS_TOKEN', token);
}

function getAccessToken() {
  return localStorage.getItem('ACCESS_TOKEN');
}

function setRefreshToken(token) {
  localStorage.setItem('REFRESH_TOKEN', token);
}

function getRefreshToken() {
  return localStorage.getItem('REFRESH_TOKEN');
}

function setMenu(menu) {
  localStorage.setItem('MENU', JSON.stringify(menu));
}

function getMenu() {
  return localStorage.getItem('MENU');
}

function removeToken() {
  localStorage.removeItem('ACCESS_TOKEN');
  localStorage.removeItem('MENU');
}

function setSelectedCommitteeTambon(value){
  localStorage.setItem('COMMITTEE_TAMBON', JSON.stringify(value));
}

function getSelectedCommitteeTambon() {
  return localStorage.getItem('COMMITTEE_TAMBON');
}

function removeSelectedCommitteeTambon() {
  localStorage.removeItem('COMMITTEE_TAMBON');
}

function setSelectedCommitteeAmphur(value){
  localStorage.setItem('COMMITTEE_AMPHUR', JSON.stringify(value));
}

function getSelectedCommitteeAmphur() {
  return localStorage.getItem('COMMITTEE_AMPHUR');
}

function removeSelectedCommitteeAmphur() {
  localStorage.removeItem('COMMITTEE_AMPHUR');
}

export default {
  setSelectedCommitteeAmphur,
  getSelectedCommitteeAmphur,
  removeSelectedCommitteeAmphur,
  setAccessToken,
  getAccessToken,
  removeToken,
  setMenu,
  getMenu,
  setRefreshToken,
  getRefreshToken,
  setSelectedCommitteeTambon,
  getSelectedCommitteeTambon,
  removeSelectedCommitteeTambon
};
