/* eslint-disable eqeqeq */
import React, { useState } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Grid from '@mui/material/Grid'
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField'
// import Autocomplete from '@mui/material/Autocomplete'
// import Checkbox from '@mui/material/Checkbox'
import IconButton from '@mui/material/IconButton'
import Button from '@mui/material/Button'
import SearchIcon from '@mui/icons-material/Search';
// import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
// import CheckBoxIcon from '@mui/icons-material/CheckBox';
import FarmerFarmList from '../list/farmer-farm-list'
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material'
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
// const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function RCCMemberForm({
    is_open,
    handleCloseForm,
    form_data,
    rcc_member_list,
    setFormData,
    handleSearchCitizenID,
    rcc_position_dropdown,
    rcc_prefix_dropdown,
    button_mode,
    handlePositonChange,
    handlePrefixChange,
    rcc_personal_project_dropdown,
    handleProjectChange,
    handleClickEditInFarmList,
    handleSaveButton,
    province_dropdown,
    amphur_dropdown,
    tambon_dropdown,
    handleProvinceChange,
    handleAmphurChange,
    handleTambonChange,
    expanded,
    setExpanded,
    isDisabled,
}) {
    const [is_open_dialog, setIs_open_dialog] = useState(false)

    const [farm_data, setFarmData] = useState({
        rice_type: '',
        rice_seed_type: '',
        farm_name: '',
        farm_rai: 0,
        farm_nga: 0,
        farm_wa: 0,
    })

    const handleClose = () => {
        handleCloseForm()
        setExpanded(false)
    }

    const handleChange = (e) => {
        const { name, value } = e.target
        setFarmData((prevFarmData) => ({
            ...prevFarmData,
            [name]: value
        }));
    }

    const handleSearchClick = () => {
        handleSearchCitizenID()
    }

    const convertSquareMeter = () => {
        const rai = farm_data.farm_rai || 0
        const nga = farm_data.farm_nga || 0
        const wa = farm_data.farm_wa || 0

        const value = (rai * 4000) + (nga * 400) + (wa * 4)
        return value.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2 })
    }

    const convertFarmArea = (filter) => {
        const total_farm_area = {
            rai: 0,
            nga: 0,
            wa: 0
        }

        if (form_data?.member_farm_list) {
            for (let member_farm of form_data.member_farm_list) {
                if ((member_farm.rice_type === filter || filter === undefined) && member_farm?.is_join) {
                    total_farm_area.rai += member_farm.farm_rai
                    total_farm_area.nga += member_farm.farm_nga
                    total_farm_area.wa += member_farm.farm_wa
                }
            }
        }

        return formatFarmArea(total_farm_area);
    }

    const formatFarmArea = (area) => {
        const formattedArea = {
            rai: area.rai.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2 }),
            nga: area.nga.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2 }),
            wa: area.wa.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2 })
        };

        return `${formattedArea.rai} ไร่ ${formattedArea.nga} งาน ${formattedArea.wa} วา`;
    };

    const handleClick = () => {
        if (farm_data.rice_type === '') {
            alert('คุณยังไม่ได้ทำการกรอกพันธุ์ข้าว\nกรุณากรอกข้อมูล')
            return
        } else if (farm_data.rice_seed_type === '') {
            alert('คุณยังไม่ได้ทำการกรอกประเภท\nกรุณากรอกข้อมูล')
            return
        } else if (farm_data.farm_name === '') {
            alert('คุณยังไม่ได้ทำการกรอกชื่อแปลง\nกรุณากรอกข้อมูล')
            return
        }
        const temp_farm_list = [...form_data.member_farm_list]
        const lastFarmListId = temp_farm_list.length > 0 ? temp_farm_list[temp_farm_list.length - 1].id : 0;
        const lastFarmListIndex = temp_farm_list.length > 0 ? temp_farm_list[temp_farm_list.length - 1].index : 0;
        const rai = parseFloat(farm_data.farm_rai) || 0
        const nga = parseFloat(farm_data.farm_nga) || 0
        const wa = parseFloat(farm_data.farm_wa) || 0

        temp_farm_list.push({
            id: lastFarmListId + 1,
            index: lastFarmListIndex + 1,
            farm_name: farm_data.farm_name,
            farm_rai: rai,
            farm_nga: nga,
            farm_wa: wa,
            farm_area: (rai * 4000) + (nga * 400) + (wa * 4),
            rice_type: farm_data.rice_type,
            rice_seed_type: farm_data.rice_seed_type,
            is_new: true,
            is_join: true
        })

        setFormData({ target: { id: 'member_farm_list', value: temp_farm_list } });
        setIs_open_dialog(false)
        setFarmData({
            rice_type: '',
            rice_seed_type: '',
            farm_name: '',
            farm_rai: 0,
            farm_nga: 0,
            farm_wa: 0,
        })
    }

    const formatIDCardNumber = (input) => {
        // Remove non-numeric characters
        const numericInput = input.replace(/\D/g, '');

        const maxLength = 13;
        const truncatedInput = numericInput.slice(0, maxLength);
        // Check if the input is not empty
        if (truncatedInput.length > 0) {
            let formattedInput = '';

            if (truncatedInput.length <= 2) {
                formattedInput = truncatedInput.replace(/^(\d{1})(\d{1})$/, '$1-$2');
            } else if (truncatedInput.length <= 5) {
                formattedInput = truncatedInput.replace(/^(\d{1})(\d{1,4})$/, '$1-$2');
            } else if (truncatedInput.length <= 10) {
                formattedInput = truncatedInput.replace(/^(\d{1})(\d{4})(\d{1,5})$/, '$1-$2-$3');
            } else if (truncatedInput.length <= 12) {
                formattedInput = truncatedInput.replace(/^(\d{1})(\d{4})(\d{5})(\d{1,2})$/, '$1-$2-$3-$4');
            } else {
                formattedInput = truncatedInput.replace(/^(\d{1})(\d{4})(\d{5})(\d{2})(\d{1})$/, '$1-$2-$3-$4-$5');
            }

            return formattedInput;
        }

        // Return an empty string if the input is empty
        return '';
    };

    return (
        <>
            <Dialog
                open={is_open}
                onClose={handleClose}
                fullWidth
                aria-labelledby="AddUser-Dialog-title"
                aria-describedby="AddUser-Dialog-Content"
                sx={{
                    "& .MuiDialog-container": {
                        "& .MuiPaper-root": {
                            width: "100%",
                            maxWidth: "1300px",  // Set your width here
                        },
                    },
                }}
            >
                <DialogTitle
                    id="AddUser-Dialog-title"
                >
                    {button_mode == "add" ? "เพิ่มสมาชิก" : "แก้ไขสมาชิก"}
                </DialogTitle>

                <DialogContent
                    id="AddUser-Dialog-Content"
                >
                    <Grid
                        container
                        alignItems="center"
                        spacing={2}
                        rowSpacing={2}
                        sx={{ mt: 0.5 }}
                    >
                        <Grid
                            item
                            xs={6}
                        >
                            <FormControl fullWidth>
                                <InputLabel id="position-select-input-label">ตำแหน่ง *</InputLabel>
                                <Select
                                    labelId="position-select-label"
                                    id="position_id"
                                    value={form_data?.position_id || ''}
                                    variant="outlined"
                                    label="ตำแหน่ง *"
                                    fullWidth
                                    onChange={(event) => {
                                        handlePositonChange(event.target.value, form_data?.position_id);
                                    }}
                                    disabled={isDisabled}
                                >
                                    <MenuItem key={0} value={0} disabled>กรุณาเลือกตำแหน่ง</MenuItem>
                                    {
                                        rcc_position_dropdown.map((item, index) => {
                                            return (
                                                <MenuItem key={index + 1} value={item.id} disabled={item.max_count <= 0 && form_data?.position_id !== item.id}>
                                                    {item.name}
                                                </MenuItem>
                                            );
                                        })
                                    }
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid
                            item
                            xs={6}
                        >
                            <TextField
                                fullWidth
                                id="citizen_id"
                                label="ค้นหาโดยเลขบัตรประชาชน"
                                type="text"
                                variant="outlined"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                style={{ display: button_mode === "edit" ? 'none' : 'block' }}
                                value={formatIDCardNumber(form_data.citizen_id)}
                                onChange={(e) => {
                                    const inputValue = e.target.value;
                                    const cleanedInput = inputValue.replace(/[^0-9]/g, '');
                                    const MAX_INPUT = 13

                                    if (cleanedInput.length <= MAX_INPUT) {
                                        setFormData({ target: { id: 'citizen_id', value: cleanedInput } });
                                    }
                                }}
                                InputProps={{
                                    endAdornment:
                                        <IconButton
                                            onClick={handleSearchClick}
                                        >
                                            <SearchIcon />
                                        </IconButton>
                                }}
                            />
                        </Grid>
                    </Grid>

                    <div style={{ padding: '30px 0 10px 0' }}>
                        <Accordion expanded={expanded}>
                            <AccordionSummary>
                                <Typography>ข้อมูลส่วนบุคคล</Typography>
                            </AccordionSummary>

                            <AccordionDetails>
                                <Grid
                                    container
                                    alignItems="center"
                                    spacing={2}
                                    rowSpacing={2}
                                    sx={{ mb: 2 }}
                                >
                                    <Grid item xs={2}>
                                        <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-readonly-label">คำนำหน้าชื่อ *</InputLabel>
                                            <Select
                                                fullWidth
                                                labelId="demo-simple-select-label"
                                                id="prefix_id"
                                                variant="outlined"
                                                label="คำนำหน้าชื่อ *"
                                                value={form_data.member_prefix_id || ''}
                                                onChange={(event) => {
                                                    handlePrefixChange(event.target.value);
                                                }}
                                                disabled={isDisabled || (form_data.member_uid && button_mode === 'add' ? true : false)}
                                            >
                                                <MenuItem key={0} value={0} disabled>กรุณาเลือกคำนำหน้าชื่อ</MenuItem>
                                                {
                                                    rcc_prefix_dropdown.map((item, index) => {
                                                        return (<MenuItem key={index + 1} value={item.id}>{item.name}</MenuItem>)
                                                    })
                                                }
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={5}>
                                        <TextField
                                            fullWidth
                                            id="member_name"
                                            label="ชื่อ"
                                            variant="outlined"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            value={form_data.member_name || ''}
                                            onChange={event => setFormData(event)}
                                            disabled={isDisabled || (form_data.member_uid && button_mode === 'add' ? true : false)}
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={5}>
                                        <TextField
                                            fullWidth
                                            id="member_surname"
                                            label="นามสกุล"
                                            variant="outlined"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            value={form_data.member_surname || ''}
                                            onChange={event => setFormData(event)}
                                            disabled={isDisabled || (form_data.member_uid && button_mode === 'add' ? true : false)}
                                            required
                                        />
                                    </Grid>

                                    <Grid item xs={4}>
                                        <TextField
                                            fullWidth
                                            id="member_id"
                                            label="เลขบัตรประชาชน"
                                            variant="outlined"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            value={formatIDCardNumber(form_data.member_id || '')}
                                            onChange={(e) => {
                                                const inputValue = e.target.value;
                                                const cleanedInput = inputValue.replace(/[^0-9]/g, '');
                                                const MAX_INPUT = 13

                                                if (cleanedInput.length <= MAX_INPUT) {
                                                    setFormData({ target: { id: 'member_id', value: cleanedInput } });
                                                }
                                            }}
                                            disabled={true}
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <TextField
                                            fullWidth
                                            type="date"
                                            id="member_birthday"
                                            label="วันเกิด"
                                            variant="outlined"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            inputProps={{
                                                max: new Date().toISOString().split('T')[0],
                                            }}
                                            value={form_data.member_birthday || ''}
                                            onChange={event => setFormData(event)}
                                            disabled={isDisabled || (form_data.member_uid && button_mode === 'add' ? true : false)}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <TextField
                                            fullWidth
                                            id="member_phone"
                                            label="เบอร์โทรศัพท์"
                                            variant="outlined"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            value={form_data.member_phone || ''}
                                            onChange={event => setFormData(event)}
                                            disabled={isDisabled || (form_data.member_uid && button_mode === 'add' ? true : false)}
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={2.4}>
                                        <TextField
                                            fullWidth
                                            id="member_addr"
                                            label="บ้านเลขที่"
                                            variant="outlined"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            value={form_data.member_addr || ''}
                                            onChange={event => setFormData(event)}
                                            disabled={isDisabled || (form_data.member_uid && button_mode === 'add' ? true : false)}
                                            required
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={2.4}>
                                        <TextField
                                            fullWidth
                                            id="member_moo"
                                            label="หมู่"
                                            variant="outlined"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            value={form_data.member_moo || ''}
                                            onChange={event => setFormData(event)}
                                            disabled={isDisabled || (form_data.member_uid && button_mode === 'add' ? true : false)}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={2.4}>
                                        <TextField
                                            fullWidth
                                            id="member_alley"
                                            label="ตรอก"
                                            variant="outlined"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            value={form_data.member_alley || ''}
                                            onChange={event => setFormData(event)}
                                            disabled={isDisabled || (form_data.member_uid && button_mode === 'add' ? true : false)}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={2.4}>
                                        <TextField
                                            fullWidth
                                            id="member_lane"
                                            label="ซอย"
                                            variant="outlined"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            value={form_data.member_lane || ''}
                                            onChange={event => setFormData(event)}
                                            disabled={isDisabled || (form_data.member_uid && button_mode === 'add' ? true : false)}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={2.4}>
                                        <TextField
                                            fullWidth
                                            id="member_road"
                                            label="ถนน"
                                            variant="outlined"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            value={form_data.member_road || ''}
                                            onChange={event => setFormData(event)}
                                            disabled={isDisabled || (form_data.member_uid && button_mode === 'add' ? true : false)}
                                        />
                                    </Grid>

                                    <Grid item xs={3}>
                                        <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-readonly-label">จังหวัด *</InputLabel>
                                            <Select
                                                fullWidth
                                                labelId="demo-simple-select-label"
                                                id="member_province_id"
                                                value={form_data.member_province_id || ''}
                                                variant="outlined"
                                                label="จังหวัด *"
                                                onChange={(event) => {
                                                    handleProvinceChange(event.target.value);
                                                }}
                                                disabled={isDisabled || (form_data.member_uid && button_mode === 'add' ? true : false)}
                                            >
                                                <MenuItem key={0} value={0} disabled>กรุณาเลือกจังหวัด</MenuItem>
                                                {
                                                    province_dropdown.map((item, index) => {
                                                        return (<MenuItem key={index + 1} value={item.id}>{item.name}</MenuItem>)
                                                    })
                                                }
                                            </Select>
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={3}>
                                        <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-readonly-label">เขต/อำเภอ *</InputLabel>
                                            <Select
                                                fullWidth
                                                labelId="demo-simple-select-label"
                                                id="member_amphur_id"
                                                variant="outlined"
                                                label="เขต/อำเภอ *"
                                                value={form_data.member_amphur_id || ''}
                                                onChange={(event) => {
                                                    handleAmphurChange(event.target.value);
                                                }}
                                                disabled={isDisabled || (form_data?.member_uid && button_mode === 'add' ? true : false)}
                                            >
                                                <MenuItem key={0} value={0} disabled>กรุณาเลือกเขต/อำเภอ</MenuItem>
                                                {
                                                    amphur_dropdown?.map((item, index) => (
                                                        <MenuItem key={index + 1} value={item.id}>
                                                            {item.name}
                                                        </MenuItem>
                                                    ))
                                                }
                                            </Select>
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={3}>
                                        <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-readonly-label">แขวง/ตำบล *</InputLabel>
                                            <Select
                                                fullWidth
                                                labelId="demo-simple-select-label"
                                                id="member_tambon_id"
                                                label="แขวง/ตำบล *"
                                                variant="outlined"
                                                value={form_data?.member_tambon_id || ''}
                                                onChange={(event) => {
                                                    handleTambonChange(event.target.value);
                                                }}
                                                disabled={isDisabled || (form_data?.member_uid && button_mode === 'add' ? true : false)}
                                            >
                                                <MenuItem key={0} value={0} disabled>กรุณาเลือกแขวง/ตำบล</MenuItem>
                                                {
                                                    tambon_dropdown?.map((item, index) => (
                                                        <MenuItem key={index + 1} value={item.id}>
                                                            {item.name}
                                                        </MenuItem>
                                                    ))
                                                }
                                            </Select>
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={3}>
                                        <TextField
                                            fullWidth
                                            id="member_zipcode"
                                            type='number'
                                            label="รหัสไปรษณีย์"
                                            variant="outlined"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            value={form_data?.member_zipcode || ''}
                                            onChange={event => setFormData(event)}
                                            disabled={isDisabled || (form_data?.member_uid && button_mode === 'add' ? true : false)}
                                        />
                                    </Grid>

                                </Grid>
                            </AccordionDetails>
                        </Accordion>

                        <Accordion expanded={expanded}>
                            <AccordionSummary>
                                <Typography>รายการแปลง</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid
                                    container
                                    alignItems="center"
                                    spacing={2}
                                    sx={{ mt: '-2rem' }}
                                    rowSpacing={2}
                                >
                                    <Grid
                                        item
                                        xs={12}
                                    >
                                        <div>
                                            <div style={{ display: 'flex' }}>
                                                <p>พื้นที่ปลูกทั้งหมด:</p>
                                                <div style={{ padding: '0 1rem' }}>
                                                    <p>
                                                        {convertFarmArea()}
                                                    </p>
                                                </div>
                                            </div>

                                            <div style={{ display: 'flex' }}>
                                                <p>พื้นที่ปลูกเมล็ดพันธ์ุข้าว:</p>
                                                <div style={{ padding: '0 1rem' }}>
                                                    <p>เมล็ดพันธุ์ข้าว</p>
                                                    <p>ข้าวคุณภาพดี</p>
                                                </div>
                                                <div>
                                                    <p>
                                                        {convertFarmArea('เมล็ดพันธุ์ข้าว')}
                                                    </p>
                                                    <p>
                                                        {convertFarmArea('ข้าวคุณภาพดี')}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        container
                                        spacing={2}
                                        direction="row"
                                        justifyContent="flex-end"
                                        alignItems="center"
                                    >

                                        <Grid
                                            item
                                            xs={2}
                                            alignItems="end"
                                            display={isDisabled && 'none'}
                                        >
                                            <Button
                                                fullWidth
                                                variant="contained"
                                                color="success"
                                                onClick={() => setIs_open_dialog(true)}
                                            >
                                                เพิ่มแปลง
                                            </Button>
                                        </Grid>
                                    </Grid>

                                    <Grid
                                        item
                                        xs={12}
                                    >
                                        {
                                            form_data.member_farm_list === undefined ? null :
                                                <FarmerFarmList
                                                    rows={form_data.member_farm_list}
                                                    handleClickEditInFarmList={handleClickEditInFarmList}
                                                    isDisabled={isDisabled}
                                                />
                                        }
                                    </Grid>
                                </Grid>
                            </AccordionDetails>
                        </Accordion>

                        <Grid
                            container
                            spacing={2}
                            direction="row"
                            justifyContent="flex-end"
                            alignItems="center"
                            sx={{ mt: 2 }}
                        >
                            <Grid item ></Grid>

                            <Grid item display={isDisabled && 'none'}>
                                <Button
                                    variant="contained"
                                    color="success"
                                    onClick={handleSaveButton}
                                >
                                    {button_mode == "add" ? "เพิ่มสมาชิก" : "แก้ไขสมาชิก"}
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    variant="contained"
                                    color="error"
                                    onClick={handleClose}
                                >
                                    ยกเลิก
                                </Button>
                            </Grid>
                        </Grid>
                    </div>

                </DialogContent>
            </Dialog >

            <Dialog
                open={is_open_dialog}
                onClose={() => setIs_open_dialog(false)}
                fullWidth
                aria-labelledby="AddUser-Dialog-title"
                aria-describedby="AddUser-Dialog-Content"
                sx={{
                    "& .MuiDialog-container": {
                        "& .MuiPaper-root": {
                            width: "100%",
                            maxWidth: "1200px",  // Set your width here
                        },
                    },
                }}
            >
                <DialogTitle id="AddUser-Dialog-title">เพิ่มแปลง</DialogTitle>
                <DialogContent id="AddUser-Dialog-Content">
                    <Grid container spacing={2} style={{ marginTop: '0.5rem' }}>
                        <Grid item xs={2}>
                            <FormControl fullWidth>
                                <InputLabel id="rice-select-readonly-label">พันธ์ุข้าว *</InputLabel>
                                <Select
                                    fullWidth
                                    labelId="rice-select-label"
                                    id="rice-seed_type"
                                    name="rice_seed_type"
                                    variant="outlined"
                                    label="พันธ์ุข้าว *"
                                    value={farm_data.rice_seed_type || ''}
                                    onChange={event => {
                                        handleChange({ target: { name: event.target.name, value: event.target.value } });
                                        handleChange({ target: { name: 'farm_name', value: event.target.value + farm_data.rice_type + (form_data.member_farm_list.length + 1) } });
                                    }}
                                    required
                                >
                                    <MenuItem key={0} value={''} disabled>กรุณาเลือกพันธ์ุข้าว</MenuItem>
                                    <MenuItem key={1} value={'ข้าวหอมมะลิ'}>ข้าวหอมมะลิ</MenuItem>
                                    <MenuItem key={2} value={'ข้าวเสาไห้'}>ข้าวเสาไห้</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={2}>
                            <FormControl fullWidth>
                                <InputLabel id="rice-type-select-readonly-label">ประเภทข้าว *</InputLabel>
                                <Select
                                    fullWidth
                                    labelId="rice-type-select-label"
                                    id="rice-type"
                                    name="rice_type"
                                    variant="outlined"
                                    label="ประเภทข้าว *"
                                    value={farm_data.rice_type || ''}
                                    onChange={event => {
                                        handleChange({ target: { name: event.target.name, value: event.target.value } });
                                        handleChange({ target: { name: 'farm_name', value: farm_data.rice_seed_type + event.target.value + (form_data.member_farm_list.length + 1) } });
                                    }}
                                    required
                                >
                                    <MenuItem key={0} value={''} disabled>กรุณาเลือกประเภทข้าว</MenuItem>
                                    <MenuItem key={1} value={'ข้าวคุณภาพดี'}>ข้าวคุณภาพดี</MenuItem>
                                    <MenuItem key={2} value={'เมล็ดพันธุ์ข้าว'}>เมล็ดพันธุ์ข้าว</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={8}>
                            <TextField
                                fullWidth
                                id="outlined-farm-name"
                                name="farm_name"
                                label="ชื่อแปลง"
                                variant="outlined"
                                value={farm_data.farm_name}
                                onChange={handleChange}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                required
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                fullWidth
                                id="outlined-farm-rai"
                                name="farm_rai"
                                label="ไร่"
                                variant="outlined"
                                type="number"
                                value={farm_data.farm_rai}
                                onChange={handleChange}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                fullWidth
                                id="outlined-farm-nga"
                                name="farm_nga"
                                label="งาน"
                                variant="outlined"
                                type="number"
                                value={farm_data.farm_nga}
                                onChange={handleChange}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                fullWidth
                                id="outlined-farm-wa"
                                name="farm_wa"
                                label="วา"
                                variant="outlined"
                                type="number"
                                value={farm_data.farm_wa}
                                onChange={handleChange}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                fullWidth
                                id="outlined-farm-area"
                                name="farm-area"
                                label="พื้นที่ (ตารางเมตร)"
                                variant="outlined"
                                value={convertSquareMeter()}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                disabled
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions style={{ padding: '1.5rem' }}>
                    <Button
                        variant="contained"
                        color="success"
                        onClick={() => handleClick()}
                    >
                        เพิ่มแปลง
                    </Button>

                    <Button
                        variant="contained"
                        color="error"
                        onClick={() => {
                            setFarmData({
                                rice_type: '',
                                rice_seed_type: '',
                                farm_name: '',
                                farm_rai: 0,
                                farm_nga: 0,
                                farm_wa: 0,
                            })
                            setIs_open_dialog(false)
                        }}
                    >
                        ยกเลิก
                    </Button>
                </DialogActions>
            </Dialog >
        </>
    )
}