import React, { useState, useEffect } from 'react';
import AdminLayout from '../../components/admin/AdminLayout'
import Card from '@mui/material/Card';
import { DataGrid } from '@mui/x-data-grid';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';


export default function CenterReport() {

  const [user_list, setUserList] = useState([])
  const columns = [
    { field: 'index', headerName: 'ลำดับ', width: 50 },
    { field: 'name', headerName: 'จำนวนศูนย์ข้าว', width: 150 },
    { field: 'rcc_name', headerName: 'จำนวนสมาชิก', width: 150 },
    { field: 'rcc_status', headerName: 'จำนวนพื้นที่เพาะปลูก', width: 150 }
  ];
  const columns2 = [
    { field: 'index', headerName: 'ลำดับ', width: 50 },
    { field: 'ddd', headerName: 'ภาค', width: 150 },
    { field: 'name', headerName: 'จำนวนศูนย์ข้าว', width: 150 },
    { field: 'rcc_name', headerName: 'จำนวนสมาชิก', width: 150 },
    { field: 'rcc_status', headerName: 'จำนวนพื้นที่เพาะปลูก', width: 150 }
  ];

  const columns3 = [
    { field: 'index', headerName: 'ลำดับ', width: 50 },
    { field: 'ddd', headerName: 'จังหวัด', width: 150 },
    { field: 'name', headerName: 'จำนวนศูนย์ข้าว', width: 150 },
    { field: 'rcc_name', headerName: 'จำนวนสมาชิก', width: 150 },
    { field: 'rcc_status', headerName: 'จำนวนพื้นที่เพาะปลูก', width: 150 }
  ];

  const columns4 = [
    { field: 'index', headerName: 'ลำดับ', width: 50 },
    { field: 'ddd', headerName: 'สถานะศูนย์ข้าวชุมชน', width: 150 },
    { field: 'name', headerName: 'จำนวนศูนย์ข้าว', width: 150 },
    { field: 'rcc_name', headerName: 'จำนวนสมาชิก', width: 150 },
    { field: 'rcc_status', headerName: 'จำนวนพื้นที่เพาะปลูก', width: 150 }
  ];
  return (
    <div style={{ margin: '2rem' }}>
      <AdminLayout />
      <Grid container className='content-box'>
        <Grid item xs={12}>
          <Typography variant='h4'>รายงานศูนย์ข้าวชุมชน</Typography>
        </Grid>
        <Grid item xs={6} >
          <Card style={{ marginTop: '1.5rem', marginRight: '1%', marginLeft: '1%' }}>
            <CardContent>
            <Grid>
              <Typography variant='h4'>จำนวนศูนย์ข้าวชุมชนทั้งหมด</Typography>
            </Grid>
              <Grid>
                <div style={{ height: 300, width: '100%', marginTop: '1rem' }}>
                  <DataGrid
                    style={{ backgroundColor: "#FFFFFF" }}
                    rows={user_list}
                    columns={columns}
                    rowsPerPageOptions={[]}
                  />
                </div>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={6} >
          <Card style={{ marginTop: '1.5rem', marginRight: '1%', marginLeft: '1%' }}>
            <CardContent>
            <Grid>
              <Typography variant='h4'>จำนวนศูนย์ข้าวชุมชนแยกตามภาค</Typography>
            </Grid>
              <Grid>
                <div style={{ height: 300, width: '100%', marginTop: '1rem' }}>
                  <DataGrid
                    style={{ backgroundColor: "#FFFFFF" }}
                    rows={user_list}
                    columns={columns2}
                    rowsPerPageOptions={[]}
                  />
                </div>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={6} >
          <Card style={{ marginTop: '1.5rem', marginRight: '1%', marginLeft: '1%' }}>
            <CardContent>
            <Grid>
              <Typography variant='h4'>จำนวนศูนย์ข้าวชุมชนแยกตามจังหวัด</Typography>
            </Grid>
              <Grid>
                <div style={{ height: 300, width: '100%', marginTop: '1rem' }}>
                  <DataGrid
                    style={{ backgroundColor: "#FFFFFF" }}
                    rows={user_list}
                    columns={columns3}
                    rowsPerPageOptions={[]}
                  />
                </div>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={6} >
          <Card style={{ marginTop: '1.5rem', marginRight: '1%', marginLeft: '1%' }}>
            <CardContent>
            <Grid>
              <Typography variant='h4'>จำนวนศูนย์ข้าวชุมชนแยกตามสถานะ</Typography>
            </Grid>
              <Grid>
                <div style={{ height: 300, width: '100%', marginTop: '1rem' }}>
                  <DataGrid
                    style={{ backgroundColor: "#FFFFFF" }}
                    rows={user_list}
                    columns={columns4}
                    rowsPerPageOptions={[]}
                  />
                </div>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  )
}