import React from 'react';
import { DataGrid } from '@mui/x-data-grid';
// import IconButton from '@mui/material/IconButton';
// import DeleteIcon from '@mui/icons-material/Delete';
// import { Edit } from '@mui/icons-material';

export default function FarmerFarmList({
  rows,
  row_count,
  page_count,
  handlePageSizeChange,
  handlePageChange,
  handleClickEditInFarmList,
  isDisabled
}) {
  const columns = [
    { field: 'index', headerName: 'ลำดับ', width: 50, align: 'center' },
    { field: 'farm_name', headerName: 'ชื่อ แปลง', flex: 1, minWidth: 150 },
    {
      field: 'farm_rai',
      headerName: 'ไร่',
      width: 150,
      renderCell: ({ row }) => row.farm_rai.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2 })
    },
    {
      field: 'farm_nga',
      headerName: 'งาน',
      width: 150,
      renderCell: ({ row }) => row.farm_nga.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2 })
    },
    {
      field: 'farm_wa',
      headerName: 'วา',
      width: 150,
      renderCell: ({ row }) => row.farm_wa.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2 })
    },
    { field: 'is_join', headerName: 'เข้าร่วม', width: 100, type: 'boolean', editable: !isDisabled },
    { field: 'rice_seed_type', headerName: 'ประเภทข้าว', width: 150, type: 'singleSelect', editable: !isDisabled, valueOptions: ['ข้าวหอมมะลิ', 'ข้าวเสาไห้'] },
    { field: 'rice_type', headerName: 'พันธุ์ข้าว', width: 150, type: 'singleSelect', editable: !isDisabled, valueOptions: ['เมล็ดพันธุ์ข้าว', 'ข้าวคุณภาพดี'] },
  ]
  return (
    <div style={{ height: 400, width: '100%', marginTop: '0rem' }}>
      <DataGrid
        style={{ backgroundColor: "#FFFFFF" }}
        rows={rows}
        rowCount={row_count}
        columns={columns}
        pageSize={page_count}
        onPageSizeChange={(newPageSize) => handlePageSizeChange(newPageSize)}
        onPageChange={(newPage) => handlePageChange(newPage)}
        onCellEditCommit={(event, new_value) => handleClickEditInFarmList(event, new_value)}
        disableSelectionOnClick={true}
        // rowsPerPageOptions={[10, 25, 50, 100]}
      />
    </div>
  )
}