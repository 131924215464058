import React, { useState, useRef } from 'react'
import "leaflet/dist/leaflet.css"
import "leaflet-draw/dist/leaflet.draw.css"
import { MapContainer, TileLayer, FeatureGroup, useMapEvents, Popup, Marker, Tooltip, Polygon } from "react-leaflet";
import { EditControl } from "react-leaflet-draw";
import { polygon, area } from '@turf/turf';
import Button from '@mui/material/Button';

function MapComponent(props) {
  const [polygonArea, setPolygonArea] = useState(0);
  const [center, setCenter] = useState([13.7367, 100.5231])
  const [mapLayers, setMapLayers] = useState([props.layers ? props.layers : []])

  console.log(mapLayers)

  const sendDataToParent = (polygonArea) => {
    const data = polygonArea
    props.onData(data);
  };

  const sendDataToParent2 = (mapLayers) => {
    const data = mapLayers
    props.onData2(data);
  };

  const ZOOM_LEVEL = 13
  const mapRef = useRef(null);

  const calculatePolygonArea = (polygonCoords) => {
    console.log(polygonCoords);
    if (polygonCoords.length > 0) {
      const updatedCoords = [...polygonCoords];
      updatedCoords.push(polygonCoords[0]);

      const turfPolygon = polygon([updatedCoords.map(({ lat, lng }) => [lng, lat])]);
      const polygonArea = area(turfPolygon);

      setPolygonArea(polygonArea);
      sendDataToParent(polygonArea)
    } else {
      console.log('0');
      setPolygonArea(0);
    }
  };

  const _onCreate = e => {
    console.log(e);

    const { layerType, layer } = e;
    if (layerType === "polygon") {
      const { _leaflet_id } = layer;

      setMapLayers((layers) => [
        ...layers,
        { id: _leaflet_id, latlng: layer.getLatLngs()[0] }]);
    }
    calculatePolygonArea(layer.getLatLngs()[0])
    sendDataToParent2(layer.getLatLngs()[0])

  }

  const _onEdited = e => {
    console.log(e)

    const { layers: { _layers } } = e;
    Object.values(_layers).map(({ _leaflet_id, editing }) => {
      setMapLayers((layers) =>
        layers.map((l) =>
          l.id === _leaflet_id ? { ...l, latlng: { ...editing.latlngs[0] } } : l
        )
      );
    });

    const latLngs = Object.values(_layers)[0].editing.latlngs[0];
    calculatePolygonArea(latLngs[0])
  };

  const _onDeleted = e => {
    console.log(e)

    const { layers: { _layers } } = e;
    Object.values(_layers).map((_leaflet_id) => {
      setMapLayers(layers => layers.filter(l => l.id !== _leaflet_id));
    })

    setPolygonArea(0)
    sendDataToParent(0)
  }

  return (
    <div>
      {console.log(props.onEdit)}
      {props.onEdit && props.layers && (
        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '-3rem', }}>
          <Button
            variant="contained"
            color="primary"
            onClick={(event) => setMapLayers([[]])}
          >
            ลบแปลง
          </Button>
        </div>
      )}
      <br />
      <MapContainer
        center={center}
        zoom={ZOOM_LEVEL}
        style={{ width: '100%', height: '350px' }}
        ref={mapRef}
      >
        <FeatureGroup>
          <EditControl position='topright'
            onCreated={_onCreate}
            onEdited={_onEdited}
            onDeleted={_onDeleted}
            draw={{
              rectangle: false,
              polyline: false,
              circle: false,
              circlemarker: false,
              marker: false,
            }}

          />
        </FeatureGroup>
        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" attribution="Map data © OpenStreetMap contributors" />
        <Polygon pathOptions={{ color: 'purple' }} positions={mapLayers} />
        {/* <div class="leaflet-control-attribution leaflet-control bott"><a href="https://leafletjs.com" title="A JS library for interactive maps">Leaflet</a> | © <a href="https://www.maptiler.com/">MapTiler</a> © <a href="http://osm.org/copyright">OpenStreetMap</a> contributors</div> */}
        {/* <LocationMarker /> */}
      </MapContainer>
      {/* <pre className='text-left'>{JSON.stringify(mapLayers, 0, 2)}</pre> */}
    </div >
  );
}

export default MapComponent


