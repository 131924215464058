import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

export default function UtilityBackdrop({ open }) {
    return (
        <>
            <Backdrop
                sx={{ color: '#fff', zIndex: 1300}}
                open={open}
                // onClick={handleClose}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    )
}