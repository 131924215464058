import React, { useState, useEffect } from 'react';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import StarIcon from '@mui/icons-material/Star';
import ReceiptIcon from '@mui/icons-material/Receipt';
import Link from '@mui/material/Link';
import { logout } from '../../utils/Auth'
import LocalStorage from '../../utils/LocalStorage';
import jwt_decode from "jwt-decode";
import { Dashboard, Logout } from '@mui/icons-material';

export default function AdminMenu() {
  const [menu_list, setMenuList] = useState([])
  const [permis_list, setPermisList] = useState([])
  const handleLogout = () => {
    logout()
  }

  useEffect(() => {
    let access_token = LocalStorage.getAccessToken();
    var decoded_token = jwt_decode(access_token);
    console.log(decoded_token);
    // setMenuList(decoded_token.menu_list)
    // setPermisList(decoded_token.permis_list)
    let temp_list = []
    for (let menu_items of decoded_token.menu_list) {
      for (let permis_items of decoded_token.permis_list) {
        if (
          menu_items.menu_id === permis_items.main_menu_id
          && permis_items.permis_action === "access"
          && permis_items.permis_value
        ) {
          temp_list.push({
            "menu_uri": menu_items.menu_uri,
            "menu_name": menu_items.menu_name
          })
        }
        // else{
        //   break;
        // }
      }
    }
    setMenuList(temp_list)
  }, []);

  return (
    <>
      <List>
        <Link href="/dashboard" color="inherit" underline="none">
          <ListItemButton
          // onClick={() => handleLogout()}
          >
            <ListItemIcon>
              <Dashboard />
            </ListItemIcon>
            <ListItemText primary="Dashboard" />
          </ListItemButton>
        </Link>
        {/* {
          menu_list.map((data, index) => {
            return (
              <Link key={'menu' + index} href={data.menu_uri} color="inherit" underline="none">
                <ListItemButton >
                  <ListItemIcon>
                    <ReceiptIcon />
                  </ListItemIcon>
                  <ListItemText primary={data.menu_name} />
                </ListItemButton>
              </Link>
            )
          })
        } */}
        <Link key={'menu2'} href="/rcc/list" color="inherit" underline="none">
          <ListItemButton >
            <ListItemIcon>
              <ReceiptIcon />
            </ListItemIcon>
            <ListItemText primary="รายการศูนย์ข้าวชุมชน" />
          </ListItemButton>
        </Link>
        <Link key={'menu3'} href="/rcc/committe/amphur" color="inherit" underline="none">
          <ListItemButton >
            <ListItemIcon>
              <ReceiptIcon />
            </ListItemIcon>
            <ListItemText primary="รายการคณะกรรมการศูนย์ข้าวระดับอำเภอ" />
          </ListItemButton>
        </Link>
        <Link key={'menu4'} href="/rcc/committe/tambon" color="inherit" underline="none">
          <ListItemButton >
            <ListItemIcon>
              <ReceiptIcon />
            </ListItemIcon>
            <ListItemText primary="รายการคณะกรรมการศูนย์ข้าวระดับตำบล" />
          </ListItemButton>
        </Link>
        <Link key={'menu5'} href="/rcc/renew/list" color="inherit" underline="none">
          <ListItemButton >
            <ListItemIcon>
              <ReceiptIcon />
            </ListItemIcon>
            <ListItemText primary="รายการแจ้งเตือนต่ออายุ" />
          </ListItemButton>
        </Link>
      </List>

      <Link href="/auth/login" color="inherit" underline="none">
        <ListItemButton
          style={{ position: 'absolute', bottom: 10, width: '100%' }}
          onClick={() => handleLogout()}
        >
          <ListItemIcon>
            <Logout />
          </ListItemIcon>
          <ListItemText primary="ออกจากระบบ" />
        </ListItemButton>
      </Link>
    </>
  );
}
