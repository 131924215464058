import axios from 'axios';
import config from '../config.json';
import LocalStorage from '../utils/LocalStorage';

export const getFarmerByCitizenId = async (citizen_id) => {
  let token = LocalStorage.getAccessToken();
  return axios.get(
    config.PAF_API + "/farmers/" + citizen_id,
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    }
  )
}

export const getFarmerByUID = async (uid) => {
  let token = LocalStorage.getAccessToken();
  return axios.get(
    config.PAF_API + "/farmers/uid/" + uid,
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    }
  )
}

export const getPersonalByUID = async (uid) => {
  let token = LocalStorage.getAccessToken();
  return axios.get(
    config.PAF_API + "/personals/uid/" + uid,
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    }
  )
}

export const savePersonal = async (body) => {
  console.log(body)
  let token = LocalStorage.getAccessToken();
  return axios.post(
    config.PAF_API + "/personals", body,
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    }
  )
}

export const saveFarmer = async (body) => {
  console.log(body)
  let token = LocalStorage.getAccessToken();
  return axios.post(
    config.PAF_API + "/farmers", body,
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    }
  )
}

export const updatePersonal = async (id, data) => {
  let token = LocalStorage.getAccessToken();
  return axios.put(
    config.PAF_API + "/personals/" + id, data,
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    }
  )
}

export const getProvinceByCode = async (data) => {
  let token = LocalStorage.getAccessToken();
  return axios.post(
    config.PAF_API + "/province", data,
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    }
  )
}