import React, { useState, useEffect } from 'react';
import {
    Dialog,
    DialogContent,
    DialogTitle,
    Grid,
    FormControl,
    Button,
    DialogActions,
    FormLabel,
    Divider,
    Typography,
    FormControlLabel,
    RadioGroup,
    Radio
} from '@mui/material';

import {
    callGetService, callPostService
} from '../services/Service'

const RCCQuestionnairForm = ({ is_open, setIsOpen, rcc_id }) => {
    const [answer_list, setAnswerList] = useState([])
    const [section_question_list, setSectionQuestionList] = useState([])
    const [score, setScore] = useState(0)

    useEffect(() => {
        callGetService('/question-section-mds')
            .then(res => {
                // console.log(res.data.data);
                setSectionQuestionList(res.data.data)
                let temp_list = []
                let index = 0
                let section_data = res.data.data
                for (let section_item of section_data) {
                    // console.log(section_item);
                    for (let question_data of section_item.attributes.question_mds.data) {
                        index++
                        temp_list.push({
                            index: index,
                            id: question_data.id,
                            value: 0
                        })
                    }
                }
                // console.log(temp_list);
                setAnswerList(temp_list)
            })
    }, []);

    const handleChange = (event, question) => {
        console.log(event);
        console.log(question);
        let temp_list = []
        let total_score = 0;

        for (let temp_item of answer_list) {
            if (temp_item.id === question.id) {
                total_score += parseInt(event.target.value)
                temp_list.push({
                    index: temp_item.index,
                    id: temp_item.id,
                    value: parseInt(event.target.value)
                })
            } else {
                total_score += temp_item.value
                temp_list.push({
                    index: temp_item.index,
                    id: temp_item.id,
                    value: temp_item.value
                })
            }
        }

        setScore(total_score)
        setAnswerList(temp_list)

    };

    // const findGrade = ()

    const handleSaveButton = () => {
        console.log(answer_list);
        let total_score = 0;
        let is_error = false

        for (let temp_item of answer_list) {
            if (temp_item.value === 0) {
                alert("คุณยังทำการตอบคำถามไม่ครบทุกข้อ \nกรุณาตรวจสอบอีกครั้ง")
                is_error = true
                break
            } else {
                total_score += temp_item.value
            }
        }

        if (!is_error) {
            total_score *= 100 / 42.0
            console.log(total_score);
            let grade = ""
            if (total_score < 50) {
                grade = "D"
            } else if (total_score < 70) {
                grade = "C"
            } else if (total_score < 80) {
                grade = "B"
            } else {
                grade = "A"
            }

            let question_sum = {
                data: {
                    ans_date: new Date().toISOString().split('T')[0],
                    total_score: total_score,
                    ans_grade: grade,
                    rcc: rcc_id
                }
            }
            console.log(question_sum);

            callPostService("/rcc-question-sums", question_sum)
                .then(res => {
                    console.log('res', res)

                    // let question_sum_id = res.data.data.id;
                    // for (let temp_item of answer_list) {
                    //     let question_sum_details = {
                    //         data: {
                    //             rcc_question_sum: question_sum_id,
                    //             score: temp_item.value,
                    //             question_md: temp_item.id
                    //         }
                    //     }
                    //     console.log('question_sum_details', question_sum_details)
                    //     callPostService("/rcc-question-details", question_sum_details)
                    //         .then(res => {
                    //             console.log('res', res)
                    //         })
                    // }

                    alert("ขอบคุณที่ทำแบบประเมิน")
                    setIsOpen(false)
                    window.location.reload()
                })
        }

    }

    return (
        <>
            <Dialog
                open={is_open}
                onClose={(event) => setIsOpen(false)}
                fullWidth
                aria-labelledby="AddUser-Dialog-title"
                aria-describedby="AddUser-Dialog-Content"
                sx={{
                    "& .MuiDialog-container": {
                        "& .MuiPaper-root": {
                            width: "100%",
                            maxWidth: "800px",  // Set your width here
                        },
                    },
                }}
            >
                <DialogTitle id="AddUser-Dialog-title">เพิ่มแบบทดสอบ</DialogTitle>
                <DialogContent id="AddUser-Dialog-Content">
                    <Grid container spacing={2} sx={{ paddingTop: 1 }}>
                        {
                            section_question_list.map((item, i) => {
                                return (
                                    <Grid item xs={12} key={i}>
                                        <Typography variant="h7" gutterBottom sx={{ fontSize: '19px' }}>
                                            {item.attributes.text}
                                        </Typography>
                                        <br />
                                        <br />
                                        <Grid item xs={12}>
                                            {
                                                (item.attributes.question_mds.data).sort((a, b) => a.attributes.section_order - b.attributes.section_order).map((question, index) => {
                                                    return (
                                                        <FormControl
                                                            sx={{
                                                                mt: 1, mb: 1,
                                                            }}
                                                            key={index}
                                                        >
                                                            <FormLabel id="demo-row-radio-buttons-group-label">{question.attributes.section_index}. {question.attributes.text}</FormLabel>
                                                            <RadioGroup
                                                                row
                                                                aria-labelledby="demo-row-radio-buttons-group-label"
                                                                name="row-radio-buttons-group"
                                                                onChange={(event) => handleChange(event, question)}
                                                            >
                                                                <FormControlLabel value="1" control={<Radio />} label={question.attributes.score1_desc} />
                                                                <FormControlLabel value="2" control={<Radio />} label={question.attributes.score2_desc} />
                                                                <FormControlLabel value="3" control={<Radio />} label={question.attributes.score3_desc} />
                                                            </RadioGroup>
                                                        </FormControl>
                                                    )
                                                })
                                            }
                                        </Grid>
                                        <Divider sx={{ mt: 3, mb: 3 }} />
                                    </Grid>
                                )
                            })
                        }
                        {/* ) */}
                    </Grid>
                </DialogContent>

                <DialogActions sx={{ m: 2, display: 'flex', justifyContent: 'space-between' }}>
                    <Typography>ทำไปแล้ว {answer_list.filter(item => item.value !== 0).length} ข้อ จาก {answer_list.length} ข้อ<br />ทั้งหมด {score} คะแนน</Typography>
                    <div>
                        <Button
                            onClick={handleSaveButton}
                            variant="contained"
                            color="success"
                            sx={{ mr: 1 }}
                        >
                            บันทึก
                        </Button>

                        <Button
                            variant="contained"
                            onClick={(event) => setIsOpen(false)}
                            color="error">
                            ยกเลิก
                        </Button>
                    </div>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default RCCQuestionnairForm;