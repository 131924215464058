import React, { useState, useEffect } from 'react'
import {
    Grid,
    Typography,
    Button,
    Card,
    CardContent,
} from '@mui/material'
import RccMemberList from '../list/rcc-member-list'

export default function RccMemberSection({
    handleAddClick,
    rows,
    row_count,
    handlePageChange,
    handlePageSizeChange,
    handleDeleteMemberList,
    handleEditMemberList,
    isDisabled,
    isRenew
}) {
    return (
        <>
            <Card style={{ marginTop: '1rem', marginRight: '1%', marginLeft: '1%' }}>
                <CardContent>
                    <Grid container spacing={2}>
                        <Grid item xs>
                            <Typography variant='h6' style={{ paddingBottom: '20px' }}>สมาชิกศูนย์ข้าวชุมชน</Typography>
                        </Grid>
                        <Grid item display={isDisabled && 'none'}>
                            <Button
                                variant="contained"
                                color='success'
                                onClick={handleAddClick}
                                disabled={isRenew}
                            >
                                เพิ่ม
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <RccMemberList
                                rows={rows}
                                row_count={row_count}
                                handlePageChange={handlePageChange}
                                handlePageSizeChange={handlePageSizeChange}
                                handleDeleteMemberList={handleDeleteMemberList}
                                handleEditMemberList={handleEditMemberList}
                                isDisabled={isDisabled}
                            />
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </>
    )
}