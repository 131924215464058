import React, { useState } from 'react'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Button from '@mui/material/Button';
import Autocomplete from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';

import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

import RCCQuestionnairForm from '../../components/RCCQuestionnairForm'
import RCCQuestionnairHistory from '../../components/RCCQuestionnairHistory'

import { useLocation } from 'react-router-dom';
import { callGetService, callPutService } from '../../services/Service';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function RccForm({
    data,
    setData,
    setRCCFormData,
    province_dropdown,
    handleProvinceChange,
    amphur_dropdown,
    handleAmphurChange,
    amphur_init,
    tambon_dropdown,
    handleTambonChange,
    tambon_init,
    handleSaveButton,
    isDisabled,
    mode,
    isRenew,
    project_rd_dropdown,
    setSelectedProjectRD,
    project_rds
}) {
    const [is_questionair_form_open, setIsQestionnairForm] = useState(false)
    const [is_questionair_history_open, setIsQestionnairHistory] = useState(false)
    const location = useLocation();
    const rcc_id = location.pathname.split('/')[3]

    const handleRCCContinue = async () => {
        let temp_list = []
        let index = 0
        await callGetService('/rcc-question-sums/' + rcc_id)
            .then(res => {
                let data = res.data.data
                // console.log('data>>>', data)
                for (let item of data) {
                    index++
                    // console.log('item',item);
                    temp_list.push({
                        id: index,
                        index: index,
                        ans_date: item.attributes.ans_date,
                        score: item.attributes.total_score.toFixed(2).toString() + "%",
                        grade: item.attributes.ans_grade
                    })
                }
            })

        if (temp_list.length === 0) {
            alert('กรุณาทำแบบประเมินก่อน')
        } else {
            let data = {
                data: {
                    renew_rcc_registration_date: new Date()
                }
            }
            await callPutService('/rcc/' + rcc_id, data)
                .then((res) => {
                    console.log('res callPutService', res)
                })
        }
    }

    return (
        <>
            <Card style={{ marginTop: '1rem', marginRight: '1%', marginLeft: '1%' }}>
                <CardContent>
                    <Typography variant='h6' style={{ paddingBottom: '20px' }}>ข้อมูลรายละเอียดศูนย์ข้าวชุมชน</Typography>
                    <Grid
                        container
                        spacing={2}
                        alignItems={"center"}
                    >
                        <Grid item xs={1}>
                            <TextField
                                fullWidth
                                id="temp_number"
                                label="รหัส"
                                variant="outlined"
                                value={data.temp_number || ''}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onChange={event => setData(event)}
                                required
                                disabled={isDisabled}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                fullWidth
                                id="name_center"
                                label="ชื่อศูนย์ข้าวชุมชน"
                                variant="outlined"
                                value={data.name_center || ''}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                onChange={event => setData(event)}
                                required
                                disabled={isDisabled}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <TextField
                                fullWidth
                                id="start_date"
                                label="เริ่มก่อตั้งปี"
                                type="date"
                                variant="outlined"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                inputProps={{
                                    max: new Date().toISOString().split('T')[0],
                                }}
                                value={data.start_date || ''}
                                onChange={event => setData(event)}
                                required
                                disabled={isDisabled}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <TextField
                                fullWidth
                                id="year_reg"
                                label="ปีที่ขึ้นทะเบียนกับกรมการข้าว"
                                type="date"
                                variant="outlined"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                inputProps={{
                                    max: new Date().toISOString().split('T')[0],
                                }}
                                value={data.year_reg || ''}
                                onChange={event => setData(event)}
                                required
                                disabled={isDisabled}
                            />
                        </Grid>

                        <Grid item xs={1}>
                            <TextField
                                fullWidth
                                id="address"
                                label="บ้านเลขที่"
                                variant="outlined"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={data.address || ''}
                                onChange={event => setData(event)}
                                required
                                disabled={isDisabled}
                            />
                        </Grid>
                        <Grid item xs={1}>
                            <TextField
                                fullWidth
                                id="moo"
                                label="หมู่ที่"
                                variant="outlined"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={data.moo || ''}
                                onChange={event => setData(event)}
                                required
                                disabled={isDisabled}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <TextField
                                fullWidth
                                id="village"
                                label="หมู่บ้าน"
                                variant="outlined"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={data.village || ''}
                                onChange={event => setData(event)}
                                disabled={isDisabled}
                            />
                        </Grid>
                        <Grid item xs={3.5}>
                            {/* จังหวัด */}
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-readonly-label">จังหวัด *</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="province_id"
                                    variant="outlined"
                                    label="จังหวัด *"
                                    fullWidth
                                    value={data.province_id || ''}
                                    onChange={event => handleProvinceChange(event.target.value)}
                                    // onChange={event => handleProvinceChange(event.target.value)}
                                    disabled={isDisabled}
                                >
                                    <MenuItem key={"province_0"} value={0} disabled>กรุณาเลือกจังหวัด</MenuItem>
                                    {
                                        province_dropdown.map((item, index) => {
                                            return (<MenuItem key={"province_" + (index + 1)} value={item.id}>{item.name}</MenuItem>)
                                        })
                                    }
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={3.5}>
                            {/* จังหวัด */}
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-readonly-label">อำเภอ *</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="amphur_id"
                                    value={data.amphur_id || ''}
                                    variant="outlined"
                                    label="อำเภอ *"
                                    fullWidth
                                    onChange={(event) => {
                                        handleAmphurChange(event.target.value);
                                    }}
                                    disabled={isDisabled}
                                >
                                    <MenuItem key={"amphur_0"} value={0} disabled>{amphur_init}</MenuItem>
                                    {
                                        amphur_dropdown.map((item, index) => {
                                            return (<MenuItem key={"amphur_" + (index + 1)} value={item.id}>{item.name}</MenuItem>)
                                        })
                                    }
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={3.5}>
                            {/* จังหวัด */}
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-readonly-label">ตำบล *</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="tambon_id"
                                    value={data.tambon_id || ''}
                                    variant="outlined"
                                    label="ตำบล *"
                                    fullWidth
                                    onChange={(event) => {
                                        handleTambonChange(event.target.value);
                                    }}
                                    disabled={isDisabled}
                                >
                                    <MenuItem key={"tambon_0"} value={0} disabled>{tambon_init}</MenuItem>
                                    {
                                        tambon_dropdown.map((item, index) => {
                                            return (<MenuItem key={"tambon_" + (index + 1)} value={item.id}>{item.name}</MenuItem>)
                                        })
                                    }
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={1.5}>
                            <TextField
                                fullWidth
                                id="zipcode"
                                label="รหัสไปรษณีย์"
                                variant="outlined"
                                // value={community_center_post_code}
                                InputLabelProps={{
                                    shrink: true,
                                    maxLength: 5
                                }}
                                value={data.zipcode || ''}
                                onChange={event => setData(event)}
                                required
                                disabled={isDisabled}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-readonly-label">UTM Zone *</InputLabel>
                                <Select
                                    fullWidth
                                    labelId="utm_zone-label"
                                    id="utm_zone"
                                    name="utm_zone"
                                    variant="outlined"
                                    label="UTM Zone *"
                                    value={data.utm_zone || ''}
                                    onChange={event => setData({ target: { id: 'utm_zone', value: event.target.value } })}
                                    disabled={isDisabled}
                                >
                                    <MenuItem key={""} value={0} disabled>UTM Zone</MenuItem>
                                    <MenuItem key={"zone_47"} value={"47"}>47</MenuItem>
                                    <MenuItem key={"zone_48"} value={"48"}>48</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={2}>
                            <TextField
                                fullWidth
                                id="utm_x"
                                label="UTM X"
                                variant="outlined"
                                InputLabelProps={{
                                    shrink: true,
                                    // maxLength: 5
                                }}
                                value={data.utm_x || ''}
                                onChange={event => setData(event)}
                                required
                                disabled={isDisabled}
                            // onChange={event => setCommunityCenterPostCode(event.target.value)}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <TextField
                                fullWidth
                                id="utm_y"
                                label="UTM Y"
                                variant="outlined"
                                InputLabelProps={{
                                    shrink: true,
                                    // maxLength: 5
                                }}
                                value={data.utm_y || ''}
                                onChange={event => setData(event)}
                                required
                                disabled={isDisabled}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                fullWidth
                                id="member"
                                label="จำนวนสมาชิกเมื่อเริ่มต่อตั้ง"
                                variant="outlined"
                                InputLabelProps={{
                                    shrink: true,
                                    // maxLength: 5
                                }}
                                value={data.member || ''}
                                onChange={event => setData(event)}
                                disabled={isDisabled}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                fullWidth
                                id="fund"
                                label="จำนวนเงินกองทุนเมื่อเริ่มก่อตั้ง"
                                variant="outlined"
                                InputLabelProps={{
                                    shrink: true,
                                    // maxLength: 5
                                }}
                                value={data.fund || ''}
                                onChange={event => setData(event)}
                                disabled={isDisabled}
                            />
                        </Grid>
                        <Grid item xs={12}>

                            <FormControl fullWidth>
                                {isDisabled ?
                                    <TextField
                                        fullWidth
                                        id="fund"
                                        label="โครงการ"
                                        variant="outlined"
                                        InputLabelProps={{
                                            shrink: true,
                                            // maxLength: 5
                                        }}
                                        value={project_rds || ''}
                                        // onChange={event => setData(event)}
                                        disabled={isDisabled}
                                    />
                                    :
                                    <Autocomplete
                                        multiple
                                        id="checkboxes-tags-demo"
                                        options={project_rd_dropdown}
                                        disableCloseOnSelect
                                        getOptionLabel={(option) => option.name}
                                        // defaultValue={project_rds}
                                        value={project_rds}
                                        // getOptionSelected={(option, value) => option.id === value.id}
                                        isOptionEqualToValue={(option, value) => option.id === value.id}
                                        onChange={(event, new_value) => {
                                            setSelectedProjectRD(new_value)
                                            // console.log("project_rds", project_rds);
                                        }}
                                        disabled={isDisabled}
                                        renderOption={(props, option,  { selected }) => (
                                            <li {...props}>
                                                <Checkbox
                                                    icon={icon}
                                                    checkedIcon={checkedIcon}
                                                    style={{ marginRight: 8 }}
                                                    checked={selected}
                                                />
                                                {option.name}
                                            </li>
                                        )}
                                        style={{ width: '100%' }}
                                        renderInput={(params) => (
                                            <TextField {...params} label="โครงการ" placeholder="โครงการ" />
                                        )}
                                    />
                                }
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel
                                sx={{ paddingTop: '10px' }}
                                control={
                                    <Switch
                                        id={"is_have_license_gather"}
                                        checked={data.is_have_license_gather || false}
                                        onChange={event => setData(event)}
                                        sx={{
                                            "&.MuiSwitch-root .Mui-checked": {
                                                color: "green"
                                            },
                                            ".css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track": {
                                                backgroundColor: '#44b700'
                                            },
                                            ".css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked.Mui-disabled": {
                                                color: '#ffffff'
                                            }
                                        }}
                                    />
                                }
                                label="มีใบอนุญาตรวบรวมเมล็ดพันธุ์ควบคุมเพื่อการค้า"
                                disabled={isDisabled}
                            />
                        </Grid>

                        <Grid item xs={3}>
                            <FormControlLabel
                                control={
                                    <Switch
                                        id={"is_have_license_seller"}
                                        checked={data.is_have_license_seller || false}
                                        onChange={(event) => {
                                            setRCCFormData({
                                                ...data,
                                                is_have_license_seller: event.target.checked,
                                                breed_license_no: event.target.checked ? data.breed_license_no : "", // Set to empty string if false
                                            });
                                        }}
                                        sx={{
                                            "&.MuiSwitch-root .Mui-checked": {
                                                color: "green"
                                            },
                                            ".css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track": {
                                                backgroundColor: '#44b700'
                                            },
                                            ".css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked.Mui-disabled": {
                                                color: '#ffffff'
                                            }
                                        }}
                                    />
                                }
                                label="มีใบอนุญาตขายเมล็ดพันธุ์ควบคุม (พ.พ.)"
                                disabled={isDisabled}
                            />
                        </Grid>
                        {
                            data.is_have_license_seller ?
                                <Grid item xs={3}>
                                    <TextField
                                        fullWidth
                                        id="breed_license_no"
                                        label="พันธุ์/ใบอนุญาตเลขที่"
                                        variant="outlined"
                                        value={data.breed_license_no || ''}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        onChange={event => setData(event)}
                                        disabled={isDisabled}
                                    />
                                </Grid>
                                : <Grid item xs={3}></Grid>
                        }
                        <Grid item xs={6}></Grid>
                        <Grid item xs={3}>
                            <FormControlLabel
                                control={
                                    <Switch
                                        id={"is_have_registration_community"}
                                        checked={data.is_have_registration_community || false}
                                        onChange={(event) => {
                                            setRCCFormData({
                                                ...data,
                                                is_have_registration_community: event.target.checked,
                                                registration_community_no: event.target.checked ? data.registration_community_no : "",
                                            });
                                        }}
                                        sx={{
                                            "&.MuiSwitch-root .Mui-checked": {
                                                color: "green"
                                            },
                                            ".css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track": {
                                                backgroundColor: '#44b700'
                                            },
                                            ".css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked.Mui-disabled": {
                                                color: '#ffffff'
                                            }
                                        }}
                                    />
                                }
                                label="จดทะเบียนวิสาหกิจชุมชน"
                                disabled={isDisabled}
                            />
                        </Grid>
                        {
                            data.is_have_registration_community ?
                                <Grid item xs={3}>
                                    <TextField
                                        fullWidth
                                        id="registration_community_no"
                                        label="ทะเบียนวิสาหกิจชุมชน"
                                        variant="outlined"
                                        value={data.registration_community_no || ''}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        onChange={event => setData(event)}
                                        disabled={isDisabled}
                                    />
                                </Grid>
                                : <Grid item xs={3}></Grid>
                        }
                        <Grid item xs={6}></Grid>
                        <Grid item xs={3}>
                            <FormControlLabel
                                control={
                                    <Switch
                                        id={"is_have_registration_community_law"}
                                        checked={data.is_have_registration_community_law || false}
                                        onChange={(event) => {
                                            setRCCFormData({
                                                ...data,
                                                is_have_registration_community_law: event.target.checked,
                                                juristic_investor_name: event.target.checked ? data.juristic_investor_name : "",
                                                registration_date: event.target.checked ? data.registration_date : "",
                                                juristic_investor_registration: event.target.checked ? data.juristic_investor_registration : "",
                                            });
                                        }}
                                        sx={{
                                            "&.MuiSwitch-root .Mui-checked": {
                                                color: "green"
                                            },
                                            ".css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track": {
                                                backgroundColor: '#44b700'
                                            },
                                            ".css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked.Mui-disabled": {
                                                color: '#ffffff'
                                            }
                                        }}
                                    />
                                }
                                label={`จดทะเบียนวิสาหกิจชุมชนประเภทนิติบุคคลตามประมวลกฏหมายแพ่งและพาณิชย์`}
                                disabled={isDisabled}
                            />
                        </Grid>
                        {
                            data.is_have_registration_community_law ?
                                <Grid item xs={3}>
                                    <TextField
                                        fullWidth
                                        id="juristic_investor_name"
                                        label="ชื่อนิติบุคคล"
                                        variant="outlined"
                                        value={data.juristic_investor_name || ''}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        onChange={event => setData(event)}
                                        disabled={isDisabled}
                                    />
                                </Grid>
                                : <Grid item xs={3}></Grid>
                        }
                        {
                            data.is_have_registration_community_law ?
                                <Grid item xs={3}>
                                    <TextField
                                        fullWidth
                                        type="date"
                                        id="registration_date"
                                        label="วัน/เดือน/ปี ที่จดทะเบียน"
                                        variant="outlined"
                                        value={data.registration_date || ''}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        inputProps={{
                                            max: new Date().toISOString().split('T')[0],
                                        }}
                                        onChange={event => setData(event)}
                                        disabled={isDisabled}
                                    />
                                </Grid>
                                : <Grid item xs={3}></Grid>
                        }
                        {
                            data.is_have_registration_community_law ?
                                <Grid item xs={3}>
                                    <TextField
                                        fullWidth
                                        id="juristic_investor_registration"
                                        label="ทะเบียนนิติบุคคล"
                                        variant="outlined"
                                        value={data.juristic_investor_registration || ''}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        onChange={event => setData(event)}
                                        disabled={isDisabled}
                                    />
                                </Grid>
                                : <Grid item xs={3}></Grid>
                        }
                        <Grid
                            container
                            spacing={2}
                            direction="row"
                            justifyContent="flex-end"
                            alignItems="center"
                            sx={{
                                mt: 2
                            }}
                            display={isDisabled && 'none'}
                        >
                            <Grid item></Grid>
                            <Grid item display={(isDisabled || mode !== 'edit') && 'none'}>
                                <Button
                                    variant="contained"
                                    color="success"
                                    onClick={(event) => handleRCCContinue()}
                                >
                                    ต่ออายุ
                                </Button>
                            </Grid>
                            <Grid item display={(isDisabled || mode !== 'edit') && 'none'}>
                                <Button
                                    variant="contained"
                                    color="success"
                                    onClick={(event) => setIsQestionnairForm(true)}
                                >
                                    ทำแบบประเมิน
                                </Button>
                            </Grid>
                            <Grid item display={(isDisabled || mode !== 'edit') && 'none'}>
                                <Button
                                    variant="contained"
                                    color="success"
                                    onClick={(event) => setIsQestionnairHistory(true)}
                                >
                                    เรียกดูแบบประเมิน
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    variant="contained"
                                    color="success"
                                    disabled={isRenew}
                                    onClick={handleSaveButton}
                                >
                                    บันทึกข้อมูล
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>

            {
                mode === 'edit' ?
                    <RCCQuestionnairForm
                        is_open={is_questionair_form_open}
                        setIsOpen={setIsQestionnairForm}
                        rcc_id={rcc_id}
                    /> : null
            }
            {
                mode === 'edit' ?
                    <RCCQuestionnairHistory
                        is_open={is_questionair_history_open}
                        setIsOpen={setIsQestionnairHistory}
                        rcc_id={rcc_id}
                    /> : null
            }
        </>

    )
}