/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react'
import AdminLayout from '../../components/admin/AdminLayout'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import RccRenewList from '../../components/list/rcc-renew'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'

import {
    callPostService, getRenewList
} from '../../services/Service'
import LocalStorage from '../../utils/LocalStorage'
import jwt_decode from "jwt-decode";
import { getProvinceByCode } from '../../services/personal-and-farmer'
import Loading from '../../components/loading'

export default function RccListPage() {
    const [rcc_list, setRCCList] = useState([])
    const [rcc_list_count, setRCCListCount] = useState(0)
    const [rcc_list_page_count, setRCCListPageCount] = useState(0)
    const [search_rcc, setSearchRCC] = useState("")
    const [rcc_payload, setRCCPayload] = useState({
        'search': "",
        'pagination': {
            'page': 1,
            'page_size': 50
        }
    })

    const [rcc_max_lasted_page_update, setRCCMaxLastedPageUpdate] = useState(0)
    const [isBusy, setBusy] = useState(true)

    const handlePageChange = (new_page) => {
        // console.log(new_page)
        // console.log(rcc_payload);
        if (new_page > rcc_max_lasted_page_update) {
            setRCCMaxLastedPageUpdate(new_page)
            let temp_payload = rcc_payload
            temp_payload.pagination.page = new_page + 1
            callPostService(
                "/new/rcc/list",
                temp_payload
            ).then(res => {
                let temp_list = [];
                let index = 0;
                for (let data of rcc_list) {
                    index++;
                    temp_list.push({
                        index: index,
                        id: data.id,
                        rcc_code: data.rcc_code,
                        rcc_name: data.rcc_name,
                        rcc_status: data.rcc_status,
                        rcc_continue_date: data.rcc_continue_date,
                        is_active: data.is_active,
                        province_name: data.province_name,
                        amphur_name: data.amphur_name,
                        tambon_name: data.tambon_name,
                        president_name: data.president_name
                    })
                }
                let res_data = res.data.data
                for (let row_data of res_data) {
                    console.log('row_data', row_data)
                    index++;
                    let status = "ดำเนินการอยู่"
                    if (row_data.attributes.id_center === "") {
                        status = "รอการอนุมัติ"
                    }
                    temp_list.push({
                        index: index,
                        id: row_data.id,
                        rcc_code: row_data.attributes.id_center,
                        rcc_name: row_data.attributes.name_center,
                        rcc_status: status,
                        rcc_continue_date: row_data.attributes.createdate,
                        is_active: row_data.attributes.sts,
                        province_name: row_data.attributes.province_name,
                        amphur_name: row_data.attributes.amphur_name,
                        tambon_name: row_data.attributes.tambon_name,
                        president_name: row_data.attributes.name + " " + row_data.attributes.surname
                    })
                }
                setRCCList(temp_list)
            })
        }
    }

    // const rccListPrep = async (keyword) => {
    //     try {
    //         const access_token = LocalStorage.getAccessToken();
    //         const decoded_token = jwt_decode(access_token);
    //         const user_id = decoded_token?.user_id;
    //         const temp_payload = keyword;

    //         const res = await callPostService(`/new/rcc/list/${user_id}`, keyword);
    //         const res_data = res.data.data;

    //         setRCCListCount(res.data.meta.pagination.total);
    //         setRCCListPageCount(res.data.meta.pagination.pageSize);

    //         const temp_list = await Promise.all(res_data.map(async (row_data, index) => {
    //             let status = "ดำเนินการอยู่";
    //             if (row_data.attributes.id_center === "") {
    //                 status = "รอการอนุมัติ";
    //             }

    //             const provinceInfo = await fetchProvinceInfo(row_data.attributes);

    //             return {
    //                 index: index + 1,
    //                 id: row_data.id,
    //                 rcc_code: row_data.attributes.id_center,
    //                 rcc_name: row_data.attributes.name_center,
    //                 rcc_status: status,
    //                 rcc_continue_date: row_data.attributes.createdate,
    //                 is_active: row_data.attributes.sts,
    //                 province_name: provinceInfo.province_name,
    //                 amphur_name: provinceInfo.amphur_name,
    //                 tambon_name: provinceInfo.tambon_name,
    //                 president_name: `${row_data.attributes.name} ${row_data.attributes.surname}`
    //             };
    //         }));

    //         console.log('temp_list', temp_list);
    //         setRCCList(temp_list);
    //         setBusy(false)
    //     } catch (error) {
    //         console.error("Error:", error);
    //     }
    // };

    const rccListPrep = async (keyword) => {
        try {
            const access_token = LocalStorage.getAccessToken();
            const decoded_token = jwt_decode(access_token);
            const user_id = decoded_token?.user_id;
            const temp_payload = keyword;
            const res = await getRenewList(`/new/rcc/renew/list/${user_id}`, temp_payload);
            const res_data = res.data.data;
            console.log('data', res_data)

            setRCCListCount(res.data.meta.pagination.total);
            setRCCListPageCount(res.data.meta.pagination.pageSize);

            const temp_list = await Promise.all(res_data.map(async (row_data, index) => {
                let status = "ดำเนินการอยู่";
                if (row_data.attributes.id_center === "") {
                    status = "รอการอนุมัติ";
                }

                const provinceInfo = await fetchProvinceInfo(row_data.attributes);

                return {
                    index: index + 1,
                    id: row_data.id,
                    rcc_code: row_data.attributes.id_center,
                    rcc_name: row_data.attributes.name_center,
                    rcc_status: status,
                    rcc_continue_date: row_data.attributes.createdate,
                    is_active: row_data.attributes.sts,
                    province_name: provinceInfo.province_name,
                    amphur_name: provinceInfo.amphur_name,
                    tambon_name: provinceInfo.tambon_name,
                    president_name: `${row_data.attributes.name} ${row_data.attributes.surname}`
                };
            }));

            console.log('temp_list', temp_list);
            setRCCList(temp_list);

            setBusy(false)
        } catch (error) {
            console.error("Error:", error);
        }
    }


    const fetchProvinceInfo = async (attributes) => {
        const data = {
            province_code: attributes.province_id,
            amphur_code: attributes.amphur_id,
            tambon_code: attributes.tambon_id,
        };

        try {
            const res = await getProvinceByCode(data);
            return res.data
        } catch (error) {
            console.error("Error fetching province:", error);
            return "";
        }
    };

    const handlePreviewClick = (event, cellValues) => {
        // console.log(cellValues);
        window.location.href = "/rcc/preview/" + cellValues.row.id
    }

    useEffect(() => {
        let temp_payload = rcc_payload
        rccListPrep(temp_payload)
    }, []);

    if (isBusy) return <Loading />

    return (
        <div style={{ margin: '2rem' }}>
            <AdminLayout />
            <Grid
                container
                className='content-box'
                spacing={1}
                sx={{ mt: 5 }}
            >
                <Grid item xs>
                    <Typography variant='h4'>รายการแจ้งเตือนต่ออายุศูนย์ข้าวชุมชน</Typography>
                </Grid>
                <Grid item xs={12} >
                    <RccRenewList
                        rows={rcc_list}
                        row_count={rcc_list_count}
                        page_count={rcc_list_page_count}
                        handlePageChange={handlePageChange}
                        handlePreviewClick={handlePreviewClick}
                    />
                </Grid>
            </Grid>
        </div>
    )
}