import React, { useState, useEffect } from 'react';
import AdminLayout from '../../components/admin/AdminLayout'
import RCCForm from '../../components/RCCForm';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

export default function RCCAdd() {
    return (
        <div style={{ margin: '2rem' }}>
            <AdminLayout />
            <Grid container className='content-box'>
                <Grid item xs={12}>
                    <Typography variant='h4'>เพิ่มศูนย์ข้าวชุมชน</Typography>
                </Grid>
                <Grid item xs={12} >
                    <RCCForm 
                        mode="add"
                    />
                </Grid>
            </Grid>
        </div>
    )
}