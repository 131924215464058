import React, { useState, useEffect } from 'react'
import AdminLayout from '../components/admin/AdminLayout'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import RccCommitteeList from '../components/list/rcc-committe'
import RegionForm from '../components/form/region-form'

import {
    getProvince,
    getAmphurs,
    getTambons,
    getMemberPosition,
} from '../services/Dropdown'

import {
    getCommittee,
    addRCCCommittee
} from '../services/rcc-committee'

import {
    getFarmerByCitizenId,
    getFarmerByUID
} from '../services/personal-and-farmer'

import {
    getMemberByID
} from '../services/rcc-member'

import {
    findValueInDict
} from '../utils/utils'

import RccCommitteeForm from '../components/form/rcc-committee'
import RccCommitteeListForm from '../components/form/rcc-committee-list'

export default function RccCommitteePage() {
    const [rcc_committee_list, setRCCCommitteeList] = useState([])
    const [rcc_committee_list_count, setRCCCommitteeListCount] = useState(100)
    const [rcc_committee_list_page_count, setRCCCommitteeListPageCount] = useState(0)

    const [selected_region_label, setSelectedRegionLabel] = useState("")

    const [province_dropdown, setProvinceDropdown] = useState([])
    const [selected_province, setSelectedProvince] = useState(0)

    const [amphur_dropdown, setAmphurList] = useState([])
    const [selected_amphur, setSelectedAmphur] = useState(0)
    const [amphur_init, setAmphurInit] = useState("กรุณาเลือกจังหวัด")

    const [tambon_dropdown, setTambonList] = useState([])
    const [selected_tambon, setSelectedTambon] = useState(0)
    const [tambon_init, setTambonInit] = useState("กรุณาเลือกจังหวัด")

    const [is_add_committee_open, setIsAddCommitteeOpen] = useState(false)
    const [position_dropdown, setPositionDropdown] = useState([])
    const [selected_position, setSelectedPosition] = useState(0)
    const [farmer_search_keyword, setFarmerSearchKeyword] = useState("")
    const [farmer_search_result, setFarmerSearchResult] = useState("")
    const [farmer_search_result_rcc_name, setFarmerSearchResultRCCName] = useState("")
    const [farmer_search_result_rcc_id, setFarmerSearchResultRCCID] = useState("")
    const [farmer_search_result_uid, setFarmerSearchResultUID] = useState("")

    const [is_add_committee_list_open, setIsAddCommitteeListOpen] = useState(false)
    const [farmer_dropdown, setFarmerDropdown] = useState([])
    const [selected_farmer, setSelectedFarmer] = useState(0)

    const handleProvinceChange = (value) => {
        setSelectedProvince(value)
        if (value === 0) {
            setAmphurInit("กรุณาเลือกจังหวัด");
            setTambonInit("กรุณาเลือกจังหวัด");
            setSelectedAmphur(0);
            setSelectedTambon(0)
        } else {
            setAmphurInit("กรุณาเลือกอำเภอ");
            setTambonInit("กรุณาเลือกอำเภอ");
            setSelectedTambon(0)
            getAmphurs(value).then(res => {
                let temp_list = []
                let res_data = res.data.data
                for (let row_data of res_data) {
                    temp_list.push({
                        id: row_data.id,
                        name: row_data.attributes.amphur_name,
                        code: row_data.attributes.amphur_code,
                    })
                }
                setAmphurList(temp_list)
            })
        }
    }

    const handleAmphurChange = (value) => {
        setSelectedAmphur(value)
        if (value === 0) {
            setAmphurInit("กรุณาเลือกอำเภอ")
            setTambonInit("กรุณาเลือกอำเภอ")
            setSelectedTambon(0)
        } else {
            setTambonInit("กรุณาเลือกตำบล")
            getTambons(value).then(res => {
                let temp_list = []
                let res_data = res.data.data
                for (let row_data of res_data) {
                    temp_list.push({
                        id: row_data.id,
                        name: row_data.attributes.tambon_name,
                        code: row_data.attributes.tambon_code,
                    })
                }
                setTambonList(temp_list)
            })
        }
    }

    const handleTambonChange = (value) => {
        setSelectedTambon(value)
    }

    const handleSearchClick = () => {
        if (selected_province === 0) {
            alert("คุณยังไม่ได้ทำการเลือกจังหวัด \n กรุณาเลือกจังหวัด")
        } else if (selected_amphur === 0) {
            alert("คุณยังไม่ได้ทำการเลือกอำเภอ \n กรุณาเลือกอำเภอ")
        } else if (selected_tambon === 0) {
            alert("คุณยังไม่ได้ทำการเลือกตำบล \n กรุณาเลือกตำบล")
        } else {
            let selected_province_details = findValueInDict(province_dropdown, selected_province)
            let selected_amphur_details = findValueInDict(amphur_dropdown, selected_amphur)
            let selected_tambon_details = findValueInDict(tambon_dropdown, selected_tambon)
            // console.log(selected_tambon_details);
            let region_lable = "ตำบล " + selected_tambon_details.name + " " + selected_amphur_details.name + " จังหวัด " + selected_province_details.name

            let filter = "&filters[province_id][$eq]=" + selected_province_details.code
                + "&filters[amphur_id][$eq]=" + selected_amphur_details.code.split(selected_province_details.code)[1]
                + "&filters[tambon_id][$eq]=" + selected_tambon_details.code.split(selected_amphur_details.code)[1]
            // if (selected_tambon !== 0) {
            //     selected_tambon_details = findValueInDict(tambon_dropdown, selected_tambon)
            //     console.log(selected_tambon_details);
            //     region_lable += " ตำบล " + selected_tambon_details.name
            //     filter += "&filters[tambon_id][$eq]=" + selected_tambon_details.code.split(selected_amphur_details.code)[1]
            // }
            // console.log(filter);
            getCommittee(
                filter
            ).then(res => {
                setRCCCommitteeListCount(res.data.meta.pagination.total)
                setRCCCommitteeListPageCount(res.data.meta.pagination.pageSize)
                region_lable += " จำนวนทั้งหมด " + res.data.meta.pagination.total + " คน"
                setSelectedRegionLabel(region_lable)
                let res_data = res.data.data
                let temp_list = []
                let index = 0
                console.log(res_data);
                for (let row_data of res_data) {
                    index += 1
                    temp_list.push({
                        index: index,
                        id: row_data.id,
                        position: row_data.attributes.rice_community_position.data.attributes.community_position_name,
                        name: row_data.attributes.farmer[0].personal_name + " " + row_data.attributes.farmer[0].personal_surname,
                    })
                }
                setRCCCommitteeList(temp_list)
            })
        }
    }

    const handleAddClick = () => {
        if (selected_province === 0) {
            alert("คุณยังไม่ได้ทำการเลือกจังหวัด \n กรุณาเลือกจังหวัด")
        } else if (selected_amphur === 0) {
            alert("คุณยังไม่ได้ทำการเลือกอำเภอ \n กรุณาเลือกอำเภอ")
        } else if (selected_region_label === "") {
            alert("คุณยังไม่ได้ทำการค้นหา \n กรุณากดปุ่มค้นหา")
        } else {
            if (selected_tambon === 0) {
                let temp_list = []
                let res_data = position_dropdown
                for (let row_data of res_data) {
                    if (row_data.name !== "ชาวนาอาสา")
                        temp_list.push({
                            id: row_data.id,
                            name: row_data.name,
                        })
                }
                setPositionDropdown(temp_list)
            } else {
                getPosition()
            }
            setIsAddCommitteeOpen(true)
        }
    }

    const handleAddByListClick = () => {
        if (selected_province === 0) {
            alert("คุณยังไม่ได้ทำการเลือกจังหวัด \n กรุณาเลือกจังหวัด")
        } else if (selected_amphur === 0) {
            alert("คุณยังไม่ได้ทำการเลือกอำเภอ \n กรุณาเลือกอำเภอ")
        } else if (selected_region_label === "") {
            alert("คุณยังไม่ได้ทำการค้นหา \n กรุณากดปุ่มค้นหา")
        } else {
            if (selected_tambon === 0) {
                let temp_list = []
                let res_data = position_dropdown
                for (let row_data of res_data) {
                    if (row_data.name !== "ชาวนาอาสา")
                        temp_list.push({
                            id: row_data.id,
                            name: row_data.name,
                        })
                }
                setPositionDropdown(temp_list)
            } else {
                getPosition()
            }
            let selected_province_details = findValueInDict(province_dropdown, selected_province)
            let selected_amphur_details = findValueInDict(amphur_dropdown, selected_amphur)
            let selected_tambon_details = {
                id: 0,
                name: "none",
                code: ""
            }
            let filter = "&pagination[pageSize]=100&filters[rcc][province_id][$eq]=" + parseInt(selected_province_details.code)
                + "&filters[rcc][amphur_id][$eq]=" + parseInt(selected_amphur_details.code.split(selected_province_details.code)[1])
            // console.log(selected_tambon_details);
            if (selected_tambon !== 0) {
                selected_tambon_details = findValueInDict(tambon_dropdown, selected_tambon)
                filter += "&filters[rcc][tambon_id][$eq]=" + parseInt(selected_tambon_details.code.split(selected_amphur_details.code)[1])
            }
            // console.log(filter);
            getMemberByID(filter, "")
                .then(res => {
                    // console.log(res);
                    if (res.data.length === 0) {
                        alert("ไม่พบเกษตรตามเงื่อนไขที่ท่านเลือก \n กรุณาค้นหาอีกครั้ง")
                        setIsAddCommitteeListOpen(false)
                    } else {
                        console.log(res.data);
                        let temp_list = []
                        for (let row_data of res.data) {
                            // console.log(row_data);
                            temp_list.push({
                                id: row_data.id,
                                label: row_data.attributes.farmer[0].personal_name + " " + row_data.attributes.farmer[0].personal_surname,
                                uid: row_data.attributes.personal_uid,
                                rcc_name: row_data.attributes.rcc.data.attributes.name_center,
                                rcc_id: row_data.attributes.rcc.id
                            })
                        }
                        setFarmerDropdown(temp_list)
                        setIsAddCommitteeListOpen(true)
                    }
                })
                .catch(err => {
                    console.log(err);
                })
        }
    }

    const handlePageSizeChange = () => {

    }

    const handlePageChange = () => {

    }

    const handlePositionChange = (value) => {
        setSelectedPosition(value)
    }

    const handleFarmerSearch = () => {
        getFarmerByCitizenId(farmer_search_keyword)
        .then(res => {
            // console.log(res.data);
            if (res.data.meta.pagination.total === 0) {
                alert("ไม่พบข้อมูลเกษตกร\nกรุณาค้นหาอีกครั้ง")
            } else {
                let farmer_data = res.data.data
                let selected_province_details = findValueInDict(province_dropdown, selected_province)
                let selected_amphur_details = findValueInDict(amphur_dropdown, selected_amphur)
                let selected_tambon_details = {
                    id: 0,
                    name: "none",
                    code: ""
                }
                let filter = "&filters[rcc][province_id][$eq]=" + parseInt(selected_province_details.code)
                    + "&filters[rcc][amphur_id][$eq]=" + parseInt(selected_amphur_details.code.split(selected_province_details.code)[1])
                // console.log(selected_tambon_details);
                if (selected_tambon !== 0) {
                    selected_tambon_details = findValueInDict(tambon_dropdown, selected_tambon)
                    filter += "&filters[rcc][tambon_id][$eq]=" + parseInt(selected_tambon_details.code.split(selected_amphur_details.code)[1])
                }
                filter += "&filters[personal_uid][$eq]=" + farmer_data[0].attributes.farmer_uid
                // console.log(filter);
                getMemberByID(filter)
                    .then(res => {
                        if (res.data.meta.pagination.total === 0) {
                            alert("เกษตรกรท่านนี้ไม่ได้อยู่ในกลุ่มศูนย์ข้าวชุมชนที่ท่านเลือก \n กรุณาค้นหาอีกครั้ง")
                        } else {
                            let rcc_member_data = res.data.data
                            setFarmerSearchResult(farmer_data[0].attributes.personal.data.attributes.personal_name + " " + farmer_data[0].attributes.personal.data.attributes.personal_surname)
                            setFarmerSearchResultRCCName(rcc_member_data[0].attributes.rcc.data.attributes.name_center)
                            setFarmerSearchResultUID(farmer_data[0].attributes.farmer_uid)
                            setFarmerSearchResultRCCID(rcc_member_data[0].attributes.rcc.data.id)
                        }
                    })
                    .catch(err => {
                        console.log(err);
                    })
            }
        })
        .catch(err => {
            console.log(err);
        })
    }

    const handleCloseCommitteeForm = () => {
        setIsAddCommitteeOpen(false)
        setSelectedPosition(0)
        setFarmerSearchKeyword("")
        setFarmerSearchResult("")
        setFarmerSearchResultRCCName("")
        setFarmerSearchResultUID("")
        setFarmerSearchResultRCCID(0)
    }

    const getPosition = () => {
        getMemberPosition().then(res => {
            let temp_list = []
            let res_data = res.data.data
            for (let row_data of res_data) {
                temp_list.push({
                    id: row_data.id,
                    name: row_data.attributes.community_position_name,
                })
            }
            setPositionDropdown(temp_list)
        })
    }

    const handleSaveCommitteeButton = () => {
        if (selected_position === 0) {
            alert("คุณยังไม่ได้เลือกตำแหน่ง \n กรุณาเลือกตำแหน่งอีกครั้ง")
        } else if (farmer_search_result_uid === "") {
            alert("คุณยังไม่ได้ค้นหาเกษตรกร \n กรุณาค้นหาอีกครั้ง")
        } else {
            let selected_province_details = findValueInDict(province_dropdown, selected_province)
            let selected_amphur_details = findValueInDict(amphur_dropdown, selected_amphur)
            let selected_tambon_details = {
                id: 0,
                name: "none",
                code: "0"
            }
            let tambon_code = ""
            if (selected_tambon !== 0) {
                selected_tambon_details = findValueInDict(tambon_dropdown, selected_tambon)
                tambon_code = selected_tambon_details.code.split(selected_amphur_details.code)[1]
            }
            let data = {
                data: {
                    province_id: selected_province_details.code,
                    province_name: selected_province_details.name,
                    amphur_id: selected_amphur_details.code.split(selected_province_details.code)[1],
                    amphur_name: selected_amphur_details.name,
                    tambon_id: tambon_code,
                    tambon_name: selected_tambon_details.name,
                    farmer_uid: farmer_search_result_uid,
                    rcc: farmer_search_result_rcc_id,
                    rice_community_position: selected_position,
                }
            }
            console.log(data);
            addRCCCommittee(data)
                .then(res => {
                    alert("เพิ่มกรรมการสำเร็จ")
                    handleCloseCommitteeForm()
                    handleSearchClick()
                })
        }
    }

    const handleSaveCommitteeListButton = () => {
        if (selected_position === 0) {
            alert("คุณยังไม่ได้เลือกตำแหน่ง \n กรุณาเลือกตำแหน่งอีกครั้ง")
        } else if (farmer_search_result_uid === "") {
            alert("คุณยังไม่ได้เลิือกเกษตรกร \n กรุณาเลือกอีกครั้ง")
        } else {
            let selected_province_details = findValueInDict(province_dropdown, selected_province)
            let selected_amphur_details = findValueInDict(amphur_dropdown, selected_amphur)
            let selected_tambon_details = {
                id: 0,
                name: "none",
                code: "0"
            }
            let tambon_code = ""
            if (selected_tambon !== 0) {
                selected_tambon_details = findValueInDict(tambon_dropdown, selected_tambon)
                tambon_code = selected_tambon_details.code.split(selected_amphur_details.code)[1]
            }
            let data = {
                data: {
                    province_id: selected_province_details.code,
                    province_name: selected_province_details.name,
                    amphur_id: selected_amphur_details.code.split(selected_province_details.code)[1],
                    amphur_name: selected_amphur_details.name,
                    tambon_id: tambon_code,
                    tambon_name: selected_tambon_details.name,
                    farmer_uid: farmer_search_result_uid,
                    rcc: farmer_search_result_rcc_id,
                    rice_community_position: selected_position,
                }
            }
            console.log(data);
            addRCCCommittee(data)
                .then(res => {
                    alert("เพิ่มกรรมการสำเร็จ")
                    handleCloseCommitteeListForm()
                    handleSearchClick()
                })
        }
    }

    const handleCloseCommitteeListForm = () => {
        setIsAddCommitteeListOpen(false)
        setSelectedPosition(0)
        setFarmerSearchResultRCCName("")
        setFarmerSearchResultUID("")
        setFarmerSearchResultRCCID(0)
    }

    const handleChangeFarmer = (value) => {
        console.log(value);
        setFarmerSearchResultRCCName(value.rcc_name)
        setFarmerSearchResultRCCID(value.rcc_id)
        setFarmerSearchResultUID(value.uid)
    }

    const handleChangeAutocompleteFarmer = (value) => {
        console.log(value);
        let selected_province_details = findValueInDict(province_dropdown, selected_province)
        let selected_amphur_details = findValueInDict(amphur_dropdown, selected_amphur)
        let selected_tambon_details = {
            id: 0,
            name: "none",
            code: ""
        }
        let filter = "&pagination[pageSize]=100&filters[rcc][province_id][$eq]=" + parseInt(selected_province_details.code)
            + "&filters[rcc][amphur_id][$eq]=" + parseInt(selected_amphur_details.code.split(selected_province_details.code)[1])
        // console.log(selected_tambon_details);
        if (selected_tambon !== 0) {
            selected_tambon_details = findValueInDict(tambon_dropdown, selected_tambon)
            filter += "&filters[rcc][tambon_id][$eq]=" + parseInt(selected_tambon_details.code.split(selected_amphur_details.code)[1])
        }
        getMemberByID(filter, value)
            .then(res => {
                console.log(res)
            })
    }

    useEffect(() => {
        getProvince().then(res => {
            let temp_list = []
            let res_data = res.data.data
            for (let row_data of res_data) {
                temp_list.push({
                    id: row_data.id,
                    name: row_data.attributes.province_name,
                    code: row_data.attributes.province_code,
                })
            }
            setProvinceDropdown(temp_list)
        })
        getPosition()
    }, []);

    return (
        <div style={{ margin: '2rem' }}>
            <AdminLayout />
            <Card style={{ marginTop: '0rem', marginRight: '1%', marginLeft: '1%' }}>
                <CardContent>
                    <Grid
                        container
                        className='content-box'
                        spacing={1}
                        sx={{ mt: 5 }}
                    >
                        <Grid item xs>
                            <Typography variant='h4'>รายการคณะกรรมการศูนย์ข้าวชุมชนระดับตำบล</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <RegionForm
                                province_dropdown={province_dropdown}
                                selected_province={selected_province}
                                amphur_dropdown={amphur_dropdown}
                                selected_amphur={selected_amphur}
                                amphur_init={amphur_init}
                                tambon_dropdown={tambon_dropdown}
                                selected_tambon={selected_tambon}
                                tambon_init={tambon_init}
                                handleProvinceChange={handleProvinceChange}
                                handleAmphurChange={handleAmphurChange}
                                handleTambonChange={handleTambonChange}
                                handleSearchClick={handleSearchClick}
                            />
                        </Grid>
                        <Grid item xs>
                            <Typography variant='h6'>{selected_region_label}</Typography>
                        </Grid>
                        <Grid item xs={2.5}>
                            <Button
                                variant="contained"
                                onClick={handleAddClick}
                            >
                                เพิ่มกรรมการโดยบัตรประชาชน
                            </Button>
                        </Grid>
                        <Grid item xs={2}>
                            <Button
                                variant="contained"
                                onClick={handleAddByListClick}
                            >
                                เพิ่มกรรมการจากรายการ
                            </Button>
                        </Grid>
                        <Grid item xs={12}>
                            <RccCommitteeList
                                rows={rcc_committee_list}
                                row_count={rcc_committee_list_count}
                                page_count={rcc_committee_list_page_count}
                                handlePageSizeChange={handlePageSizeChange}
                                handlePageChange={handlePageChange}
                            />
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
            <RccCommitteeForm
                is_open={is_add_committee_open}
                postion_dropdown={position_dropdown}
                selected_position={selected_position}
                handlePositionChange={handlePositionChange}
                handleFarmerSearch={handleFarmerSearch}
                farmer_search_keyword={farmer_search_keyword}
                setFarmerSearchKeyword={setFarmerSearchKeyword}
                farmer_search_result={farmer_search_result}
                farmer_search_result_rcc={farmer_search_result_rcc_name}
                handleSaveCommitteeButton={handleSaveCommitteeButton}
                handleCloseCommitteeForm={handleCloseCommitteeForm}
            />
            <RccCommitteeListForm
                is_open={is_add_committee_list_open}
                postion_dropdown={position_dropdown}
                selected_position={selected_position}
                handlePositionChange={handlePositionChange}
                farmer_search_result_rcc={farmer_search_result_rcc_name}
                handleSaveCommitteeButton={handleSaveCommitteeListButton}
                handleCloseCommitteeForm={handleCloseCommitteeListForm}
                farmer_dropdown={farmer_dropdown}
                handleChangeFarmer={handleChangeFarmer}
                handleChangeAutocompleteFarmer={handleChangeAutocompleteFarmer}
            />
        </div>
    )
}