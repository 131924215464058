import React from 'react';
import { DataGrid, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import PreviewIcon from '@mui/icons-material/Preview';

export default function RccList({
    rows,
    row_count,
    page_count,
    handlePageSizeChange,
    handlePageChange,
    handlePreviewClick
}) {
    const columns = [
        {
            field: 'index',
            headerName: 'ลำดับ',
            maxWidth: 50,
            flex: 1
        },
        {
            field: 'rcc_code',
            headerName: 'รหัสศูนย์ข้าวชุมชน',
            minWidth: 150,
            flex: 1
        },
        {
            field: 'rcc_name',
            headerName: 'ชื่อศูนย์ข้าวชุมชน',
            minWidth: 250,
            flex: 1
        },
        {
            field: 'rcc_status',
            headerName: 'สถานะ',
            minWidth: 150,
            flex: 1
        },
        // {
        //     field: 'rcc_continue_date',
        //     headerName: 'วันที่ต่ออายุล่าสุด',
        //     minWidth: 150,
        //     flex: 1
        // },
        {
            field: 'province_name',
            headerName: 'จังหวัด',
            minWidth: 150,
            flex: 1
        },
        {
            field: 'amphur_name',
            headerName: 'อำเภอ',
            minWidth: 150,
            flex: 1
        },
        {
            field: 'tambon_name',
            headerName: 'ตำบล',
            minWidth: 150,
            flex: 1
        },
        {
            field: 'president_name',
            headerName: 'ประธาน',
            minWidth: 150,
            flex: 1
        },
        {
            field: "edit",
            headerName: "รายละเอียด",
            sortable: false,
            maxWidth: 100,
            flex: 1,
            disableClickEventBubbling: true,
            renderCell: (cellValues) => {
                return <IconButton
                    color="success"
                    aria-label="add an alarm"
                    onClick={(event) => {
                        handleEditClick(event, cellValues);
                    }} >
                    <EditIcon />
                </IconButton>
            }
        },
        {
            field: "preview",
            headerName: "เรียกดู",
            sortable: false,
            maxWidth: 100,
            flex: 1,
            disableClickEventBubbling: true,
            renderCell: (cellValues) => {
                return <IconButton
                    color="success"
                    aria-label="add an alarm"
                    onClick={(event) => {
                        handlePreviewClick(event, cellValues);
                    }} >
                    <PreviewIcon />
                </IconButton>
            }
        }
    ];

    const handleEditClick = (event, cellValues) => {
        console.log(cellValues.row.id);
        window.location.href = "/rcc/edit/" + cellValues.row.id
    }

    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarExport />
            </GridToolbarContainer>
        );
    }

    return (
        <div style={{ height: 600, width: '100%', marginTop: '2rem' }}>
            <DataGrid
                style={{ backgroundColor: "#FFFFFF" }}
                rows={rows}
                rowCount={row_count}
                columns={columns}
                pageSize={page_count}
                onPageSizeChange={(newPageSize) => handlePageSizeChange(newPageSize)}
                onPageChange={(newPage) => handlePageChange(newPage)}
                //   rowsPerPageOptions={[]}
                components={{
                    Toolbar: CustomToolbar,
                }}
            />
        </div>
    )
}