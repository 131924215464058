import React from 'react';
// import ReactDOM from 'react-dom/client'; 
import './index.scss';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from './pages/auth/login'
import CenterList from './pages/center/CenterList';
// import LocalList from './pages/local/LocalList';
import RCCAdd from './pages/rcc/RCCAdd';
// import RCCEdit from './pages/rcc/RCCEdit';
import CenterReport from './pages/center/CenterReport';
import Dashboard from './pages/Dashboard';
import RccListPage from './pages/new-rcc/rcc-list';
import RccAddPage from './pages/new-rcc/rcc-add';
import RccCommitteePage from './pages/rcc-committee';
import CommitteeTambonPage from './pages/committe/committee-tambon-list';
import CommitteeTambonDetailsPage from './pages/committe/committee-tambon-details';
import CommitteeAmphurPage from './pages/committe/committee-amphur-list';
import CommitteeAmphurDetailsPage from './pages/committe/committee-amphur-details';
import RccPreviewPage from './pages/new-rcc/rcc-preview';
import RccEditPage from './pages/new-rcc/rcc-edit';
import RccRenewPage from './pages/new-rcc/rcc-renew-list'

function App() { 
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/auth/login" element={<Login />} />
        <Route path="/center/list" element={<CenterList />} />
        <Route path="/center/report" element={<CenterReport />} />
        <Route path="/rcc/add/old" element={<RCCAdd />} />
        <Route path="/rcc/list" element={<RccListPage />} />
        <Route path="/rcc/add" element={<RccAddPage />} />
        <Route path="/rcc/committe" element={<RccCommitteePage />} />
        <Route path="/rcc/committe/tambon" element={<CommitteeTambonPage />} />
        <Route path="/rcc/committe/tambon/details" element={<CommitteeTambonDetailsPage />} />
        <Route path="/rcc/committe/amphur" element={<CommitteeAmphurPage />} />
        <Route path="/rcc/committe/amphur/details" element={<CommitteeAmphurDetailsPage />} />
        <Route path="/rcc/add2" element={<RCCAdd />} />
        {/* <Route path="/rcc/edit/:id" element={<RCCEdit />} /> */}
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/rcc/preview/:id" element={<RccPreviewPage />} />
        <Route path="/rcc/edit/:id" element={<RccEditPage />} />
        <Route path="/rcc/renew/list" element={<RccRenewPage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;