import React, { useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';

export default function CommitteeAmphurList({ 
    rows, 
    row_count, 
    page_size, 
    handleViewClick,
}) {
    const columns = [
        {
            field: 'index',
            headerName: 'ลำดับ',
            maxWidth: 50,
            flex: 1
        },
        {
            field: 'province_name',
            headerName: 'จังหวัด',
            minWidth: 150,
            flex: 1
        },
        {
            field: 'amphur_name',
            headerName: 'อำเภอ',
            minWidth: 250,
            flex: 1
        },
        {
            field: 'committe_num',
            headerName: 'จำนวนกรรมการ',
            minWidth: 250,
            flex: 1
        },
        {
            field: 'committe_amphur_num',
            headerName: 'จำนวนกรรมการระดับตำบล',
            minWidth: 250,
            flex: 1
        },
        {
            field: "view",
            headerName: "เรียกดู",
            sortable: false,
            maxWidth: 100,
            flex: 1,
            disableClickEventBubbling: true,
            
            renderCell: (cellValues) => {
                if (cellValues.row.is_can_add) {
                    return (
                        <IconButton
                            color="secondary"
                            aria-label="add an alarm"
                            onClick={(event) => {
                                handleViewClick(event, cellValues);
                            }} >
                            <SearchIcon />
                        </IconButton>
                    )
                }
            }
        }
    ];
    return (
        <div style={{ height: 600, width: '100%', marginTop: '2rem' }}>
            <DataGrid
              style={{ backgroundColor: "#FFFFFF" }}
              rows={rows}
              rowCount={row_count}
              columns={columns}
              pageSize={page_size}
            />
        </div>
    )
}