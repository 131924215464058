export const numberWithCommas = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const findValueInDict = (obj, id) => {
  for (let row of obj) {
    if (row.id === id) {
      return row
    }
  }
}

export const checkIsUndefindedAndEmpty = (data) => {
  if (data === undefined || data === "" || data === 0) {
    return true
  } else {
    return false
  }
}

export const formatIDCardNumber = (input) => {
  // Remove non-numeric characters
  const numericInput = input.replace(/\D/g, '');

  const maxLength = 13;
  const truncatedInput = numericInput.slice(0, maxLength);
  // Check if the input is not empty
  if (truncatedInput.length > 0) {
    let formattedInput = '';

    if (truncatedInput.length <= 2) {
      formattedInput = truncatedInput.replace(/^(\d{1})(\d{1})$/, '$1-$2');
    } else if (truncatedInput.length <= 5) {
      formattedInput = truncatedInput.replace(/^(\d{1})(\d{1,4})$/, '$1-$2');
    } else if (truncatedInput.length <= 10) {
      formattedInput = truncatedInput.replace(/^(\d{1})(\d{4})(\d{1,5})$/, '$1-$2-$3');
    } else if (truncatedInput.length <= 12) {
      formattedInput = truncatedInput.replace(/^(\d{1})(\d{4})(\d{5})(\d{1,2})$/, '$1-$2-$3-$4');
    } else {
      formattedInput = truncatedInput.replace(/^(\d{1})(\d{4})(\d{5})(\d{2})(\d{1})$/, '$1-$2-$3-$4-$5');
    }

    return formattedInput;
  }

  // Return an empty string if the input is empty
  return '';
};
export const findDataInDictWithKey = async (obj, key, value) => {
  for (let row of obj) {
    if (row[key] === value) {
      return row
    }
  }
}
