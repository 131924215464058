import axios from 'axios';
import config from '../config.json';
import LocalStorage from '../utils/LocalStorage';

export const saveRCC = async (data) => {
    let token = LocalStorage.getAccessToken();
    return axios.post(
        config.BASE_API + "/new/rcc",
        data,
        {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        }
    )
}

export const RCC = async (data) => {
    let token = LocalStorage.getAccessToken();
    return axios.post(
        config.BASE_API + "/new/rcc",
        data,
        {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        }
    )
}

export const putRCC = async (id, data) => {
    let token = LocalStorage.getAccessToken();
    return axios.put(
        config.BASE_API + "/new/rcc/" + id,
        data,
        {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        }
    )
}

export const getRCCList = async (data) => {
    let token = LocalStorage.getAccessToken();
    return axios.post(
        config.BASE_API + "/new/rcc/list",
        data,
        {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        }
    )
}

export const getRCCListCommitee = async (data) => {
    let token = LocalStorage.getAccessToken();
    return axios.post(
        config.BASE_API + "/new/rcc/list2",
        {
            filter: data
        },
        {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        }
    )
}

export const getRCCListDashboard = async (data) => {
    let token = LocalStorage.getAccessToken();
    return axios.post(
        config.BASE_API + "/new/rcc/list",
        {
            filter: data
        },
        {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        }
    )
}

export const savePlant = async (data) => {
    let token = LocalStorage.getAccessToken();
    return axios.post(
        config.BASE_API + "/plants/",
        data,
        {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        }
    )
}

export const getPlantById = async (farm_id) => {
    let token = LocalStorage.getAccessToken();
    return axios.get(
        config.BASE_API + "/plants/" + farm_id,
        {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        }
    )
}

export const delPlantById = async (plant_id) => {
    let token = LocalStorage.getAccessToken();
    return axios.delete(
        config.BASE_API + "/plants/delete/" + plant_id,
        {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        }
    )
}

export const updatePlantById = async (plant_id, data) => {
    let token = LocalStorage.getAccessToken();
    return axios.put(
        config.BASE_API + "/plants/update/" + plant_id, data,
        {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        }
    )
}

export const getEquipmentById = async (id) => {
    let token = LocalStorage.getAccessToken();
    return axios.get(
        config.BASE_API + "/rcc-equipment/" + id,
        {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        }
    )
}

export const postEquipment = async (body) => {
    let token = LocalStorage.getAccessToken();
    return axios.post(
        config.BASE_API + "/rcc-equipment/",
        body,
        {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        }
    )
}

export const putEquipmentById = async (id, body) => {
    let token = LocalStorage.getAccessToken();
    return axios.put(
        config.BASE_API + '/rcc-equipment/' + id,
        body,
        {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        }
    )
}

export const delEquipmentById = async (id) => {
    let token = LocalStorage.getAccessToken();
    return axios.delete(
        config.BASE_API + '/rcc-equipment/' + id,
        {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        }
    )
}

export const getRDUserLevel = async (user_id) => {
    let token = LocalStorage.getAccessToken();
    return axios.get(
        config.BASE_API + '/rd-user-levels/' + user_id,
        {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        }
    )
}