import React, { useState, useEffect } from 'react'
import {
    Card,
    CardContent,
    Grid,
    Typography,
} from '@mui/material'

import RegionForm from '../../components/form/region-form'
import AdminLayout from '../../components/admin/AdminLayout'

import {
    getProvince,
    getAmphurs,
    getTambons,
} from '../../services/Dropdown'

import {
    findValueInDict
} from '../../utils/utils'

import LocalStorage from '../../utils/LocalStorage'
import jwt_decode from "jwt-decode";

import CommitteeTambonList from '../../components/list/committe-tambon'
import { getCommittee } from '../../services/rcc-committee'
import { getMemberByID } from '../../services/rcc-member'
import UtilityBackdrop from '../../components/UtilityBackdrop'
import { getRCCList, getRCCListCommitee } from '../../services/rcc'

export default function CommitteeTambonPage() {
    const [is_loading, setIsLoading] = useState(false)

    const [province_dropdown, setProvinceDropdown] = useState([])
    const [selected_province, setSelectedProvince] = useState(0)
    const [amphur_dropdown, setAmphurList] = useState([])
    const [selected_amphur, setSelectedAmphur] = useState(0)
    const [amphur_init, setAmphurInit] = useState("กรุณาเลือกจังหวัด")

    const [committee_tambon_list, setCommitteeTambonList] = useState([])
    const [row_count, setRowCount] = useState(0)
    const [page_size, setPageSize] = useState(100)
    // const [tambob_list , setTambonList] = useState([])

    const handleProvinceChange = (value) =>{
        setSelectedProvince(value)
        if (value === 0) {
            setAmphurInit("กรุณาเลือกจังหวัด");
            setSelectedAmphur(0)
        } else {
            setAmphurInit("กรุณาเลือกอำเภอ");
            setSelectedAmphur(0)
            getAmphurs(value).then(res => {
                let temp_list = []
                let res_data = res.data.data
                for (let row_data of res_data) {
                    temp_list.push({
                        id: row_data.id,
                        name: row_data.attributes.amphur_name,
                        code: row_data.attributes.amphur_code,
                    })
                }
                setAmphurList(temp_list)
            })
        }
    }

    const handleAmphurChange = (value) => {
        setSelectedAmphur(value)
    }

    const handleSearchClick = async () => {
        if (selected_province === 0) {
            alert("คุณยังไม่ได้ทำการเลือกจังหวัด \n กรุณาเลือกจังหวัด")
        } else if (selected_amphur === 0) {
            alert("คุณยังไม่ได้ทำการเลือกอำเภอ \n กรุณาเลือกอำเภอ")
        } else {
            setIsLoading(true)
            setCommitteeTambonList([])
            let selected_province_details = findValueInDict(province_dropdown, selected_province)
            let selected_amphur_details = findValueInDict(amphur_dropdown, selected_amphur)
            let tambon = await getTambons(selected_amphur);
            let temp_list = []
            let index = 0
            let res_data = tambon.data.data
            for (let row_data of res_data) {
                // console.log(row_data);
                let filter = "&filters[province_id][$eq]=" + parseInt(selected_province_details.code)
                    + "&filters[amphur_id][$eq]=" + parseInt(selected_amphur_details.code.slice(-2))
                    + "&filters[tambon_id][$eq]=" + parseInt(row_data.attributes.tambon_code.slice(-2))
                console.log(filter);
                let rcc_list = await getRCCListCommitee({
                    filters:filter
                })
                console.log("rcc_list", rcc_list.data);
                let committee_list = await getCommittee(filter)
                let filter_rcc_member = "&filters[rcc][province_id][$eq]=" + selected_province_details.code
                + "&filters[rcc][amphur_id][$eq]=" + selected_amphur_details.code.slice(-2)
                + "&filters[rcc][tambon_id][$eq]=" + row_data.attributes.tambon_code.slice(-2)
                let member_list = await getMemberByID(filter_rcc_member)
                let is_can_add = false
                if( member_list.data.length > 0){
                    is_can_add = true 
                }
                // let is_can_add = true ? member_list.length > 0 : false
                // console.log(committee_list.data);
                index += 1
                temp_list.push({
                    id: row_data.id,
                    index: index,
                    province_code: selected_province_details.code,
                    province_name: selected_province_details.name,
                    amphur_code: selected_amphur_details.code,
                    amphur_name: selected_amphur_details.name,
                    tambon_code: row_data.attributes.tambon_code,
                    tambon_name: row_data.attributes.tambon_name,
                    committe_num: committee_list.data.meta.pagination.total,
                    rcc_member:member_list.data.length,
                    is_can_add: is_can_add,
                    rcc_number: rcc_list.data.meta.pagination.total,
                })
            }
            setCommitteeTambonList(temp_list)
            setIsLoading(false)
        }
    }   

    const handleViewClick = (event, value) => {
        if(value.row.is_can_add){
            LocalStorage.setSelectedCommitteeTambon(value.row)
            window.location.href = "/rcc/committe/tambon/details";
        }
        // LocalStorage.setSelectedCommitteeTambon(value.row)
        // window.location.href = "/rcc/committe/tambon/details";
    }

    useEffect(() => {
        LocalStorage.removeSelectedCommitteeTambon()
        
        let access_token = LocalStorage.getAccessToken();
        var decoded_token = jwt_decode(access_token);

    //   try {
        // getProvince(decoded_token?.user_id)
        getProvince(decoded_token?.user_id).then(res => {
            let temp_list = []
            let res_data = res.data.data
            for (let row_data of res_data) {
                temp_list.push({
                    id: row_data.id,
                    name: row_data.attributes.province_name,
                    code: row_data.attributes.province_code,
                })
            }
            setProvinceDropdown(temp_list)
        })
    }, [])

    return (
        <div style={{ margin: '2rem' }}>
            <AdminLayout />
            <Card style={{ marginTop: '0rem', marginRight: '1%', marginLeft: '1%' }}>
                <CardContent>
                    <Grid
                        container
                        className='content-box'
                        spacing={1}
                        sx={{ mt: 5 }}
                    >
                        <Grid item xs>
                            <Typography variant='h4'>รายการคณะกรรมการศูนย์ข้าวชุมชนระดับตำบล</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <RegionForm
                                mode={"committee_tambon"}
                                province_dropdown={province_dropdown}
                                selected_province={selected_province}
                                amphur_dropdown={amphur_dropdown}
                                selected_amphur={selected_amphur}
                                amphur_init={amphur_init}
                                handleProvinceChange={handleProvinceChange}
                                handleAmphurChange={handleAmphurChange}
                                handleSearchClick={handleSearchClick}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <CommitteeTambonList
                                rows={committee_tambon_list}
                                row_count={row_count}
                                page_size={page_size}
                                handleViewClick={handleViewClick}
                            />
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
            <UtilityBackdrop 
                open={is_loading}
            />
        </div>
    )
}