/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import AdminLayout from '../../components/admin/AdminLayout'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import RccForm from '../../components/form/rcc-form'
import { v4 as uuid } from 'uuid';
import jwt_decode from "jwt-decode";

import {
    getPrefix
} from '../../services/Dropdown'

import {
    // callGetService,
    callPostService,
    // callPutService
    callPaFPostService
} from '../../services/Service'

import {
    getMemberByUID,
    saveMember,
    delMemberById,
    putMemberById
} from '../../services/rcc-member'

import {
    perpareProvinceDropdown,
    handleProvince,
    handleAmphur,
    prepareEquipmentDropdown,
    prepareRCCMemberDropdown,
    prepareRCCPersonalProjectDropdown,
    findRowInDict,
    perparePrefixDropdown,
    prepareProjectRDDropdown,
} from '../../utils/Dropdown'

import {
    checkIsUndefindedAndEmpty,
    findDataInDictWithKey,
    findValueInDict,
    formatIDCardNumber
} from '../../utils/utils'

import {
    savePersonal,
    saveFarmer,
    updatePersonal,
    // getPersonalByUID,
    getFarmerByUID,
} from '../../services/personal-and-farmer'

import RccEquipmentSection from '../../components/section/rcc-equipment-section'
import RccEquipmentListForm from '../../components/form/rcc-equipment-form'
import RccMemberSection from '../../components/section/rcc-member-section'
import RCCMemberForm from '../../components/form/rcc-member-form'
import { checkMemberByCitizenID } from '../../utils/Farmer'
import {
    getPlantById,
    // delPlantById,
    updatePlantById,
    // saveRCC,
    putRCC,
    postEquipment,
    getEquipmentById,
    putEquipmentById,
    delEquipmentById,
    getRDUserLevel
} from '../../services/rcc'
import { callRicePostService } from '../../services/Service'
import { useLocation } from 'react-router-dom'
import LocalStorage from '../../utils/LocalStorage'

export default function RccAddPage() {
    const [rcc_form_data, setRCCFormData] = useState([])
    const [rcc_member_list, setRCCMemberList] = useState([])
    const [is_member_form_open, setIsMemberFormOpen] = useState(false)
    const [member_form_mode, setMemberFormMode] = useState("")
    const [member_form_data, setMemberFormData] = useState({
        citizen_id: "",
        member_farm_list: [],
        member_name: "",
        member_surname: "",
        member_addr: "",
        member_id: "",
        member_uid: "",
        member_farmer_id: "",
        farmer_id: "",
    })
    const [rcc_position_dropdown, setRCCPositionDropdown] = useState([])
    const [rcc_personal_project_dropdown, setRCCPersonalProjectDropDown] = useState([])
    const [rcc_prefix_dropdown, setRCCPrefixDropDown] = useState([])
    const [equipment_list, setEquipmentList] = useState([])
    const [is_equipment_form_open, setIsEquipmentFormOpen] = useState(false)
    const [equipment_form_mode, setEquipmentFormMode] = useState("")
    const [equipment_form_data, setEquipmentFormData] = useState([])
    const [equipment_dropdown, setEquipmentDropdown] = useState([])
    const [province_dropdown, setProvinceDropDown] = useState([])
    const [amphur_dropdown, setAmphurDropDown] = useState([])
    const [tambon_dropdown, setTambonDropDown] = useState([])
    const [amphur_init, setAmphurInit] = useState("กรุณาเลือกจังหวัด")
    const [tambon_init, setTambonInit] = useState("กรุณาเลือกจังหวัด")
    const [member_province_dropdown, setMemberProvinceDropDown] = useState([])
    const [member_amphur_dropdown, setMemberAmphurDropDown] = useState([])
    const [member_tambon_dropdown, setMemberTambonDropDown] = useState([])
    const [member_amphur_init, setMemberAmphurInit] = useState("กรุณาเลือกจังหวัด")
    const [member_tambon_init, setMemberTambonInit] = useState("กรุณาเลือกจังหวัด")
    const [province_list, setProvinceList] = useState()
    const [user_level, setUserLevel] = useState()
    // for delete rcc member
    const [delete_object, setDeleteObject] = useState([])
    // for delete equipment
    const [delete_equipment_object, setDeleteEquipmentObject] = useState([])
    const [expanded, setExpanded] = useState(false)
    const location = useLocation();
    const [isRenew, setRenew] = useState(false)

    const [project_rd_dropdown, setProjectRDDropdown] = useState([])
    const [selected_project_rd, setSelectedProjectRD] = useState([])

    let rcc_id = location.pathname.split('/')[3];

    // Extracting Rice Data
    // TODO
    const convertRiceId = {
        rice_type: {
            'ข้าวคุณภาพดี': 1,
            'เมล็ดพันธุ์ข้าว': 2
        },
        rice_seed_type: {
            'ข้าวหอมมะลิ': 3,
            'ข้าวเสาไห้': 4
        }
    }

    const handleDataChange = (event) => {
        let id = event.target.id
        let value = event.target.value
        if (id === "is_have_license_gather" || id === "is_have_license_seller" || id === "is_have_registration_community" || id === "is_have_registration_community_law") {
            value = !rcc_form_data[id]
        }
        setRCCFormData({
            ...rcc_form_data,
            [id]: value
        })
    }

    const handleProvinceChange = (item_id) => {
        setRCCFormData({
            ...rcc_form_data,
            "province_id": item_id,
            "amphur_id": 0,
            "tambon_id": 0,
            "zipcode": '',
        })
        handleProvince(
            item_id,
            setAmphurInit,
            setAmphurDropDown,
            setTambonInit,
            setTambonDropDown
        )
    }

    const handleAmphurChange = (item_id) => {
        setRCCFormData({
            ...rcc_form_data,
            "amphur_id": item_id,
            "tambon_id": 0,
            "zipcode": '',
        })
        handleAmphur(
            item_id,
            setTambonInit,
            setTambonDropDown
        )
    }

    const handleTambonChange = (item_id) => {
        let temp_number = item_id
        setRCCFormData({
            ...rcc_form_data,
            "tambon_id": item_id,
            "zipcode": tambon_dropdown[tambon_dropdown.findIndex(obj => obj.id === item_id)].zipcode,
            "temp_number": temp_number
        })
    }

    // member_form_data
    const handleMemberProvinceChange = (item_id) => {
        setMemberFormData({
            ...member_form_data,
            "member_province_id": item_id,
            "member_amphur_id": 0,
            "member_tambon_id": 0,
            "member_zipcode": '',
        })
        handleProvince(
            item_id,
            setMemberAmphurInit,
            setMemberAmphurDropDown,
            setMemberTambonInit,
            setMemberTambonDropDown
        )
    }

    const handleMemberAmphurChange = (item_id) => {
        setMemberFormData({
            ...member_form_data,
            "member_amphur_id": item_id,
            "member_tambon_id": 0,
            "member_zipcode": '',
        })
        handleAmphur(
            item_id,
            setMemberTambonInit,
            setMemberTambonDropDown
        )
    }

    const handleMemberTambonChange = (item_id) => {
        setMemberFormData({
            ...member_form_data,
            "member_tambon_id": item_id,
            "member_zipcode": member_tambon_dropdown[member_tambon_dropdown.findIndex(obj => obj.id === item_id)].zipcode
        })
    }

    const handleAddEquipment = () => {
        setIsEquipmentFormOpen(true)
        setEquipmentFormMode("add")
    }

    const handleCloseEquipmentForm = () => {
        setIsEquipmentFormOpen(false)
        setEquipmentFormData([])
    }

    const handleEquipmentDataChange = (event) => {
        let id = event.target.id
        let value = event.target.value

        setEquipmentFormData({
            ...equipment_form_data,
            [id]: value
        })
    }

    const handleSaveEquipmentForm = () => {
        if (equipment_form_data?.equipment_id === undefined) {
            alert("คุณยังไม่ได้เลือกอุปกรณ์ กรุณาเลือกอุปกรณ์")
            return
        } else if (equipment_form_data?.equipment_quantity === undefined) {
            alert("คุณยังไม่ได้เพิ่มจำนวนอุปกรณ์ กรุณาเพิ่มจำนวนอุปกรณ์")
            return
        }

        let index = 0;
        let temp_list = [];
        let equipment = findValueInDict(equipment_dropdown, equipment_form_data.equipment_id)

        for (let data of equipment_list) {
            index++;
            temp_list.push({
                index: index,
                id: index,
                equipment_id: data?.equipment_id,
                equipment_index: data?.equipment_index,
                equipment_name: data?.equipment_name,
                equipment_number: data?.equipment_number,
                equipment_registation_year: data?.equipment_registation_year,
                equipment_acquired_project: data?.equipment_acquired_project,
                is_new: data?.is_new,
                is_change: data?.is_change
            })
        }

        index++;
        if (equipment_form_mode === "add") {
            // console.log("add")

            temp_list.push({
                index: index,
                id: index,
                equipment_id: equipment_form_data?.equipment_id,
                equipment_name: equipment?.name,
                equipment_number: Number(equipment_form_data?.equipment_quantity),
                equipment_registation_year: equipment_form_data?.equipment_registation_year,
                equipment_acquired_project: equipment_form_data?.equipment_acquired_project,
                is_new: true
            })

            // console.log('temp_list', temp_list)
            setEquipmentList(temp_list)
        }
        if (equipment_form_mode === "edit") {
            // console.log('edit in form')

            temp_list.push({
                index: index,
                id: index,
                equipment_id: equipment_form_data?.equipment_id,
                equipment_index: equipment_form_data?.equipment_index,
                equipment_name: equipment?.name,
                equipment_number: Number(equipment_form_data?.equipment_quantity),
                equipment_registation_year: equipment_form_data?.equipment_registation_year,
                equipment_acquired_project: equipment_form_data?.equipment_acquired_project,
                is_new: equipment_form_data?.is_new,
                is_change: true
            })

            // Function to remove old index when id is same
            const removeOldIndex = (equipmentArray) => {
                // console.log('equipmentArray', equipmentArray)
                const unique = {};

                equipmentArray.forEach((equipment) => {
                    const { equipment_index } = equipment;
                    if (!unique[equipment_index] || unique[equipment_index].index < equipment.index) {
                        unique[equipment_index] = equipment;
                    }
                });

                // Reindex the array
                const result = Object.values(unique).map((equipment, index) => ({
                    ...equipment,
                    id: index + 1,
                    index: index + 1,
                }));

                return result;
            };

            const updatedTemp = removeOldIndex(temp_list);
            setEquipmentList(updatedTemp)
        }

        handleCloseEquipmentForm()
        setEquipmentFormData([])
    }

    const handleEditEquipmentList = (event, data) => {
        setEquipmentFormMode("edit")
        setEquipmentFormData({
            ...equipment_form_data,
            "equipment_id": data?.row?.equipment_id,
            "equipment_index": data?.row?.equipment_index,
            "equipment_name": data?.row?.name,
            "equipment_quantity": data?.row?.equipment_number,
            "equipment_registation_year": data?.row?.equipment_registation_year,
            "equipment_acquired_project": data?.row?.equipment_acquired_project,
            "is_new": data?.row?.is_new,

        })
        setIsEquipmentFormOpen(true)
    }

    const handleDeleteEquipmentList = (event, data) => {
        const is_new = data?.row?.is_new
        const id = data?.row?.equipment_index

        if (!is_new) {
            setDeleteEquipmentObject((prev) => [...prev, { id: id }])
        }

        let index = 0
        let temp_list = [];
        for (let list of equipment_list) {
            if (data?.row?.index !== list.index) {
                index++;
                temp_list.push({
                    ...list,
                    index: index,
                })
            }
        }

        setEquipmentList(temp_list)
    }

    const handleAddMember = () => {
        setMemberFormMode("add")
        setIsMemberFormOpen(true)
    }

    const handleCloseMemberForm = () => {
        let temp_position = rcc_position_dropdown.map((value) => {
            let max_count = value?.max_count;
            if (value?.id === member_form_data.position_id) max_count++;

            return {
                id: value?.id,
                name: value?.name,
                max_count: max_count
            };
        });

        setRCCPositionDropdown(temp_position)

        setIsMemberFormOpen(false)
        setMemberFormData({
            citizen_id: "",
            member_farm_list: [],
            member_name: "",
            member_surname: "",
            member_addr: "",
            member_id: "",
            member_uid: "",
            member_farmer_id: "",
            farmer_id: "",
        })
    }

    const findIdByName = (roleName) => (rcc_position_dropdown.find(role => role.name === roleName) || {}).id;

    const handleEditMemberList = async (event, data) => {
        if (data.row.rcc_name === 'ประธาน' && user_level !== 1) {
            alert('คุณไม่มีสิทธิ์ที่จะแก้ไขประธานศูนย์ข้าว')
            return
        } else {
            alert('คุณมีสิทธิ์ที่จะแก้ไขประธานศูนย์ข้าว')
        }

        setExpanded(true)

        const row = data.row
        const resultId = findIdByName(data.row.rcc_name);

        const temp_list = {
            ...member_form_data,
            ...row,
            id: row.id,
            position_id: resultId,
            member_uid: row.member_uid,
            member_id: row.member_id,
            member_prefix_id: row.member_prefix_id,
            member_name: row.member_name,
            member_surname: row.member_surname,
            member_birthday: row.member_birthday,
            member_phone: row.member_phone,
            member_addr: row.member_addr,
            member_moo: row.member_moo,
            member_alley: row.member_alley,
            member_lane: row.member_lane,
            member_road: row.member_road,
            member_province_id: row.member_province_id,
            member_amphur_id: row.member_amphur_id,
            member_tambon_id: row.member_tambon_id,
            member_zipcode: row.member_zipcode,
            member_farmer_uid: row.member_farmer_uid,
            member_farmer_id: row.member_farmer_id,
            member_farm_list: row.member_farm_list,
            amphurs: row.amphurs,
            tambons: row.tambons
        }

        setMemberFormData(temp_list)
        setMemberAmphurDropDown(temp_list.amphurs)
        setMemberTambonDropDown(temp_list.tambons)
        setMemberFormMode("edit")
        setIsMemberFormOpen(true)
    }

    const handleDeleteMemberList = async (event, data) => {
        console.log('user_level', user_level)

        console.log('data', data.row.rcc_name)
        if (data.row.rcc_name === 'ประธาน' && user_level !== 1) {
            alert('คุณไม่มีสิทธิ์ที่จะลบประธานศูนย์ข้าว')
            return
        } else {
            alert('คุณมีสิทธิ์ที่จะลบประธานศูนย์ข้าว')
        }

        var result = window.confirm("คุณต้องการที่จะลบข้อมูลนี้ใช่ไหม?");
        if (result) {
            // User clicked OK
            console.log("User clicked OK");
            let temp_position = rcc_position_dropdown.map((value) => {
                let max_count = value.max_count;
                if (value.name === data.row.rcc_name) max_count++;

                return {
                    id: value.id,
                    name: value.name,
                    max_count: max_count
                };
            })

            setRCCPositionDropdown(temp_position)

            const is_new = data?.row?.is_new || false
            const id = data?.row?.rcc_member_id
            const plants = data?.row?.farm_list

            if (!is_new) {
                setDeleteObject((prev) => [...prev, { id: id, plants: plants }])
            }

            let index = 0
            let temp_list = [];
            for (let list of rcc_member_list) {
                if (data?.row?.index !== list?.index) {
                    index++;
                    temp_list.push({
                        ...list,
                        index: index,
                    })
                }
            }

            setRCCMemberList(temp_list)
        } else {
            // User clicked Cancel
            console.log("User clicked Cancel");
        }

    }

    const handleFormDataChange = (event) => {
        let id = event.target.id
        let value = event.target.value

        if (id === "is_have_permise" || id === "is_have_certificate") {
            value = !rcc_form_data[id]
        }

        setMemberFormData({
            ...member_form_data,
            [id]: value
        })
    }

    const handlePositonChange = (new_value, old_value) => {
        let temp_position = rcc_position_dropdown.map((value) => {
            let max_count = value?.max_count;

            if (value?.id === old_value) max_count++;
            else if (value?.id === new_value) max_count--;

            return {
                id: value?.id,
                name: value?.name,
                max_count: max_count
            };
        });

        setRCCPositionDropdown(temp_position)
        setMemberFormData({
            ...member_form_data,
            "position_id": new_value
        })
    }

    const handlePrefixChange = (value) => {
        setMemberFormData({
            ...member_form_data,
            "member_prefix_id": value
        })
    }

    const handleProjectChange = (value) => {
        setMemberFormData({
            ...member_form_data,
            "personal_project": value
        })
    }

    const searchPlantPlan = async (farm_id) => {
        try {
            const res = await getPlantById(farm_id);

            const temp_data = {
                is_join: res?.data?.data[0]?.attributes?.is_join,
                plant_id: res?.data?.data[0]?.id,
                rice_type: res?.data.data[0]?.attributes?.rice_type_master_datum?.data?.attributes?.rice_type_name,
                rice_seed_type: res?.data.data[0]?.attributes?.rice_seed_type?.data?.attributes?.rice_seed_name
            };

            return temp_data;
        } catch (error) {
            console.error('Error in searchPlantPlan:', error);
            throw error;
        }
    };

    const perpareMemberForm = async (search_result) => {
        let temp_farm_list = [];
        let farm_list = search_result.farm_list.data
        let index = 0;

        for (let row_farm_data of farm_list) {
            let res = await searchPlantPlan(row_farm_data.id)

            temp_farm_list.push({
                id: row_farm_data.id,
                index: index += 1,
                farm_name: row_farm_data.attributes.farm_name,
                farm_rai: row_farm_data.attributes.farm_rai,
                farm_nga: row_farm_data.attributes.farm_nga,
                farm_wa: row_farm_data.attributes.farm_wa,
                farm_area: row_farm_data.attributes.farm_area,
                rice_type: res.rice_type || "",
                rice_seed_type: res.rice_seed_type || "",
                is_join: res.is_join,
                is_new: false,
                plant_id: res.plant_id,
                farm_id: row_farm_data.id,
            })
        }

        return {
            id: search_result.id,
            member_uid: search_result.personal_uid,
            member_id: search_result.personal_id,
            member_prefix_id: search_result.personal_prefix.data?.id,
            member_name: search_result.personal_name,
            member_surname: search_result.personal_surname,
            member_birthday: search_result.personal_birthday,
            member_phone: search_result.personal_phone,
            member_addr: search_result.personal_addr,
            member_moo: search_result.personal_moo,
            member_alley: search_result.personal_alley,
            member_lane: search_result.personal_lane,
            member_road: search_result.personal_road,
            member_province_id: search_result.province?.data?.id,
            member_amphur_id: search_result.amphur?.data?.id,
            member_tambon_id: search_result.tambon?.data?.id,
            member_zipcode: search_result.tambon?.data?.attributes?.postcode,

            member_farmer_uid: search_result.farmer_uid,
            member_farmer_id: search_result.farmer_id,
            member_farm_list: temp_farm_list,

            amphurs: search_result.amphurs,
            tambons: search_result.tambons
        }
    }

    const handleSearchCitizenID = async () => {
        if (member_form_data.citizen_id.length < 13) {
            alert('เลขบัตรประชาชนไม่ถูกต้อง')
            setMemberFormData({
                position_id: member_form_data.position_id,
                citizen_id: member_form_data.citizen_id,
                member_id: member_form_data.citizen_id,
                member_farm_list: [],
            })

            setExpanded(false)
            return
        }

        if (member_form_mode === "add") {
            const isDuplicated = rcc_member_list.some((value) => {
                return value.id === member_form_data.citizen_id;
            });

            if (isDuplicated) {
                alert('สมาชิกท่านนี้ได้มีการเพิ่มข้อมูลแล้ว')
                setExpanded(false)
                return
            }

            let search_result = await checkMemberByCitizenID(member_form_data.citizen_id, "", "add")

            if (search_result.error !== undefined) {
                console.log('member_form_data', member_form_data)

                setMemberFormData({
                    position_id: member_form_data.position_id,
                    citizen_id: member_form_data.citizen_id,
                    member_id: member_form_data.citizen_id,
                    member_farm_list: [],
                })

                alert(search_result.error)

                if (search_result.error === 'ไม่พบข้อมูลเกษตกร') {
                    setExpanded(true)
                } else {
                    setExpanded(false)
                }

            }
            const province_code = search_result?.province?.data?.attributes?.province_code

            if (user_level !== 1 || province_list.includes(province_code)) {
                alert('เกษตกรท่านนี้อยู่คนละจังหวัดของศูนย์ข้าวชุมชน')
                return
            } else {
                let prepared_member_form_data = await perpareMemberForm(search_result)
                alert(`พบข้อมูลเกษตกร ${rcc_prefix_dropdown[rcc_prefix_dropdown.findIndex(obj => obj.id === prepared_member_form_data?.member_prefix_id)]?.name || ''} ${prepared_member_form_data?.member_name} ${prepared_member_form_data?.member_surname}\nเลขบัตรประชาชน ${formatIDCardNumber(prepared_member_form_data?.member_id)}`)

                console.log('prepared_member_form_data', prepared_member_form_data)

                setMemberFormData({
                    ...member_form_data,
                    id: prepared_member_form_data?.id,
                    member_uid: prepared_member_form_data?.member_uid,
                    member_id: prepared_member_form_data?.member_id,
                    member_prefix_id: prepared_member_form_data?.member_prefix_id,
                    member_name: prepared_member_form_data?.member_name,
                    member_surname: prepared_member_form_data?.member_surname,
                    member_birthday: prepared_member_form_data?.member_birthday,
                    member_phone: prepared_member_form_data?.member_phone,
                    member_addr: prepared_member_form_data?.member_addr,
                    member_moo: prepared_member_form_data?.member_moo,
                    member_alley: prepared_member_form_data?.member_alley,
                    member_lane: prepared_member_form_data?.member_lane,
                    member_road: prepared_member_form_data?.member_road,
                    member_province_id: prepared_member_form_data?.member_province_id,
                    member_amphur_id: prepared_member_form_data?.member_amphur_id,
                    member_tambon_id: prepared_member_form_data?.member_tambon_id,
                    member_zipcode: prepared_member_form_data?.member_zipcode,

                    member_farmer_uid: prepared_member_form_data?.member_farmer_uid,
                    member_farmer_id: prepared_member_form_data?.member_farmer_id,
                    member_farm_list: prepared_member_form_data?.member_farm_list,

                    amphurs: prepared_member_form_data?.amphurs,
                    tambons: prepared_member_form_data?.tambons
                })

                setMemberAmphurDropDown([])
                setMemberTambonDropDown([])

                setExpanded(true)
            }

        } else if (member_form_mode === "edit") {
            alert("ไม่สามารถค้นหาได้เนื่องจากเป็นโหมดแก้ไข")
        }
    }

    const handleClickEditInFarmList = async (value) => {
        let temp_farm_list = []
        let index = 0;

        for (let row_farm_data of member_form_data.member_farm_list) {
            if (row_farm_data.id === value.id) {
                row_farm_data[value.field] = value.value;
                temp_farm_list.push({
                    ...row_farm_data,
                    index: index += 1,
                    is_change: true
                })
            } else {
                temp_farm_list.push({
                    ...row_farm_data,
                    index: index += 1,
                })
            }
        }

        temp_farm_list.sort((a, b) => b.is_join - a.is_join);

        // Reindex the array
        temp_farm_list.forEach((farm, newIndex) => {
            farm.index = newIndex + 1;
        });

        // console.log(temp_farm_list);
        setMemberFormData({
            ...member_form_data,
            "member_farm_list": temp_farm_list,
        })
    }

    const handleSaveMemberButton = async () => {
        let selected_farm = []
        let temp_list = [];
        let index = 0;
        let position_details = findRowInDict(rcc_position_dropdown, member_form_data.position_id)

        if (member_form_data.position_id === undefined || member_form_data.position_id === 0) {
            alert("คุณยังไม่ได้เลือกตำแหน่ง\nกรุณาเลือกตำแหน่ง")
            return
        } else {
            // check validate for find selected farm
            for (let member_farm of member_form_data.member_farm_list) {
                if (member_farm.is_join === true || (member_farm.plant_id !== undefined && member_farm.is_change)) {
                    if (!member_farm?.rice_seed_type) {
                        alert("คุณยังไม่ได้เลือกประเภทข้าวของแปลง " + member_farm.farm_name + "\nกรุณาเลือกประเภทข้าว")
                        return
                    } else if (!member_farm?.rice_type) {
                        alert("คุณยังไม่ได้เลือกพันธุ์ข้าวของแปลง " + member_farm.farm_name + "\nกรุณาเลือกพันธุ์ข้าว")
                        return
                    } else if (!member_farm?.rice_seed_type) {
                        selected_farm.push(member_farm)
                    }
                }
            }

            // Validation checks
            if (!member_form_data?.member_prefix_id) {
                alert("คุณยังไม่ได้ทำการกรอกคำนำหน้า\nกรุณากรอกข้อมูล")
                return
            } else if (!member_form_data?.member_name) {
                alert("คุณยังไม่ได้ทำการกรอกชื่อ กรุณากรอกข้อมูล")
                return
            } else if (!member_form_data?.member_surname) {
                alert("คุณยังไม่ได้ทำการกรอกนามสกุล กรุณากรอกข้อมูล")
                return
            } else if (!member_form_data?.member_id) {
                alert("คุณยังไม่ได้ทำการกรอกเลขบัตรประชาชน กรุณากรอกข้อมูล")
                return;
            } else if (member_form_data?.member_id.length < 13) {
                alert("เลขบัตรประชาชนไม่ถูกต้อง กรุณาตรวจสอบข้อมูลอีกครั้ง")
                return
            } else if (!member_form_data?.member_province_id) {
                alert("คุณยังไม่ได้ทำการกรอกจังหวัด\nกรุณากรอกข้อมูล")
                return
            } else if (!member_form_data?.member_amphur_id) {
                alert("คุณยังไม่ได้ทำการกรอกอำเภอ\nกรุณากรอกข้อมูล")
                return
            } else if (!member_form_data?.member_tambon_id) {
                alert("คุณยังไม่ได้ทำการกรอกตำบล\nกรุณากรอกข้อมูล")
                return
            }

            // Add new user at PAF
            if (member_form_data.member_uid === undefined) {
                try {
                    const personalData = {
                        personal_uid: uuid(),
                        personal_id: member_form_data.member_id,
                        personal_prefix: member_form_data.member_prefix_id,
                        personal_name: member_form_data.member_name,
                        personal_surname: member_form_data.member_surname,
                        personal_birthday: member_form_data.member_birthday,
                        personal_phone: member_form_data.member_phone,
                        personal_moo: member_form_data.member_moo,
                        personal_alley: member_form_data.member_alley,
                        personal_lane: member_form_data.member_lane,
                        personal_road: member_form_data.member_road,
                        tambon: member_form_data.member_tambon_id,
                        amphur: member_form_data.member_amphur_id,
                        province: member_form_data.member_province_id,
                        personal_addr: member_form_data.member_addr
                    }

                    console.log('personalData', personalData)

                    const personalResponse = await savePersonal(personalData);
                    console.log('Personal Save Response:', personalResponse);

                    const farmerData = {
                        data: {
                            farmer_uid: uuid(),
                            personal: parseInt(personalResponse.data.data.id)
                            // farms:''
                        }
                    }

                    console.log('farmerData', farmerData)

                    const farmerResponse = await saveFarmer(farmerData);
                    console.log('Farmer Save Response:', farmerResponse);

                    member_form_data.member_farmer_id = farmerResponse.data.data.id
                    member_form_data.member_farmer_uid = farmerResponse.data.data.attributes.farmer_uid
                    member_form_data.member_id = personalResponse.data.data.attributes.personal_id
                    member_form_data.member_uid = personalResponse.data.data.attributes.personal_uid

                } catch (error) {
                    console.error('Error:', error);
                    alert(error)
                }
            }

            // loop save rcc_member_list in temp_list
            for (let data of rcc_member_list) {
                index++
                temp_list.push({
                    ...data,
                    index: index,
                    is_new: data?.is_new,
                    is_change: data?.is_change
                })
            }

            if (member_form_mode === 'add') {
                console.log('add')
                index++;
                temp_list.push({
                    ...member_form_data,
                    id: index,
                    index: index,
                    farm_list: selected_farm,
                    name: member_form_data.member_name + " " + member_form_data.member_surname,
                    rcc_id: position_details.id,
                    rcc_name: position_details.name,
                    rcc_status: "สมาชิก",
                    is_new: true,
                    amphurs: member_amphur_dropdown,
                    tambons: member_tambon_dropdown
                    // project: personal_project,
                })

                setRCCMemberList(temp_list)
                handleCloseMemberForm()
            } else if (member_form_mode === 'edit') {
                // console.log('edit')

                index++;
                temp_list.push({
                    ...member_form_data,
                    index: index,
                    farm_list: member_form_data.member_farm_list,
                    name: member_form_data.member_name + " " + member_form_data.member_surname,
                    rcc_id: position_details.id,
                    rcc_name: position_details.name,
                    rcc_status: "สมาชิก",
                    is_change: true,
                    amphurs: member_amphur_dropdown,
                    tambons: member_tambon_dropdown
                })

                // Function to remove old index when id is same
                const removeOldIndex = (Array) => {
                    const uniqueMembers = {};

                    Array.forEach((member) => {
                        const { member_id } = member;
                        if (!uniqueMembers[member_id] || uniqueMembers[member_id].index < member.index) {
                            uniqueMembers[member_id] = member;
                        }
                    });

                    // Reindex the array
                    const result = Object.values(uniqueMembers).map((farmer, index) => ({
                        ...farmer,
                        index: index + 1,
                    }));

                    return result;
                };

                const updatedTemp = removeOldIndex(temp_list);
                // console.log('updatedTemp', updatedTemp)

                setRCCMemberList(updatedTemp)
                handleCloseMemberForm()
            }
        }
    }

    const handleSaveRccButton = async () => {
        // เช็คทุกช่อง
        if (checkIsUndefindedAndEmpty(rcc_form_data.name_center)) {
            alert("คุณยังไม่ได้ทำการกรอกชื่อศูนย์ข้าวชุมชน\nกรุณากรอกข้อมูล")
            return
        } else if (checkIsUndefindedAndEmpty(rcc_form_data.start_date)) {
            alert("คุณยังไม่ได้ทำการกรอกปีที่ก่อตั้ง\nกรุณากรอกข้อมูล")
            return
        } else if (checkIsUndefindedAndEmpty(rcc_form_data.year_reg)) {
            alert("คุณยังไม่ได้ทำการกรอกปีที่ลงทะเบียน\nกรุณากรอกข้อมูล")
            return
        } else if (checkIsUndefindedAndEmpty(rcc_form_data.address)) {
            alert("คุณยังไม่ได้ทำการกรอกบ้านเลขที่\nกรุณากรอกข้อมูล")
            return
        } else if (checkIsUndefindedAndEmpty(rcc_form_data.moo)) {
            alert("คุณยังไม่ได้ทำการกรอกหมู่ที่\nกรุณากรอกข้อมูล")
            return
        } else if (checkIsUndefindedAndEmpty(rcc_form_data.province_id)) {
            alert("คุณยังไม่ได้ทำการกรอกจังหวัด\nกรุณากรอกข้อมูล")
            return
        } else if (checkIsUndefindedAndEmpty(rcc_form_data.amphur_id)) {
            alert("คุณยังไม่ได้ทำการกรอกอำเภอ\nกรุณากรอกข้อมูล")
            return
        } else if (checkIsUndefindedAndEmpty(rcc_form_data.tambon_id)) {
            alert("คุณยังไม่ได้ทำการกรอกตำบล\nกรุณากรอกข้อมูล")
            return
        } else if (checkIsUndefindedAndEmpty(rcc_form_data.zipcode)) {
            alert("คุณยังไม่ได้ทำการกรอกรหัสไปรษณีย์\nกรุณากรอกข้อมูล")
            return
        } else if (checkIsUndefindedAndEmpty(rcc_form_data.utm_zone)) {
            alert("คุณยังไม่ได้ทำการกรอก UTM Zone\nกรุณากรอกข้อมูล")
            return
        } else if (checkIsUndefindedAndEmpty(rcc_form_data.utm_x)) {
            alert("คุณยังไม่ได้ทำการกรอก UTM X\nกรุณากรอกข้อมูล")
            return
        } else if (checkIsUndefindedAndEmpty(rcc_form_data.utm_y)) {
            alert("คุณยังไม่ได้ทำการกรอก UTM Y\nกรุณากรอกข้อมูล")
            return
        } else if (rcc_member_list.length < 1) {
            alert("คุณยังไม่ได้ทำการเพิ่มสมาชิก\nกรุณาเพิ่มสมาชิก")
            return
        }

        let had_president = false
        // let president_data = []
        for (let checking_member of rcc_member_list) {
            if (checking_member.rcc_name === "ประธาน") {
                had_president = true
                // president_data = checking_member
                break
            }
        }

        if (!had_president) {
            alert("คุณยังไม่ได้ทำการเพิ่มประธาน\nกรุณาเพิ่มประธาน")
            return
        } else if (rcc_form_data.is_have_license_seller && (!rcc_form_data.breed_license_no || rcc_form_data.breed_license_no.trim() === '')) {
            alert('คุณยังไม่ได้ทำการกรอกข้อมูลใบอนุญาติขายเมล็ดพันธ์ควบคุม (พ.พ.)\nกรุณากรอกข้อมูล')
            return
        } else if (rcc_form_data.is_have_registration_community && (!rcc_form_data.registration_community_no || rcc_form_data.registration_community_no.trim() === '')) {
            alert('คุณยังไม่ได้ทำการกรอกข้อมูลจดทะเบียนวิสาหกิจชุมชน\nกรุณากรอกข้อมูล')
            return
        } else if (rcc_form_data.is_have_registration_community_law) {
            if (rcc_form_data.juristic_investor_name === '' || rcc_form_data.juristic_investor_name === undefined) {
                alert('คุณยังไม่ได้ทำการกรอกข้อมูลชื่อนิติบุคคล\nกรุณากรอกข้อมูล')
                return
            } else if (rcc_form_data.registration_date === '' || rcc_form_data.registration_date === undefined) {
                alert('คุณยังไม่ได้ทำการกรอกข้อมูลวัน/เดือน/ปี ที่จดทะเบียน\nกรุณากรอกข้อมูล')
                return
            } else if (rcc_form_data.juristic_investor_registration === '' || rcc_form_data.juristic_investor_registration === undefined) {
                alert('คุณยังไม่ได้ทำการกรอกข้อมูลทะเบียนนิติบุคคล\nกรุณากรอกข้อมูล')
                return
            }
        }

        // loop rcc_project_rd
        let project_rd_list = []
        for (let project_rd of selected_project_rd) {
            console.log(project_rd);
            project_rd_list.push(project_rd.id)
        }

        let rcc_add_object = {
            data: {
                "uid": uuid(),
                "name_center": rcc_form_data.name_center,
                "start_date": new Date(rcc_form_data.start_date).getFullYear(),
                "year_reg": new Date(rcc_form_data.year_reg).getFullYear(),
                "rcc_establish_date": rcc_form_data.start_date,
                "rcc_registration_date": rcc_form_data.year_reg,

                "address": rcc_form_data.address,
                "moo": checkIsUndefindedAndEmpty(rcc_form_data.moo) ? "" : rcc_form_data.moo,
                "village": checkIsUndefindedAndEmpty(rcc_form_data.village) ? "" : rcc_form_data.village,
                "tambon_id": findValueInDict(tambon_dropdown, rcc_form_data?.tambon_id)?.code?.slice(-2),
                "tambon_name": findValueInDict(tambon_dropdown, rcc_form_data?.tambon_id)?.name,
                "amphur_id": findValueInDict(amphur_dropdown, rcc_form_data?.amphur_id)?.code?.slice(-2),
                "amphur_name": findValueInDict(amphur_dropdown, rcc_form_data?.amphur_id)?.name,
                "province_id": findValueInDict(province_dropdown, rcc_form_data?.province_id)?.code?.slice(-2),
                "province_name": findValueInDict(province_dropdown, rcc_form_data?.province_id)?.name,
                "zipcode": rcc_form_data.zipcode,
                "fulladdress": `${rcc_form_data.address} ${checkIsUndefindedAndEmpty(rcc_form_data.moo) ? "" : rcc_form_data.moo} ${checkIsUndefindedAndEmpty(rcc_form_data.village) ? "" : rcc_form_data.village} ${findValueInDict(tambon_dropdown, rcc_form_data.tambon_id).name} ${findValueInDict(amphur_dropdown, rcc_form_data.amphur_id).name} ${findValueInDict(province_dropdown, rcc_form_data.province_id).name} ${rcc_form_data.zipcode}`,
                "utm_zone": rcc_form_data.utm_zone,
                "utm_x": rcc_form_data.utm_x,
                "utm_y": rcc_form_data.utm_y,
                "fund": parseFloat(rcc_form_data.fund) || 0,
                "member": parseFloat(rcc_form_data.member) || 0,

                "is_have_license_gather": rcc_form_data.is_have_license_gather || false,
                "is_have_license_seller": rcc_form_data.is_have_license_seller || false,
                "breed_license_no": rcc_form_data.breed_license_no,
                "is_have_registration_community": rcc_form_data.is_have_registration_community || false,
                "registration_community_no": rcc_form_data.registration_community_no,
                "is_have_registration_community_law": rcc_form_data.is_have_registration_community_law || false,
                "juristic_investor_name": rcc_form_data.juristic_investor_name,
                "registration_date": rcc_form_data.registration_date,
                "juristic_investor_registration": rcc_form_data.juristic_investor_registration,

                "crmc_status": '1',
                "sts": parseInt(1),
                "project_rds": project_rd_list,
            }
        }

        for (let member_data of rcc_member_list) {
            if (member_data?.rcc_name === 'ประธาน') {
                rcc_add_object.data.prefix = String(member_data?.member_prefix_id)
                rcc_add_object.data.name = member_data?.member_name
                rcc_add_object.data.surname = member_data?.member_surname
            }
        }

        try {
            const res = await putRCC(rcc_id, rcc_add_object)
            console.log('putRcc res:', res)

        } catch (error) {
            console.error('Error in putRCC:', error);
            throw error;
        }

        // loop rcc_member_list
        for (let member_data of rcc_member_list) {
            if (member_data?.is_new) {
                // add new member
                const data = {
                    data: {
                        id_member: uuid(),
                        personal_uid: member_data?.member_farmer_uid,
                        rice_community_position: member_data?.rcc_id,
                        rcc: rcc_id,
                        createdate: new Date(),
                        is_active: true,
                    }
                }
                try {
                    let res = await saveMember(data);
                    console.log('saveMember res:', res)

                } catch (error) {
                    console.error('Error in saveMember:', error);
                    throw error;
                }
            } else if (member_data?.is_change) {
                // update member
                // log
                console.log('change!')

                const personalData = {
                    data: {
                        personal_prefix: member_data?.member_prefix_id,
                        personal_name: member_data?.member_name,
                        personal_surname: member_data?.member_surname,
                        personal_birthday: member_data?.member_birthday,
                        personal_phone: member_data?.member_phone,
                        personal_addr: member_data?.member_addr,
                        personal_moo: member_data?.member_moo,
                        personal_alley: member_data?.member_alley,
                        personal_lane: member_data?.member_lane,
                        personal_road: member_data?.member_road,
                        province: member_data?.member_province_id,
                        amphur: member_data?.member_amphur_id,
                        tambon: member_data?.member_tambon_id,
                    }
                }

                let personal_id = member_data?.id
                let member_id = member_data?.rcc_member_id

                try {
                    const res = await updatePersonal(personal_id, personalData);
                    console.log('updatePersonal res:', res)
                } catch (error) {
                    console.error('Error in updatePersonal:', error);
                    throw error;
                }

                const memberData = {
                    // TODO
                    data: {
                        rice_community_position: 1
                    }
                }

                try {
                    const res = await putMemberById(member_id, memberData);
                    console.log('putMemberById res:', res)
                } catch (error) {
                    console.error('Error in putMemberById:', error);
                    throw error;
                }
            }

            // log
            // console.log('member_data.farm_list', member_data.farm_list)
            // console.log('member_data', member_data)

            // loop member_farm_list
            for (let farm_data of member_data.member_farm_list) {
                if (farm_data?.is_new) {
                    let data = {
                        farm_name: farm_data?.farm_name,
                        farm_rai: farm_data?.farm_rai,
                        farm_nga: farm_data?.farm_nga,
                        farm_wa: farm_data?.farm_wa,
                        farm_area: farm_data?.farm_area,
                        // farm_geo_fence: mapLayers,
                        farmer: member_data?.member_farmer_id
                    }

                    const farmResponse = await callPaFPostService("/farms", { data });
                    const farm_id = farmResponse.data.data.id;
                    console.log('Farm added successfully. Farm ID:', farm_id);

                    if (farm_data?.plant_id === undefined) {
                        const riceData = {
                            data: {
                                rice_seed_type: convertRiceId.rice_seed_type[farm_data.rice_seed_type],
                                rice_type_master_datum: convertRiceId.rice_type[farm_data.rice_type],
                                rice_plan_rai: farm_data?.farm_rai,
                                rice_plan_ngan: farm_data?.farm_nga,
                                rice_plan_wa: farm_data?.farm_wa,
                                farm_id: farm_id,
                                is_join: farm_data?.is_join
                                // rcc_member: 
                            }
                        };
                        // add RicePlantPlan
                        const riceResponse = await callRicePostService("/plants", { riceData });
                        console.log('RicePlantPlan added successfully.', riceResponse);
                    }
                }
                else if (farm_data?.is_change) {
                    // console.log('change farm!')
                    if (farm_data?.plant_id === undefined) {
                        const riceData = {
                            data: {
                                rice_seed_type: convertRiceId.rice_seed_type[farm_data.rice_seed_type],
                                rice_type_master_datum: convertRiceId.rice_type[farm_data.rice_type],
                                rice_plan_rai: farm_data?.farm_rai,
                                rice_plan_ngan: farm_data?.farm_nga,
                                rice_plan_wa: farm_data?.farm_wa,
                                farm_id: farm_data?.farm_id,
                                is_join: farm_data?.is_join
                                // rcc_member: 
                            }
                        };
                        // add RicePlantPlan
                        try {
                            const riceResponse = await callRicePostService("/plants", { riceData });
                            console.log('callRicePostService plants', riceResponse)
                        } catch (error) {
                            console.error('Error in callRicePostService plants:', error);
                            throw error;
                        }
                    } else {
                        const plant_id = farm_data?.plant_id
                        const riceData = {
                            data: {
                                rice_seed_type: convertRiceId.rice_seed_type[farm_data.rice_seed_type],
                                rice_type_master_datum: convertRiceId.rice_type[farm_data.rice_type],
                                rice_plan_rai: farm_data?.farm_rai,
                                rice_plan_ngan: farm_data?.farm_nga,
                                rice_plan_wa: farm_data?.farm_wa,
                                is_join: farm_data?.is_join
                                // rcc_member: 
                            }
                        };

                        console.log('riceData', plant_id, riceData)
                        try {
                            const updateRes = await updatePlantById(plant_id, riceData);
                            console.log('updatePlantById', updateRes)
                        } catch (error) {
                            console.error('Error in updatePlantById:', error);
                            throw error;
                        }
                    }
                }
            }
        }

        // Handel equipment data
        // log
        console.log('equipment_list', equipment_list)

        for (let equipment_data of equipment_list) {
            let id = equipment_data.equipment_index

            if (equipment_data?.is_new) {
                let equipment_add = {
                    data: {
                        equipment_master_datum: equipment_data.equipment_id,
                        equipment_quantity: equipment_data.equipment_number,
                        equipment_acquired_project: equipment_data.equipment_acquired_project,
                        // "equipment_registation_year": equipment_data.equipment_registation_year,
                        rcc: rcc_id,
                        rcc_id: rcc_id,
                    }
                }

                let res = await postEquipment(equipment_add)
                console.log('postEquipment res:', res)
            } else if (equipment_data?.is_change) {
                let equipment_add = {
                    data: {
                        equipment_master_datum: equipment_data.equipment_id,
                        equipment_quantity: equipment_data.equipment_number,
                        equipment_acquired_project: equipment_data.equipment_acquired_project,
                        // "equipment_registation_year": equipment_data.equipment_registation_year,
                        rcc: rcc_id,
                        rcc_id: rcc_id,
                    }
                }

                let res = await putEquipmentById(id, equipment_add)
                console.log('putEquipmentById res:', res)
            }
        }

        // del member data
        for (let del_memder_data of delete_object) {
            const id = del_memder_data.id
            const plants = del_memder_data.plants

            try {
                let res = await delMemberById(id)
                console.log('delMemberById res:', res)

            } catch (error) {
                console.error('Error in delMemberById:', error);
                throw error;
            }

            for (let list of plants) {
                const plant_id = list?.plant_id
                const riceData = {
                    data: {
                        rice_seed_type: convertRiceId.rice_seed_type[list.rice_seed_type],
                        rice_type_master_datum: convertRiceId.rice_type[list.rice_type],
                        rice_plan_rai: list?.farm_rai,
                        rice_plan_ngan: list?.farm_nga,
                        rice_plan_wa: list?.farm_wa,
                        is_join: false
                        // rcc_member: 
                    }
                };

                if (plant_id === undefined) {
                    try {
                        const saveRes = await callRicePostService("/plants", { riceData });
                        console.log('callRicePostService plants', saveRes)
                    } catch (error) {
                        console.error('Error in callRicePostService plants:', error);
                        throw error;
                    }
                } else {
                    try {
                        const updateRes = await updatePlantById(plant_id, riceData);
                        console.log('updatePlantById', updateRes)
                        return;
                    } catch (error) {
                        console.error('Error in updatePlantById:', error);
                        throw error;
                    }
                }
            }
        }

        // del equipment data
        for (let del_equipment_data of delete_equipment_object) {
            const id = del_equipment_data.id

            try {
                let res = await delEquipmentById(id)
                console.log('delMemberById res:', res)

            } catch (error) {
                console.error('Error in delMemberById:', error);
                throw error;
            }
        }

        alert("บันทึกข้อมูลเสร็จสิ้น")
        window.location.href = "/rcc/list";
        // window.location.reload()
    }

    useEffect(() => {
        const fetchData = async () => {
            let access_token = LocalStorage.getAccessToken();
            const decoded_token = jwt_decode(access_token);
            const user_id = decoded_token?.user_id

            try {
                let rcc_data
                try {
                    let res = await callPostService(
                        '/new/rcc/list/' + user_id,
                        {
                            'filters': '&filters[id][$eq]=' + rcc_id
                        }
                    )
                    rcc_data = res.data.data[0];
                    console.log('rcc_data', rcc_data)

                    if (!rcc_data) {
                        alert('ระบบเกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง')
                        window.location.href = "/rcc/list"
                        return
                    }
                } catch (error) {
                    console.error('Error in callPostService new/rcc/list:', error);
                    throw error;
                }

                const renew_rcc_registration_date = rcc_data?.attributes?.renew_rcc_registration_date

                const checkRegistrationDate = () => {
                    const oneYearAgo = new Date();
                    oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);

                    if (!renew_rcc_registration_date || (renew_rcc_registration_date && new Date(renew_rcc_registration_date) < oneYearAgo)) {
                        alert('แจ้งเตือนต่ออายุศูนย์ข้าวชุมชน!')
                        setRenew(true)
                    }
                };

                checkRegistrationDate();

                const equipments = await getEquipmentById(rcc_id);
                const equipment_list = equipments.data.data

                let temp_list = []
                equipment_list.map((value, index) => (
                    temp_list.push({
                        index: index + 1,
                        id: index + 1,
                        equipment_id: value?.attributes?.equipment_master_datum?.data?.id,
                        equipment_index: value.id,
                        equipment_name: value?.attributes?.equipment_master_datum?.data?.attributes?.equipment_name,
                        equipment_number: value?.attributes?.equipment_quantity,
                        equipment_registation_year: value?.attributes?.equipment_registation_year,
                        equipment_acquired_project: value?.attributes?.equipment_acquired_project,
                    })
                ))

                setEquipmentList(temp_list)

                let provicne = await perpareProvinceDropdown(setProvinceDropDown)
                let selected_province = await findDataInDictWithKey(
                    provicne,
                    'code',
                    ('0' + rcc_data.attributes.province_id.toString()).slice(-2)
                )

                let amphur = await handleProvince(
                    selected_province.id,
                    setAmphurInit,
                    setAmphurDropDown,
                    setTambonInit,
                    setTambonDropDown
                )
                let selected_amphur = await findDataInDictWithKey(
                    amphur,
                    'code',
                    ('0' + rcc_data.attributes.province_id.toString()).slice(-2)
                    + ('0' + rcc_data.attributes.amphur_id.toString()).slice(-2)
                )

                let tambon = await handleAmphur(
                    selected_amphur.id,
                    setTambonInit,
                    setTambonDropDown
                )
                let selected_tambon = await findDataInDictWithKey(
                    tambon,
                    'code',
                    ('0' + rcc_data.attributes.province_id.toString()).slice(-2)
                    + ('0' + rcc_data.attributes.amphur_id.toString()).slice(-2)
                    + ('0' + rcc_data.attributes.tambon_id.toString()).slice(-2)
                )

                let project_rd_list = []
                let display_project_list = []
                let display_project_str = ""
                for(let project_rd_item of rcc_data.attributes.project_rds.data){
                    // console.log(project_rd_item);
                    project_rd_list.push(project_rd_item.id)
                    display_project_str += project_rd_item.attributes.project_name+" "
                    display_project_list.push({
                        "id": project_rd_item.id,
                        "name": project_rd_item.attributes.project_name
                    })
                }
                console.log("display_project_list", display_project_list);
                setSelectedProjectRD(display_project_list)
                // setSelectedProjectRD(display_project_str)

                setRCCFormData({
                    ...rcc_form_data,
                    "temp_number": findValueInDict(tambon, selected_tambon.id).code,
                    "name_center": rcc_data.attributes.name_center,
                    // "start_date": rcc_data.attributes.start_date + '-01-01',
                    // "year_reg": rcc_data.attributes.year_reg + '-01-01',
                    "year_reg": rcc_data.attributes.rcc_registration_date,
                    "start_date": rcc_data.attributes.rcc_establish_date,
                    "address": rcc_data.attributes.address,
                    "moo": rcc_data.attributes.moo || "-",
                    "village": rcc_data.attributes.village || "-",
                    "zipcode": rcc_data.attributes.zipcode,
                    "utm_x": rcc_data.attributes.utm_x || "-",
                    "utm_y": rcc_data.attributes.utm_y || "-",
                    "utm_zone": rcc_data.attributes.utm_zone,
                    "member": rcc_data.attributes.member,
                    "fund": rcc_data.attributes.fund,
                    "province_id": selected_province.id,
                    "amphur_id": selected_amphur.id,
                    "tambon_id": selected_tambon.id,

                    "is_have_license_gather": rcc_data.attributes.is_have_license_gather || false,
                    "is_have_license_seller": rcc_data.attributes.is_have_license_seller || false,
                    "breed_license_no": rcc_data.attributes.breed_license_no,
                    "is_have_registration_community": rcc_data.attributes.is_have_registration_community || false,
                    "registration_community_no": rcc_data.attributes.registration_community_no,
                    "is_have_registration_community_law": rcc_data.attributes.is_have_registration_community_law || false,
                    "juristic_investor_name": rcc_data.attributes.juristic_investor_name,
                    "registration_date": rcc_data.attributes.registration_date,
                    "project_rds": project_rd_list,
                    "juristic_investor_registration": rcc_data.attributes.juristic_investor_registration,
                })

                const member_list = rcc_data.attributes.rcc_members.data

                let index_i = 1
                const fetchData = async (item) => {
                    const uid = item.attributes.personal_uid;
                    let personal_id
                    let data
                    let data2

                    try {
                        const res = await getFarmerByUID(uid)
                        data = res.data?.data[0]
                        personal_id = res.data?.data[0]?.attributes?.personal?.data?.attributes?.personal_id
                    } catch (error) {
                        console.error('Error in getFarmerByUID:', error);
                        throw error;
                    }

                    try {
                        const res2 = await getMemberByUID(uid);
                        data2 = res2.data?.data[0]
                    } catch (error) {
                        console.error('Error in getMemberByUID:', error);
                        throw error;
                    }

                    let index_j = 0
                    const temp_list = []

                    for (let list of data?.attributes?.farms?.data) {
                        const farm_id = list.id
                        const res3 = await getPlantById(farm_id)
                        const data3 = res3.data.data[0]

                        temp_list.push({
                            id: list?.id,
                            index: index_j++,
                            farm_name: list?.attributes?.farm_name,
                            farm_rai: list?.attributes?.farm_rai,
                            farm_nga: list?.attributes?.farm_nga,
                            farm_wa: list?.attributes?.farm_wa,
                            farm_area: list.attributes?.farm_area,
                            rice_type: data3?.attributes?.rice_type_master_datum?.data?.attributes?.rice_type_name,
                            rice_seed_type: data3?.attributes?.rice_seed_type?.data?.attributes?.rice_seed_name,
                            is_join: data3?.attributes?.is_join,
                            plant_id: data3?.id,
                            farm_id: farm_id,
                        })
                    }

                    let search_result = await checkMemberByCitizenID(personal_id, "", 'edit')
                    let prepared_member_form_data = await perpareMemberForm(search_result)
                    // console.log('prepared_member_form_data', prepared_member_form_data)

                    const temp_amphurs = prepared_member_form_data?.amphurs?.map((value) => ({
                        code: value?.attributes?.amphur_code,
                        id: value?.id,
                        name: value?.attributes?.amphur_name
                    }));

                    const temp_tambons = prepared_member_form_data?.tambons?.map((value) => ({
                        code: value?.attributes?.tambon_code,
                        id: value?.id,
                        name: value?.attributes?.tambon_name,
                        zipcode: value?.attributes?.postcode
                    }));

                    return {
                        ...prepared_member_form_data,
                        amphurs: temp_amphurs,
                        tambons: temp_tambons,
                        farm_list: temp_list,
                        farmer_id: data?.id,
                        id: data?.attributes?.personal?.data?.id,
                        index: index_i++,
                        name: data?.attributes?.personal?.data?.attributes?.personal_name + " " + data?.attributes?.personal?.data?.attributes?.personal_surname,
                        rcc_id: rcc_id,
                        rcc_name: data2.attributes.rice_community_position.data.attributes.community_position_name,
                        rcc_member_id: data2.id,
                        rcc_status: "สมาชิก",
                    };
                };

                const processData = async () => {
                    const promises = member_list.map(fetchData);
                    const temp_list = await Promise.all(promises);
                    const roles = temp_list.map(item => item.rcc_name);

                    await setRCCMemberList(temp_list)
                    await perparePrefixDropdown(setRCCPrefixDropDown)
                    await perpareProvinceDropdown(setProvinceDropDown)
                    await perpareProvinceDropdown(setMemberProvinceDropDown)
                    await prepareEquipmentDropdown(setEquipmentDropdown)
                    await prepareRCCMemberDropdown(setRCCPositionDropdown, roles)
                    await prepareRCCPersonalProjectDropdown(setRCCPersonalProjectDropDown)
                    await prepareProjectRDDropdown(setProjectRDDropdown)
                };

                processData();
                // setBusy(false)
            } catch (error) {
                console.error('Error fetching data:', error);
            }


            try {
                let resData = await getRDUserLevel(user_id)
                console.log('resData', resData)
                let data = resData.data.data[0].attributes
                setUserLevel(data?.user_level)
            } catch (error) {
                console.error('Error in getRDUserLevel:', error);
            }
        };

        fetchData();
    }, []);

    const setSelectedProjectRD2 = (value) => {
        console.log(value);
        console.log(selected_project_rd);
        // set
        setSelectedProjectRD(value)
    }

    return (
        <div style={{ margin: '2rem' }}>
            <AdminLayout />
            <Grid
                container
                className='content-box'
                spacing={1}
                sx={{ mt: 5 }}
            >
                <Grid item xs>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Typography variant='h4'>ข้อมูลศูนย์ข้าวชุมชน</Typography>
                        <p
                            onClick={() => window.history.back()}
                            style={{ textDecoration: 'none', cursor: 'pointer' }}
                            onMouseOver={(e) => (e.currentTarget.style.textDecoration = 'underline')}
                            onMouseOut={(e) => (e.currentTarget.style.textDecoration = 'none')}
                        >
                            ย้อนกลับ
                        </p>
                    </div>
                </Grid>
                <Grid item xs={12}>
                    {
                        project_rd_dropdown.length > 0 ?
                            <RccForm
                                data={rcc_form_data}
                                setData={handleDataChange}
                                setRCCFormData={setRCCFormData}
                                province_dropdown={province_dropdown}
                                handleProvinceChange={handleProvinceChange}
                                amphur_dropdown={amphur_dropdown}
                                handleAmphurChange={handleAmphurChange}
                                amphur_init={amphur_init}
                                tambon_dropdown={tambon_dropdown}
                                handleTambonChange={handleTambonChange}
                                tambon_init={tambon_init}
                                handleSaveButton={handleSaveRccButton}
                                isDisabled={false}
                                mode={'edit'}
                                isRenew={isRenew}
                                project_rd_dropdown={project_rd_dropdown}
                                setSelectedProjectRD={setSelectedProjectRD2}
                                project_rds={selected_project_rd}
                            />
                            : null
                    }
                </Grid>
                <Grid item xs={12}>
                    <RccMemberSection
                        handleAddClick={handleAddMember}
                        rows={rcc_member_list}
                        handleDeleteMemberList={handleDeleteMemberList}
                        handleEditMemberList={handleEditMemberList}
                        isDisabled={false}
                        isRenew={isRenew}
                    />
                </Grid>
                <Grid item xs={12}>
                    <RccEquipmentSection
                        handleAddClick={handleAddEquipment}
                        rcc_equipment_list={equipment_list}
                        handleDeleteEquipmentList={handleDeleteEquipmentList}
                        handleEditEquipmentList={handleEditEquipmentList}
                        isDisabled={false}
                        isRenew={isRenew}
                    />
                </Grid>
            </Grid>
            <RccEquipmentListForm
                is_open={is_equipment_form_open}
                handleCloseEquipmentForm={handleCloseEquipmentForm}
                form_data={equipment_form_data}
                equipment_dropdown={equipment_dropdown}
                setData={handleEquipmentDataChange}
                handleSaveEquipmentForm={handleSaveEquipmentForm}
                button_mode={equipment_form_mode}
                isDisabled={false}
            />
            <RCCMemberForm
                is_open={is_member_form_open}
                button_mode={member_form_mode}
                handleCloseForm={handleCloseMemberForm}
                form_data={member_form_data}
                rcc_member_list={rcc_member_list}
                setFormData={handleFormDataChange}
                handlePositonChange={handlePositonChange}
                handlePrefixChange={handlePrefixChange}
                rcc_position_dropdown={rcc_position_dropdown}
                rcc_prefix_dropdown={rcc_prefix_dropdown}
                handleSearchCitizenID={handleSearchCitizenID}
                rcc_personal_project_dropdown={rcc_personal_project_dropdown}
                handleProjectChange={handleProjectChange}
                handleClickEditInFarmList={handleClickEditInFarmList}
                handleSaveButton={handleSaveMemberButton}
                province_dropdown={member_province_dropdown}
                amphur_dropdown={member_amphur_dropdown}
                tambon_dropdown={member_tambon_dropdown}
                handleProvinceChange={handleMemberProvinceChange}
                handleAmphurChange={handleMemberAmphurChange}
                handleTambonChange={handleMemberTambonChange}
                getPrefix={getPrefix}
                expanded={expanded}
                setExpanded={setExpanded}
                isDisabled={false}
            />
        </div>
    )
}