import React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import IconButton from '@mui/material/IconButton';
import { Edit, Delete } from '@mui/icons-material';
import PreviewIcon from '@mui/icons-material/Preview';

export default function RccMemberList({
  rows,
  row_count,
  page_count,
  handlePageSizeChange,
  handlePageChange,
  handleDeleteMemberList,
  handleEditMemberList,
  isDisabled,
}) {

  const convertFarmArea = (farm_list, filter) => {
    const total_farm_area = {
      rai: 0, nga: 0, wa: 0
    }

    if (farm_list) {
      for (let farm of farm_list) {
        if ((farm?.rice_type === filter || filter === undefined) && farm?.is_join) {
          total_farm_area.rai += farm?.farm_rai
          total_farm_area.nga += farm?.farm_nga
          total_farm_area.wa += farm?.farm_wa
        }
      }
    }

    return formatFarmArea(total_farm_area);
  }

  const formatFarmArea = (area) => {
    const formattedArea = {
      rai: area.rai.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2 }),
      nga: area.nga.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2 }),
      wa: area.wa.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 2 })
    };

    return `${formattedArea.rai} ไร่ ${formattedArea.nga} งาน ${formattedArea.wa} วา`;
  };

  const columns = [
    {
      field: 'index',
      headerName: 'ลำดับ',
      headerAlign: 'center',
      maxWidth: 55,
      flex: 1,
      align: 'center',
    },
    {
      field: 'name',
      headerName: 'ชื่อ สมาชิก',
      minWidth: 250,
      flex: 1
    },
    {
      field: 'rcc_name',
      headerName: 'ตำแหน่ง',
      minWidth: 150,
      flex: 1
    },
    {
      field: 'rcc_farm_seedstock_area',
      headerName: 'พื้นที่ปลูกเมล็ดพันธ์ุข้าว',
      minWidth: 200,
      flex: 1,
      renderCell: ({ row }) => convertFarmArea(row.farm_list, 'เมล็ดพันธุ์ข้าว')
    },
    {
      field: 'rcc_farm_high_quality_area',
      headerName: 'พื้นที่ปลูกข้าวคุณภาพดี',
      minWidth: 200,
      flex: 1,
      renderCell: ({ row }) => convertFarmArea(row.farm_list, 'ข้าวคุณภาพดี')
    },
    {
      field: "edit",
      headerName: isDisabled ? "ดู" : "แก้ไข",
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      maxWidth: 55,
      flex: 1,
      disableClickEventBubbling: true,
      renderCell: (cellValues) => {
        return <IconButton
          color="secondary"
          aria-label="add an alarm"
          onClick={(event) => {
            handleEditMemberList(event, cellValues);
          }} >
          {isDisabled ? <PreviewIcon sx={{ color: 'green' }} /> : <Edit sx={{ color: 'green' }}/>}
        </IconButton>
      }
    },
    {
      field: "delete",
      headerAlign: 'center',
      headerName: "ลบ",
      sortable: false,
      align: 'center',
      maxWidth: 55,
      flex: 1,
      disableClickEventBubbling: true,
      hide: isDisabled,
      renderCell: (cellValues) => {
        return <IconButton
          color="secondary"
          aria-label="add an alarm"
          onClick={(event) => {
            handleDeleteMemberList(event, cellValues);
          }} >
          <Delete sx={{ color: 'green' }} />
        </IconButton>
      }
    },
  ];
  return (
    <div style={{ height: 400, width: '100%', marginTop: '0rem' }}>
      <DataGrid
        style={{ backgroundColor: "#FFFFFF" }}
        rows={rows}
        rowCount={row_count}
        columns={columns}
        pageSize={page_count}
        onPageSizeChange={(newPageSize) => handlePageSizeChange(newPageSize)}
        onPageChange={(newPage) => handlePageChange(newPage)}
        // rowsPerPageOptions={[]}
        disableColumnMenu
      />
    </div>
  )
}