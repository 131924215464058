import axios from 'axios';
import config from '../config.json';
import LocalStorage from '../utils/LocalStorage';

export const getMemberByID = async (filters, keyword) => {
  let token = LocalStorage.getAccessToken();
  return axios.post(
    config.BASE_API + "/new/rcc/member",
    {
      filter: filters,
      keyword: keyword
    },
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    }
  )
}

export const saveMember = async (obj) => {
  let token = LocalStorage.getAccessToken();
  return axios.post(
    config.BASE_API + "/new/rcc/member/save",
    obj,
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    }
  )
}

export const getMemberByUID = async (uid) => {
  let token = LocalStorage.getAccessToken();
  return axios.get(
    config.BASE_API + "/new/rcc/member/uid/" + uid,
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    }
  )
}

export const delMemberById = async (id) => {
  let token = LocalStorage.getAccessToken();
  return axios.delete(
    config.BASE_API + "/new/rcc/member/" + id,
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    }
  )
}

export const putMemberById = async (id, body) => {
  let token = LocalStorage.getAccessToken();
  return axios.put(
    config.BASE_API + "/new/rcc/member/" + id,
    body,
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    }
  )
}