import React, { useState, useEffect } from 'react'
import {
    Grid,
    Typography,
    Button,
    Card,
    CardContent,
} from '@mui/material'

import RccEquipmentList from '../list/rcc-equipment-list';

export default function RccEquipmentSection({
    handleAddClick,
    rcc_equipment_list,
    rcc_equipment_count,
    handlePageChange,
    handlePageSizeChange,
    handleDeleteEquipmentList,
    handleEditEquipmentList,
    isDisabled,
    isRenew
}) {
    return (
        <Card style={{ marginTop: '1rem', marginRight: '1%', marginLeft: '1%' }}>
            <CardContent>
                <Grid container spacing={2}>
                    <Grid item xs>
                        <Typography variant='h6' style={{ paddingBottom: '20px' }}>ข้อมูลรายละเอียดเครื่องมือหรืออุปกรณ์ที่ใช้ในศูนย์ข้าวชุมชน ณ ปัจจุบัน</Typography>
                    </Grid>
                    <Grid item display={isDisabled && 'none'}>
                        <Button
                            variant="contained"
                            color="success"
                            onClick={handleAddClick}
                            disabled={isRenew}
                        >
                            เพิ่ม
                        </Button>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <RccEquipmentList
                            rows={rcc_equipment_list}
                            row_count={rcc_equipment_count}
                            handlePageChange={handlePageChange}
                            handlePageSizeChange={handlePageSizeChange}
                            handleDeleteEquipmentList={handleDeleteEquipmentList}
                            handleEditEquipmentList={handleEditEquipmentList}
                            isDisabled={isDisabled}
                        />
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
}