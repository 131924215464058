import React, { useState, useEffect } from 'react';
import {
    Dialog,
    DialogContent,
    DialogTitle,
    Grid,
    FormControl,
    Autocomplete,
    Button,
    DialogActions,
    TextField,
    Checkbox,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

import Map from '../components/map/MapComponent'
import {
    getFarmer,
    getMemberPosition,
    getPersonalProject,
    getRiceSeedType,
    getRiceType,
} from '../services/Dropdown'

import {
    callPaFPostService,
    callPostService,
    callPaFGetService
} from '../services/Service'

import jwt_decode from "jwt-decode";
import LocalStorage from '../../src/utils/LocalStorage';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;


const RCCMemberForm = ({ member_list, setMemberList, is_open, setIsOpen, mode }) => {

    const [is_open_dialog, setIs_open_dialog] = useState(false)

    const [farm_name, setFarmName] = useState("");
    const [farm_rai, setFarmRai] = useState(0);
    const [farm_nga, setFarmNga] = useState(0);
    const [farm_wa, setFarmWa] = useState(0);
    const [farm_area, setFarmArea] = useState(0);

    // Dropdown and table state
    const [community_center_member_dropdown, setCommunityCenterMemberDropdown] = useState([])
    const [community_center_member_farm, setCommunityCenterMemberFarm] = useState([])
    const [community_center_member_position_dropdown, setCommunityCenterMemberPositionDropdown] = useState([])
    const [community_center_member_project_dropdown, setCommunityMemberProjectDropdown] = useState([])
    const [rice_type, setRiceType] = useState([])
    const [rice_seed_type, setRiceSeedType] = useState([])

    const member_farm_columns = [
        { field: 'index', headerName: 'ลำดับ', width: 50 },
        { field: 'farm_name', headerName: 'ชื่อ แปลง', width: 150 },
        { field: 'farm_rai', headerName: 'ไร่', width: 50 },
        { field: 'farm_nga', headerName: 'งาน', width: 50 },
        { field: 'farm_wa', headerName: 'วา', width: 50 },
        { field: 'is_join', headerName: 'เข้าร่วม', width: 100, type: 'boolean', editable: true },
        { field: 'rice_type', headerName: 'พันธุ์ข้าว', width: 150, type: 'singleSelect', editable: true, valueOptions: ['ข้าวหอมมะลิ', 'ข้าวเสาไห้'] },
        { field: 'rice_seed_type', headerName: 'ประเภทข้าว', width: 150, type: 'singleSelect', editable: true, valueOptions: ['เมล็ดพันธุ์ข้าว', 'ข้าวคุณภาพดี'] },
    ]

    // Data state
    const [community_center_member_selected, setCommunityCenterMemberSelected] = useState([])
    const [community_center_member_position_selected, setCommunityCenterMemberPositionSelected] = useState([])
    const [community_center_member_project_selected, setCommunityMemberProjectSelected] = useState([])

    const handleAddUser = () => {
        if (community_center_member_selected.length === 0) {
            alert("คุณยังไม่ได้เลือกสมาชิก")
        } else if (community_center_member_position_selected.length === 0) {
            alert("คุณยังไม่ได้เลือกตำแหน่ง")
        } else {
            let temp_list = [];
            let index = 0;
            for (let data of member_list) {
                index++;
                temp_list.push({
                    farmer_id: data.farmer_id,
                    index: index,
                    id: data.id,
                    name: data.name,
                    uid: data.uid,
                    rcc_id: data.rcc_id,
                    rcc_name: data.rcc_name,
                    rcc_status: "สมาชิก",
                    project: data.project,
                    farm_list: data.farm_list,
                    is_old: data.is_old
                })
            }
            index++;
            let temp_farm_list = []
            let index_farm = 0;
            for (let member_farm in community_center_member_farm) {
                // console.log(member_farm);
                if (community_center_member_farm[member_farm].is_join) {
                    temp_farm_list.push({
                        id: community_center_member_farm[member_farm].id,
                        index: index_farm += 1,
                        farm_name: community_center_member_farm[member_farm].farm_name,
                        farm_rai: community_center_member_farm[member_farm].farm_rai,
                        farm_nga: community_center_member_farm[member_farm].farm_nga,
                        farm_wa: community_center_member_farm[member_farm].farm_wa,
                        is_join: community_center_member_farm[member_farm].is_join,
                        rice_type: community_center_member_farm[member_farm].rice_type,
                        rice_seed_type: community_center_member_farm[member_farm].rice_seed_type,
                        rice_type_id: community_center_member_farm[member_farm].rice_type_id,
                        rice_seed_type_id: community_center_member_farm[member_farm].rice_seed_type_id
                    })
                }
            }
            temp_list.push({
                farmer_id: community_center_member_selected.farmer_id,
                index: index,
                id: community_center_member_selected.id,
                name: community_center_member_selected.label,
                uid: community_center_member_selected.uid,
                rcc_id: community_center_member_position_selected.id,
                rcc_name: community_center_member_position_selected.label,
                rcc_status: "สมาชิก",
                project: community_center_member_project_selected,
                farm_list: temp_farm_list,
                rcc_farm: temp_farm_list.length,
                is_old: false
            })
            // console.log(temp_lis);
            setMemberList(temp_list)
            setIsOpen(false)
        }
    }

    const handleEditFarmTable = (value) => {
        let temp_farm_list = []
        let index = 0;
        // console.log(value);
        for (let member_farm in community_center_member_farm) {
            let rice_type_id = community_center_member_farm[member_farm].rice_type_id
            let rice_seed_type_id = community_center_member_farm[member_farm].rice_seed_type_id
            if (community_center_member_farm[member_farm].id === value.id) {
                community_center_member_farm[member_farm][value.field] = value.value;
            }
            if (value.field === "rice_type") {
                for (let i in rice_type) {
                    if (rice_type[i].name === value.value) {
                        rice_type_id = rice_type[i].id
                        // break
                    }
                }
            }
            if (value.field === "rice_seed_type") {
                for (let i in rice_seed_type) {
                    if (rice_seed_type[i].name === value.value) {
                        rice_seed_type_id = rice_seed_type[i].id
                        // break
                    }
                }
            }
            temp_farm_list.push({
                id: community_center_member_farm[member_farm].id,
                index: index += 1,
                farm_name: community_center_member_farm[member_farm].farm_name,
                farm_rai: community_center_member_farm[member_farm].farm_rai,
                farm_nga: community_center_member_farm[member_farm].farm_nga,
                farm_wa: community_center_member_farm[member_farm].farm_wa,
                is_join: community_center_member_farm[member_farm].is_join,
                rice_type: community_center_member_farm[member_farm].rice_type,
                rice_seed_type: community_center_member_farm[member_farm].rice_seed_type,
                rice_seed_type_id: rice_seed_type_id,
                rice_type_id: rice_type_id
            })
        }
        // console.log(temp_farm_list);
        setCommunityCenterMemberFarm(temp_farm_list)
    }

    const handleChangeMember = (value) => {
        // console.log(value.farm_list);
        if (value?.farm_list !== null && value?.farm_list !== undefined) {
            setCommunityCenterMemberFarm(value.farm_list)
        }
        setCommunityCenterMemberSelected(value);
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                getFarmer().then(res => {
                    let temp_list = []
                    let res_data = res.data.data
                    // console.log("get Farmer")
                    // console.log(res_data);
                    for (let row_data of res_data) {
                        // console.log(row_data);
                        let temp_farm_list = [];
                        let index = 0;
                        for (let row_farm_data of row_data.attributes.farms.data) {
                            temp_farm_list.push({
                                id: row_farm_data.id,
                                index: index += 1,
                                farm_name: row_farm_data.attributes.farm_name,
                                farm_rai: row_farm_data.attributes.farm_rai,
                                farm_nga: row_farm_data.attributes.farm_nga,
                                farm_wa: row_farm_data.attributes.farm_wa,
                                is_join: false,
                                rice_type: "",
                                rice_seed_type: "",
                                rice_type_id: 0,
                                rice_seed_type_id: 0
                            })
                        }
                        temp_list.push({
                            farmer_id: row_data.id,
                            id: row_data.attributes.personal.data.id,
                            label: row_data.attributes.personal.data.attributes.personal_name + " " + row_data.attributes.personal.data.attributes.personal_surname,
                            uid: row_data.attributes.farmer_uid,
                            farm_list: temp_farm_list
                        })
                    }
                    // console.log("member dropdown")
                    // console.log(temp_list)
                    setCommunityCenterMemberDropdown(temp_list)
                })

                getMemberPosition().then(res => {
                    let temp_list = []
                    let res_data = res.data.data
                    for (let row_data of res_data) {
                        temp_list.push({
                            id: row_data.id,
                            label: row_data.attributes.community_position_name,
                        })
                    }
                    setCommunityCenterMemberPositionDropdown(temp_list)
                })

                getPersonalProject().then(res => {
                    let temp_list = []
                    let res_data = res.data.data
                    for (let row_data of res_data) {
                        temp_list.push({
                            id: row_data.id,
                            name: row_data.attributes.project_name,
                        })
                    }
                    setCommunityMemberProjectDropdown(temp_list)
                })

                getRiceType().then(res => {
                    let temp_list = []
                    let res_data = res.data.data
                    // console.log(res_data);
                    for (let row_data of res_data) {
                        temp_list.push({
                            id: row_data.id,
                            name: row_data.attributes.rice_type_name,
                        })
                    }
                    setRiceType(temp_list)
                })

                getRiceSeedType().then(res => {
                    let temp_list = []
                    let res_data = res.data.data
                    // console.log(res_data);
                    for (let row_data of res_data) {
                        temp_list.push({
                            id: row_data.id,
                            name: row_data.attributes.rice_seed_name,
                        })
                    }
                    setRiceSeedType(temp_list)
                })
            } catch (error) {
                console.error('Error fetching data from Server:', error);
                alert('Error fetching data from Server')
            }
        };

        fetchData();
    }, []);

    useEffect(()=>{
        callPaFGetService('/farms/'+community_center_member_selected.farmer_id)
        .then(res => {
            let temp_farm_list = []
            let res_data = res.data.data
            // console.log(res_data)
            let index = 0
            for (let row_farm_data of res_data) {
                temp_farm_list.push({
                    id: row_farm_data.id,
                    index: index += 1,
                    farm_name: row_farm_data.attributes.farm_name,
                    farm_rai: row_farm_data.attributes.farm_rai,
                    farm_nga: row_farm_data.attributes.farm_nga,
                    farm_wa: row_farm_data.attributes.farm_wa,
                    is_join: false,
                    rice_type: "",
                    rice_seed_type: "",
                    rice_type_id: 0,
                    rice_seed_type_id: 0
                })
            }
            setCommunityCenterMemberFarm(temp_farm_list)
        })
        // getFarmer().then(res => {
        //     let temp_list = []
        //     let res_data = res.data.data
        //     for (let row_data of res_data) {
        //         let temp_farm_list = [];
        //         let index = 0;
        //         for (let row_farm_data of row_data.attributes.farms.data) {
        //             temp_farm_list.push({
        //                 id: row_farm_data.id,
        //                 index: index += 1,
        //                 farm_name: row_farm_data.attributes.farm_name,
        //                 farm_rai: row_farm_data.attributes.farm_rai,
        //                 farm_nga: row_farm_data.attributes.farm_nga,
        //                 farm_wa: row_farm_data.attributes.farm_wa,
        //                 is_join: false,
        //                 rice_type: "",
        //                 rice_seed_type: "",
        //                 rice_type_id: 0,
        //                 rice_seed_type_id: 0
        //             })
        //         }
        //         temp_list.push({
        //             farmer_id: row_data.id,
        //             id: row_data.attributes.personal.data.id,
        //             label: row_data.attributes.personal.data.attributes.personal_name + " " + row_data.attributes.personal.data.attributes.personal_surname,
        //             uid: row_data.attributes.personal.data.attributes.personal_uid,
        //             farm_list: temp_farm_list
        //         })
        //     }
        //     setCommunityCenterMemberDropdown(temp_list)
        // })
    }, [is_open_dialog])

    const [mapLayers, setMapLayers] = useState([])

    const handleDataFromChild2 = (data) => {
        // console.log(data)
        setMapLayers(data)
    }

    const handleDataFromChild = (data) => {
        // setReceivedData(data);
        setFarmRai((parseInt(data / 1600)));
        setFarmNga((parseInt(data % 1600) / 400));
        setFarmWa((parseInt(data % 400) / 4));
        setFarmArea(parseInt(data));
    };

    const handleSaveFarmButton = () => {
        if (farm_name === "") {
            alert("คุณยังไม่ได้ระชุชื่อแปลง \n กรุณาระบุชื่อแปลง")
        } else {
            // console.log(community_center_member_selected);
            let data = {
                data: {
                    farm_name: farm_name,
                    farm_rai: farm_rai,
                    farm_nga: farm_nga,
                    farm_wa: farm_wa,
                    farm_area: farm_area,
                    farm_geo_fence: mapLayers,
                    farmer: community_center_member_selected.farmer_id
                }
            }

            // console.log(data)

            callPaFPostService("/farms", data).then(res => {
                // console.log('res', res)
                setIs_open_dialog(false);
                // setIsRefresh(!is_refresh)
                // setIsModalOpen(false)
                // window.location.reload();
            })
        }
    }

    return (
        <>
            <Dialog
                open={is_open}
                onClose={(event) => setIsOpen(false)}
                fullWidth
                aria-labelledby="AddUser-Dialog-title"
                aria-describedby="AddUser-Dialog-Content"
                sx={{
                    "& .MuiDialog-container": {
                        "& .MuiPaper-root": {
                            width: "100%",
                            maxWidth: "100px",  // Set your width here
                        },
                    },
                }}
            >
                <DialogTitle id="AddUser-Dialog-title">เพิ่มสมาชิก</DialogTitle>
                <DialogContent id="AddUser-Dialog-Content">
                    <Grid container spacing={2} sx={{ paddingTop: 2 }}>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <Autocomplete
                                    onChange={(event, newValue) => {
                                        handleChangeMember(newValue)
                                    }}
                                    id="controllable-states-demo"
                                    options={community_center_member_dropdown}
                                    sx={{ width: '100%' }}
                                    renderInput={(params) => <TextField {...params} label="ชื่อสมาชิก" />}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <Autocomplete
                                    onChange={(event, newValue) => {
                                        setCommunityCenterMemberPositionSelected(newValue);
                                    }}
                                    id="controllable-states-demo"
                                    options={community_center_member_position_dropdown}
                                    sx={{ width: '100%' }}
                                    renderInput={(params) => <TextField {...params} label="ตำแหน่งสมาชิก" />}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <Autocomplete
                                    multiple
                                    id="checkboxes-tags-demo"
                                    options={community_center_member_project_dropdown}
                                    disableCloseOnSelect
                                    getOptionLabel={(option) => option.name}
                                    onChange={(event, new_value) => {
                                        setCommunityMemberProjectSelected(new_value)
                                    }}
                                    renderOption={(props, option, { selected }) => (
                                        <li {...props}>
                                            <Checkbox
                                                icon={icon}
                                                checkedIcon={checkedIcon}
                                                style={{ marginRight: 8 }}
                                                checked={selected}
                                            />
                                            {option.name}
                                        </li>
                                    )}
                                    style={{ width: '100%' }}
                                    renderInput={(params) => (
                                        <TextField {...params} label="โครงการ" placeholder="โครงการ" />
                                    )}
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={12}>
                            <Grid
                                container
                                spacing={2}
                                direction="row"
                                justifyContent="flex-end"
                                alignItems="center"
                            >
                                <Grid item>
                                    <Button
                                        variant="contained"
                                        color="success"
                                        onClick={() => setIs_open_dialog(true)}
                                    >
                                        เพิ่มแปลง
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12}>
                            <div style={{ height: 300, width: '100%', marginTop: '1rem' }}>
                                <DataGrid
                                    style={{ backgroundColor: "#FFFFFF" }}
                                    rows={community_center_member_farm}
                                    columns={member_farm_columns}
                                    onCellEditCommit={(event, new_value) => handleEditFarmTable(event, new_value)}
                                />
                            </div>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleAddUser}
                        variant="contained"
                        color="success"
                    >
                        เพิ่มสมาชิก
                    </Button>

                    <Button
                        variant="contained"
                        onClick={(event) => setIsOpen(false)}
                        color="error"
                    >
                        ยกเลิก
                    </Button>
                </DialogActions>
            </Dialog>


            <Dialog
                open={is_open_dialog}
                onClose={() => setIs_open_dialog(false)}
                fullWidth
                aria-labelledby="AddUser-Dialog-title"
                aria-describedby="AddUser-Dialog-Content"
                sx={{
                    "& .MuiDialog-container": {
                        "& .MuiPaper-root": {
                            width: "100%",
                            maxWidth: "800px",  // Set your width here
                        },
                    },
                }}
            >
                <DialogTitle id="AddUser-Dialog-title">เพิ่มแปลง</DialogTitle>
                <DialogContent id="AddUser-Dialog-Content">
                    {/* <Map /> */}
                    <Map onData={handleDataFromChild} onData2={handleDataFromChild2} />

                    <Grid container spacing={2} style={{ marginTop: '2rem', marginBottom: '2rem' }}>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                id="outlined-basic"
                                label="ชื่อแปลง"
                                variant="outlined"
                                value={farm_name}
                                onChange={event => setFarmName(event.target.value)}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                id="outlined-basic"
                                label="ไร่"
                                variant="outlined"
                                value={farm_rai}
                                type="number"
                                onChange={event => setFarmRai(event.target.value)}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                id="outlined-basic"
                                label="งาน"
                                variant="outlined"
                                value={farm_nga}
                                type="number"
                                onChange={event => setFarmNga(event.target.value)}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                id="outlined-basic"
                                label="วา"
                                variant="outlined"
                                value={farm_wa}
                                type="number"
                                onChange={event => setFarmWa(event.target.value)}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                id="outlined-basic"
                                label="พื้นที่ (ตารางเมตร)"
                                variant="outlined"
                                value={farm_area}
                                onChange={event => setFarmArea(event.target.value)}
                            />
                        </Grid>
                    </Grid>
                    {/* <Grid container spacing={2} sx={{ paddingTop: 2 }}>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <Autocomplete
                                    onChange={(event, newValue) => {
                                        handleChangeMember(newValue)
                                    }}
                                    id="controllable-states-demo"
                                    options={community_center_member_dropdown}
                                    sx={{ width: '100%' }}
                                    renderInput={(params) => <TextField {...params} label="ชื่อสมาชิก" />}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <Autocomplete
                                    onChange={(event, newValue) => {
                                        setCommunityCenterMemberPositionSelected(newValue);
                                    }}
                                    id="controllable-states-demo"
                                    options={community_center_member_position_dropdown}
                                    sx={{ width: '100%' }}
                                    renderInput={(params) => <TextField {...params} label="ตำแหน่งสมาชิก" />}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <Autocomplete
                                    multiple
                                    id="checkboxes-tags-demo"
                                    options={community_center_member_project_dropdown}
                                    disableCloseOnSelect
                                    getOptionLabel={(option) => option.name}
                                    onChange={(event, new_value) => {
                                        setCommunityMemberProjectSelected(new_value)
                                    }}
                                    renderOption={(props, option, { selected }) => (
                                        <li {...props}>
                                            <Checkbox
                                                icon={icon}
                                                checkedIcon={checkedIcon}
                                                style={{ marginRight: 8 }}
                                                checked={selected}
                                            />
                                            {option.name}
                                        </li>
                                    )}
                                    style={{ width: '100%' }}
                                    renderInput={(params) => (
                                        <TextField {...params} label="โครงการ" placeholder="โครงการ" />
                                    )}
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={12}>
                            <Grid
                                container
                                spacing={2}
                                direction="row"
                                justifyContent="flex-end"
                                alignItems="center"
                            >
                                <Grid item>
                                    <Button
                                        variant="contained"
                                        color="success"
                                        onClick={(event) => console.log('click')}
                                    >
                                        เพิ่มแปลง
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12}>
                            <div style={{ height: 300, width: '100%', marginTop: '1rem' }}>
                                <DataGrid
                                    style={{ backgroundColor: "#FFFFFF" }}
                                    rows={community_center_member_farm}
                                    columns={member_farm_columns}
                                    onCellEditCommit={(event, new_value) => handleEditFarmTable(event, new_value)}
                                />
                            </div>
                        </Grid>
                    </Grid> */}
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => handleSaveFarmButton()}
                        variant="contained"
                        color="success"
                    >
                        เพิ่มแปลง
                    </Button>

                    <Button
                        variant="contained"
                        onClick={() => setIs_open_dialog(false)}
                        color="error"
                    >
                        ยกเลิก
                    </Button>
                </DialogActions>
            </Dialog>
        </>


    )
}

export default RCCMemberForm;