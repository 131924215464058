import React, { useState, useEffect } from 'react'
import {
    Card,
    CardContent,
    Grid,
    Typography,
} from '@mui/material'

import RegionForm from '../../components/form/region-form'
import AdminLayout from '../../components/admin/AdminLayout'

import {
    getProvince,
    getAmphurs,
    getTambons,
} from '../../services/Dropdown'

import {
    findValueInDict
} from '../../utils/utils'

import LocalStorage from '../../utils/LocalStorage'
import jwt_decode from "jwt-decode";

import CommitteeTambonList from '../../components/list/committe-tambon'
import { getCommittee } from '../../services/rcc-committee'
import CommitteeAmphurList from '../../components/list/committe-amphur'
import UtilityBackdrop from '../../components/UtilityBackdrop'

export default function CommitteeAmphurPage() {
    const [is_loading, setIsLoading] = useState(false)
    const [province_dropdown, setProvinceDropdown] = useState([])
    const [selected_province, setSelectedProvince] = useState(0)

    const [committee_amphur_list, setCommitteeAmphurList] = useState([])
    const [row_count, setRowCount] = useState(0)
    const [page_size, setPageSize] = useState(100)
    // const [tambob_list , setTambonList] = useState([])

    const handleProvinceChange = (value) =>{
        setSelectedProvince(value)
    }

    const handleSearchClick = async () => {
        if (selected_province === 0) {
            alert("คุณยังไม่ได้ทำการเลือกจังหวัด \n กรุณาเลือกจังหวัด")
        } else {
            setIsLoading(true)
            setCommitteeAmphurList([])
            let selected_province_details = findValueInDict(province_dropdown, selected_province)
            let amphur = await getAmphurs(selected_province);
            let temp_list = []
            let index = 0
            let res_data = amphur.data.data
            for (let row_data of res_data) {
                // console.log(row_data);
                let filter = "&filters[province_id][$eq]=" + selected_province_details.code
                    + "&filters[amphur_id][$eq]=" + row_data.attributes.amphur_code.slice(-2).slice(-2)
                    + "&filters[tambon_id][$ne]=00"
                // console.log(filter);
                let tambon_committee_list = await getCommittee(filter)
                filter = "&filters[province_id][$eq]=" + selected_province_details.code
                    + "&filters[amphur_id][$eq]=" + row_data.attributes.amphur_code.slice(-2).slice(-2)
                    + "&filters[tambon_id][$eq]=00"
                let committee_list = await getCommittee(filter)
                index += 1
                temp_list.push({
                    id: row_data.id,
                    index: index,
                    province_code: selected_province_details.code,
                    province_name: selected_province_details.name,
                    amphur_code: row_data.attributes.amphur_code,
                    amphur_name: row_data.attributes.amphur_name,
                    committe_num: committee_list.data.meta.pagination.total,
                    committe_amphur_num: tambon_committee_list.data.meta.pagination.total,
                    is_can_add: tambon_committee_list.data.meta.pagination.total > 0 ? true : false,
                })
            }
            setCommitteeAmphurList(temp_list)
            setIsLoading(false)
        }
    }   

    const handleViewClick = (event, value) => {
        LocalStorage.setSelectedCommitteeAmphur(value.row)
        window.location.href = "/rcc/committe/amphur/details";
    }

    useEffect(() => {
        LocalStorage.removeSelectedCommitteeTambon()
        let access_token = LocalStorage.getAccessToken();
        var decoded_token = jwt_decode(access_token);

    //   try {
        // getProvince(decoded_token?.user_id)
        getProvince(decoded_token?.user_id).then(res => {
            let temp_list = []
            let res_data = res.data.data
            for (let row_data of res_data) {
                temp_list.push({
                    id: row_data.id,
                    name: row_data.attributes.province_name,
                    code: row_data.attributes.province_code,
                })
            }
            setProvinceDropdown(temp_list)
        })
    }, [])

    return (
        <div style={{ margin: '2rem' }}>
            <AdminLayout />
            <Card style={{ marginTop: '0rem', marginRight: '1%', marginLeft: '1%' }}>
                <CardContent>
                    <Grid
                        container
                        className='content-box'
                        spacing={1}
                        sx={{ mt: 5 }}
                    >
                        <Grid item xs>
                            <Typography variant='h4'>รายการคณะกรรมการศูนย์ข้าวชุมชนระดับอำเภอ</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <RegionForm
                                mode={"commitee_amphur"}
                                province_dropdown={province_dropdown}
                                selected_province={selected_province}
                                handleProvinceChange={handleProvinceChange}
                                handleSearchClick={handleSearchClick}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <CommitteeAmphurList
                                rows={committee_amphur_list}
                                row_count={row_count}
                                page_size={page_size}
                                handleViewClick={handleViewClick}
                            />
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
            <UtilityBackdrop 
                open={is_loading}
            />
        </div>
    )
}