import * as React from 'react';
import Button from '@mui/material/Button';
// import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { adminLogin } from '../../services/admin/Auth'
import LocalStorage from '../../utils/LocalStorage'
import Alert from '@mui/material/Alert';

import logoRcc from '../../assets/images/rcc-logo.png'
import logoRice from '../../assets/images/rice_logo.png'

const theme = createTheme();

export default function SignIn() {
  const [username, setUsername] = React.useState('')
  const [password, setPassword] = React.useState('')
  const [isSuccess, setIsSuccess] = React.useState(true)
  const [data, setData] = React.useState('')

  const handleSubmit = (event) => {
    event.preventDefault();
    let object = {
      "username": username,
      "password": password,
      "app_id": 6,
    }
    adminLogin(object).then(res => {
      LocalStorage.setAccessToken(res.data.data[0]['accessToken'])
      LocalStorage.setRefreshToken(res.data.data[0]['refreshToken'])
      setIsSuccess(true)
      window.location.href = "/dashboard";
    }).catch(error => {
      console.log('error',error.response);
      if (error.response) {
        if (error.response.status === 401) {
          setIsSuccess(false)
          setData("ไม่พบชื่อผู้ใช้งานในระบบ")
        }
      }
    })
  };

  return (
    <ThemeProvider theme={theme}>
      <Box className='login-bg'>
        <Container component="main" maxWidth="xs" style={{ marginTop: '8rem' }}>
          {/* <CssBaseline /> */}
          <div className='login-box'>
            <Box
              sx={{
                marginTop: 2,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <img style={{ width: '200px' }} src={logoRcc} alt='' />

              <Typography align='center' component="h1" variant="h5">
                กรมการข้าว<br />  ระบบจัดการข้อมูลศูนย์ข้าวชุมชน
              </Typography>
              <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                {
                  !isSuccess ? <Alert severity="error">{data}</Alert> : null
                }
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="username"
                  label="ชื่อผู้ใช้งาน"
                  name="username"
                  autoComplete="username"
                  autoFocus
                  onChange={(e) => setUsername(e.target.value)}
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="รหัสผ่าน"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  onChange={(e) => setPassword(e.target.value)}

                />
                {/* <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            /> */}
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                  color="success"
                >
                  เข้าสู่ระบบ
                </Button>
              </Box>
            </Box>
          </div>
        </Container>
        <div className='login-sub'>
          พัฒนาโดยศูนย์เทคโนโลยีสารสนเทศและการสื่อสาร กรมการข้าว<br />
          <img style={{ width: '100px' }} src={logoRice} alt='' />
        </div>
      </Box>
    </ThemeProvider>
  );
}