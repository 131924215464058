import React, { useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';

export default function CommitteeTambonList({
    rows,
    row_count,
    page_size,
    handleViewClick,
}) {
    const columns = [
        {
            field: 'index',
            headerName: 'ลำดับ',
            maxWidth: 50,
            flex: 1
        },
        {
            field: 'province_name',
            headerName: 'จังหวัด',
            minWidth: 150,
            flex: 1
        },
        {
            field: 'amphur_name',
            headerName: 'อำเภอ',
            minWidth: 250,
            flex: 1
        },
        {
            field: 'tambon_name',
            headerName: 'ตำบล',
            minWidth: 250,
            flex: 1
        },
        {
            field: 'committe_num',
            headerName: 'จำนวนกรรมการ',
            minWidth: 250,
            flex: 1
        },
        {
            field: 'rcc_member',
            headerName: 'จำนวนสมาชิกศูนย์ข้าวชุมชน',
            minWidth: 250,
            flex: 1
        },
        {
            field: 'rcc_number',
            headerName: 'จำนวนศูนย์ข้าวชุมชน',
            minWidth: 250,
            flex: 1
        },
        {
            field: "view",
            headerName: "เรียกดู",
            sortable: false,
            maxWidth: 100,
            flex: 1,
            disableClickEventBubbling: true,
            renderCell: (cellValues) => {
                if (cellValues.row.is_can_add) {
                    return (
                        <IconButton
                            color="secondary"
                            aria-label="add an alarm"
                            onClick={(event) => {
                                handleViewClick(event, cellValues);
                            }} >
                            <SearchIcon />
                        </IconButton>
                    )
                }
                // return (
                //     // cellValues.row.is_can_add ? 
                //     <IconButton
                //         color="secondary"
                //         aria-label="add an alarm"
                //         onClick={(event) => {
                //             handleViewClick(event, cellValues);
                //         }} >
                //         <SearchIcon />
                //     </IconButton>
                // //     : <></>
                // )
            }
        }

    ];
    return (
        <div style={{ height: 600, width: '100%', marginTop: '2rem' }}>
            <DataGrid
                style={{ backgroundColor: "#FFFFFF" }}
                rows={rows}
                rowCount={row_count}
                columns={columns}
                pageSize={page_size}
            />
        </div>
    )
}