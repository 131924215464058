import axios from 'axios';
import config from '../config.json';
import LocalStorage from '../utils/LocalStorage';

export const callService = async (uri, obj) => {
  let token = LocalStorage.getAccessToken();
  return axios.post(
    config.BASE_API + uri,
    obj,
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    }
  )
}

export const callPutService = async (uri, obj) => {
  let token = LocalStorage.getAccessToken();
  return axios.put(
    config.BASE_API + uri,
    obj,
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    }
  )
}

export const callPostService = async (uri, data) => {
  let token = LocalStorage.getAccessToken();
  return axios.post(
    config.BASE_API + uri,
    data,
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    }
  )
}

export const callGetService = async (uri) => {
  let token = LocalStorage.getAccessToken();
  return axios.get(
    config.BASE_API + uri,
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    }
  )
}

export const callPaFGetService = async (uri) => {
  let token = LocalStorage.getAccessToken();
  return axios.get(
    config.PAF_API + uri,
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    }
  )
}
export const callPaFPostService = async (uri, obj) => {
  let token = LocalStorage.getAccessToken();
  return axios.post(
    config.PAF_API + uri,
    obj,
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    }
  )
}

export const callRicePostService = async (uri, obj) => {
  let token = LocalStorage.getAccessToken();
  return axios.post(
    config.BASE_API + uri,
    obj,
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    }
  )
}

export const callRccApi = async () => {
  return axios.get(
    config.RRC_API,
    {
      headers: {
        'Content-Type': 'application/json',
      }
    }
  )
}

export const getRenewList = async (uri, body) => {
  let token = LocalStorage.getAccessToken();
  return axios.post(
    config.BASE_API + uri,
    body,
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    }
  )
}

// export const callPostService = async (uri, data) => {
//   let token = LocalStorage.getAccessToken();
//   return axios.post(
//     config.BASE_API + uri,
//     data,
//     {
//       headers: {
//         'Content-Type': 'application/json',
//         'Authorization': 'Bearer ' + token
//       }
//     }
//   )
// }