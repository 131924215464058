/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import {
    Dialog,
    DialogContent,
    DialogTitle,
    Grid,
    // FormControl,
    Button,
    DialogActions,
    // FormLabel,
    // Divider,
    // Typography,
    // FormControlLabel,
    // RadioGroup,
    // Radio
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';

import {
    callGetService,
    //  callPostService
} from '../services/Service'

const RCCQuestionnairHistory = ({ is_open, setIsOpen, rcc_id }) => {
    const [rcc_questionnair_history_list, setRCCQuestionnairHistoryList] = useState([])
    // const [rcc_continue_date, setRCCQuestionnairHistoryList] = useState([])

    const columns = [
        {
            field: 'index',
            headerName: 'ลำดับ',
            maxWidth: 50,
            flex: 1
        },
        {
            field: 'ans_date',
            headerName: 'วันที่ทำแบบประเมิน',
            minWidth: 150,
            flex: 1
        },
        {
            field: 'score',
            headerName: 'คะแนน',
            minWidth: 250,
            flex: 1
        },
        {
            field: 'grade',
            headerName: 'เกรด',
            minWidth: 150,
            flex: 1
        },
    ]

    useEffect(() => {
        callGetService('/rcc-question-sums/' + rcc_id)
            .then(res => {
                let temp_list = []
                let index = 0
                let data = res.data.data
                for (let item of data) {
                    index++
                    console.log(item);
                    temp_list.push({
                        id: index,
                        index: index,
                        ans_date: item.attributes.ans_date,
                        score: item.attributes.total_score.toFixed(2).toString() + "%",
                        grade: item.attributes.ans_grade
                    })
                }
                setRCCQuestionnairHistoryList(temp_list)
            })
    }, []);

    return (
        <>
            <Dialog
                open={is_open}
                onClose={(event) => setIsOpen(false)}
                fullWidth
                aria-labelledby="AddUser-Dialog-title"
                aria-describedby="AddUser-Dialog-Content"
                sx={{
                    "& .MuiDialog-container": {
                        "& .MuiPaper-root": {
                            width: "100%",
                            maxWidth: "800px",  // Set your width here
                        },
                    },
                }}
            >
                <DialogTitle id="AddUser-Dialog-title">ประวัติการทำแบบประเมิน</DialogTitle>
                <DialogContent id="AddUser-Dialog-Content">
                    <Grid container spacing={2} sx={{ paddingTop: 0 }}>
                        <Grid item xs={12} >
                            <div style={{ height: 600, width: '100%', marginTop: '2rem' }}>
                                <DataGrid
                                    style={{ backgroundColor: "#FFFFFF" }}
                                    rows={rcc_questionnair_history_list}
                                    columns={columns}
                                // rowsPerPageOptions={[]}
                                />
                            </div>
                        </Grid>
                    </Grid>
                </DialogContent>

                <DialogActions sx={{ m: 2 }}>
                    <Button
                        variant="contained"
                        onClick={(event) => setIsOpen(false)}
                        color="error">
                        ปิดหน้าต่าง
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default RCCQuestionnairHistory;