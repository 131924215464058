import React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import IconButton from '@mui/material/IconButton';
// import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

export default function RccCommitteeList({ 
    rows, 
    row_count, 
    page_count, 
    handlePageSizeChange, 
    handlePageChange,
    handleDeleteClick
}) {
    const columns = [
        {
            field: 'index',
            headerName: 'ลำดับ',
            maxWidth: 50,
            flex: 1
        },
        {
            field: 'name',
            headerName: 'ชื่อ',
            minWidth: 150,
            flex: 1
        },
        {
            field: 'position',
            headerName: 'ตำแหน่ง',
            minWidth: 150,
            flex: 1
        },
        {
            field: 'rcc_name',
            headerName: 'ศูนย์ข้าวชุมชนที่สังกัด',
            minWidth: 250,
            flex: 1
        },
        {
          field: "delete",
          headerName: "ลบ",
          sortable: false,
          maxWidth: 100,
          flex:1,
          disableClickEventBubbling: true,
          renderCell: (cellValues) => {
            return <IconButton
              color="secondary"
              aria-label="add an alarm"
              onClick={(event) => {
                handleDeleteClick(event, cellValues);
              }} >
              <DeleteIcon />
            </IconButton>
          }
        }
    ];

    // const handleEditClick = (event, cellValues) => {
    //     console.log(cellValues.row.id);
    //     // window.location.href = "/rcc/edit/" + cellValues.row.id
    // }

    return (
        <div style={{ height: 600, width: '100%', marginTop: '2rem' }}>
            <DataGrid
              style={{ backgroundColor: "#FFFFFF" }}
              rows={rows}
              rowCount={row_count}
              columns={columns}
              pageSize={page_count}
              onPageSizeChange={(newPageSize) => handlePageSizeChange(newPageSize)}
              onPageChange={(newPage) => handlePageChange(newPage)}
              // rowsPerPageOptions={[]}
            />
        </div>
    )
}