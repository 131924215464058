import React, { useState, useEffect } from 'react'
import LocalStorage from '../../utils/LocalStorage'
import AdminLayout from '../../components/admin/AdminLayout'
import RccCommitteeList from '../../components/list/rcc-committe'
import CommitteeCitizenIDForm from '../../components/form/committee-citizen-id-form';
import CommitteeListForm from '../../components/form/committee-list-form';

import {
    Card,
    CardContent,
    Grid,
    Typography,
    Button,
} from '@mui/material'

import {
    getCommittee,
    addRCCCommittee,
    deleteRCCCommittee,
} from '../../services/rcc-committee'

import {
    getMemberPosition,
} from '../../services/Dropdown'

import {
    searchCommitteByCitizenID
} from '../../utils/Farmer'

import {
    getMemberByID
} from '../../services/rcc-member'
import UtilityBackdrop from '../../components/UtilityBackdrop';
import { findValueInDict } from '../../utils/utils';
import ConfirmationBox from '../../components/utility/confirmation-box';

export default function CommitteeTambonDetailsPage() {
    const [is_loading, setIsLoading] = useState(false)
    const [sub_title, setSubTitle] = useState("")
    const [committee_list, setCommitteeList] = useState([])
    const [committee_list_count, setCommitteeListCount] = useState(100)
    const [committee_list_page_count, setCommitteeListPageCount] = useState(0)

    const [position_dropdown, setPositionDropdown] = useState([])
    const [farmer_dropdown, setFarmerDropdown] = useState([])

    const [is_add_committee_open, setIsAddCommitteeOpen] = useState(false)
    const [is_add_committee_list_open, setIsAddCommitteeListOpen] = useState(false)
    const [form_data, setFormData] = useState([])

    const [is_open_confirmation_box, setIsOpenConfirmationBox] = useState(false)
    const [dialog_title, setDialogTitle] = useState("")
    const [dialog_content, setDialogContent] = useState("")
    const [confirm_label, setConfirmLabel] = useState("")
    const [cancle_label, setCancleLabel] = useState("ยกเลิก")
    const [selected_id, setSelectedId] = useState(0)
    const [confirmation_mode, setConfirmationMode] = useState("")

    const handleCloseCommitteeForm = () => {
        setIsAddCommitteeOpen(false)
    }

    const handleCloseCommitteeListForm = () => {
        setIsAddCommitteeListOpen(false)
    }

    const handlePageSizeChange = () => {

    }

    const handlePageChange = () => {

    }

    const handleAddClick = () => {
        setIsAddCommitteeOpen(true)
    }

    const handleAddListClick = async () => {
        setIsAddCommitteeListOpen(true)
    }

    const handleDataChange = (event) => {
        let id = event.target.id
        let value = event.target.value
        console.log(event);
        setFormData({
            ...form_data,
            [id]: value
        })
    }

    const handlePositionChange = async (value) => {
        setIsLoading(true)
        let data = LocalStorage.getSelectedCommitteeTambon()
        var parameter = JSON.parse(data)
        let filter = "&pagination[pageSize]=100&filters[rcc][province_id][$eq]=" + parameter.province_code
            + "&filters[rcc][amphur_id][$eq]=" + (parameter.amphur_code.slice(-2))
            + "&filters[rcc][tambon_id][$eq]=" + (parameter.tambon_code.slice(-2))
            + "&filters[rice_community_position][id][$eq]=" + value
        let member_list = await getMemberByID(filter, "")
        setFarmerDropdown([])
        if (member_list.data.length === 0) {
            alert("ไม่พบเกษตรตามเงื่อนไขที่ท่านเลือก \n กรุณาค้นหาอีกครั้ง")
        } else {
            let temp_list = []
            for (let row_data of member_list.data) {
                temp_list.push({
                    id: row_data.id,
                    label: row_data.attributes.farmer[0].personal_name + " " + row_data.attributes.farmer[0].personal_surname + "(" + row_data.attributes.rcc.data.attributes.name_center + ")",
                    uid: row_data.attributes.personal_uid,
                    rcc_name: row_data.attributes.rcc.data.attributes.name_center,
                    rcc_id: row_data.attributes.rcc.data.id
                })
            }
            setFarmerDropdown(temp_list)
        }
        setFormData({
            ...form_data,
            ['selected_position']: value
        })
        setIsLoading(false)
    }

    const handleFarmerSearch = async () => {
        let data = LocalStorage.getSelectedCommitteeTambon()
        var parameter = JSON.parse(data)
        let filter = "&filters[rcc][province_id][$eq]=" + parameter.province_code
            + "&filters[rcc][amphur_id][$eq]=" + (parameter.amphur_code.slice(-2))
            + "&filters[rcc][tambon_id][$eq]=" + (parameter.tambon_code.slice(-2))
        let search_result = await searchCommitteByCitizenID(form_data.farmer_search_keyword, filter)
        console.log(search_result);
        if (search_result.error !== "") {
            alert(search_result.error)
        } else {
            setFormData({
                ...form_data,
                ['farmer_result_name']: search_result.farmer_result_name,
                ['farmer_result_rcc_name']: search_result.farmer_result_rcc_name,
                ['farmer_result_UID']: search_result.farmer_result_UID,
                ['farmer_result_RCC_ID']: search_result.farmer_result_RCC_ID,
            })
            // console.log(form_data);
        }
    }

    const handleSelectedFarmer = async (value) => {
        console.log(value);
        setFormData({
            ...form_data,
            ['farmer_result_name']: value.label,
            ['farmer_result_rcc_name']: value.rcc_name,
            ['farmer_result_UID']: value.uid,
            ['farmer_result_RCC_ID']: value.rcc_id,
        })
    }

    const handleSaveCommitteeButton = () => {
        console.log(form_data.selected_position);
        if (form_data.selected_position === 0) {
            alert("คุณยังไม่ได้เลือกตำแหน่ง \n กรุณาเลือกตำแหน่งอีกครั้ง")
        } else if (form_data.farmer_result_UID === "") {
            alert("คุณยังไม่ได้ค้นหาเกษตรกร \n กรุณาค้นหาอีกครั้ง")
        } else {
            let data = LocalStorage.getSelectedCommitteeTambon()
            var parameter = JSON.parse(data)
            let add_object = {
                data: {
                    province_id: parameter.province_code,
                    province_name: parameter.province_name,
                    amphur_id: parameter.amphur_code.slice(-2),
                    amphur_name: parameter.amphur_name,
                    tambon_id: parameter.tambon_code.slice(-2),
                    tambon_name: parameter.tambon_name,
                    farmer_uid: form_data.farmer_result_UID,
                    rcc: form_data.farmer_result_RCC_ID,
                    rice_community_position: form_data.selected_position,
                }
            }
            console.log(add_object);
            addRCCCommittee(add_object)
                .then(res => {
                    alert("เพิ่มกรรมการสำเร็จ")
                    handleCloseCommitteeForm()
                    handleCloseCommitteeListForm()
                    initPage()
                })
        }
    }

    const handleBackClick = () => {
        window.location.href = "/rcc/committe/tambon";
    }

    const handleDeleteClick = (event, cellValues) => {
        let selected_data = (findValueInDict(committee_list, cellValues.row.id));
        setDialogTitle("ลบคณะกรรมการศูนย์ข้าว")
        setDialogContent("คุณต้องการลบ " + selected_data.name + " ตำแหน่ง " + selected_data.position + " ออกจากคณะกรรมการศูนย์ข้าวชุมชน ระดับตำบลหรือไม่")
        setConfirmLabel("ลบข้อมูล")
        setConfirmationMode("delete")
        setSelectedId(cellValues.row.id)
        setIsOpenConfirmationBox(true)
    }

    const handleConfirm = async () => {
        if (confirmation_mode === "delete") {
            console.log(selected_id);
            await deleteRCCCommittee(selected_id)
            alert("ลบข้อมูลสำเร็จ")
            setIsOpenConfirmationBox(false)
            setIsLoading(true)
            initPage()
            setIsLoading(false)
        }
    }

    const initPage = async () => {
        setFormData({
            farmer_result_name: "",
            farmer_result_rcc_name: "",
            farmer_result_UID: "",
            farmer_result_RCC_ID: "",
            selected_position: 0,
            farmer_search_keyword: "",
        })
        let data = LocalStorage.getSelectedCommitteeTambon()
        if (data === null) {
            window.location.href = "/rcc/committe/tambon";
        } else {
            var parameter = JSON.parse(data);
            let label = "ตำบล " + parameter.tambon_name + ", " + parameter.amphur_name + ", จังหวัด" + parameter.province_name
            let filter = "&filters[province_id][$eq]=" + parameter.province_code
                + "&filters[amphur_id][$eq]=" + (parameter.amphur_code.slice(-2))
                + "&filters[tambon_id][$eq]=" + (parameter.tambon_code.slice(-2))
            let committ_list = await getCommittee(filter)
            label += " จำนวนทั้งหมด " + committ_list.data.meta.pagination.total + " คน"
            setSubTitle(label)
            setCommitteeListCount(committ_list.data.meta.pagination.total)
            setCommitteeListPageCount(committ_list.data.meta.pagination.pageSize)
            let res_data = committ_list.data.data
            let temp_list = []
            let index = 0
            for (let row_data of res_data) {
                index += 1
                temp_list.push({
                    index: index,
                    id: row_data.id,
                    position: row_data.attributes.rice_community_position.data.attributes.community_position_name,
                    name: row_data.attributes.farmer[0].personal_name + " " + row_data.attributes.farmer[0].personal_surname, rcc_name: row_data.attributes.rcc.data.attributes.name_center,
                })
            }
            setCommitteeList(temp_list)
            let member_poistion = await getMemberPosition()
            let temp_position_list = []
            res_data = member_poistion.data.data
            for (let row_data of res_data) {
                let check_position = temp_list.find((committee_data) => committee_data.position === row_data.attributes.community_position_name)
                if (check_position === undefined) {
                    temp_position_list.push({
                        id: row_data.id,
                        name: row_data.attributes.community_position_name,
                    })
                }
            }
            setPositionDropdown(temp_position_list)
        }
    }

    useEffect(async () => {
        setIsLoading(true)
        initPage()
        setIsLoading(false)
    }, [])

    return (
        <div style={{ margin: '2rem' }}>
            <UtilityBackdrop
                open={is_loading}
            />
            <AdminLayout />
            <Card style={{ marginTop: '0rem', marginRight: '1%', marginLeft: '1%' }}>
                <CardContent>
                    <Grid
                        container
                        className='content-box'
                        spacing={1}
                        sx={{ mt: 5 }}
                    >
                        <Grid item xs={10}>
                            <Typography variant='h4'>รายชื่อคณะกรรมการศูนย์ข้าวชุมชนระดับตำบล</Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Button
                                variant="contained"
                                onClick={handleBackClick}
                            >
                                ย้อนกลับ
                            </Button>
                        </Grid>
                        <Grid item xs>
                            <Typography variant='h6'>{sub_title}</Typography>
                        </Grid>
                        <Grid item xs={2.5}>
                            <Button
                                variant="contained"
                                onClick={handleAddClick}
                            >
                                เพิ่มกรรมการโดยบัตรประชาชน
                            </Button>
                        </Grid>
                        <Grid item xs={2.5}>
                            <Button
                                variant="contained"
                                onClick={handleAddListClick}
                            >
                                เพิ่มกรรมการจากรายการ
                            </Button>
                        </Grid>
                        <Grid item xs={12}>
                            <RccCommitteeList
                                rows={committee_list}
                                row_count={committee_list_count}
                                page_count={committee_list_page_count}
                                handlePageSizeChange={handlePageSizeChange}
                                handlePageChange={handlePageChange}
                                handleDeleteClick={handleDeleteClick}
                            />
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
            <CommitteeCitizenIDForm
                data={form_data}
                is_open={is_add_committee_open}
                postion_dropdown={position_dropdown}
                handleCloseCommitteeForm={handleCloseCommitteeForm}
                handleDataChange={handleDataChange}
                handleFarmerSearch={handleFarmerSearch}
                handleSaveCommitteeButton={handleSaveCommitteeButton}
                handlePositionChange={handlePositionChange}
            />
            <CommitteeListForm
                data={form_data}
                is_open={is_add_committee_list_open}
                postion_dropdown={position_dropdown}
                handlePositionChange={handlePositionChange}
                handleSaveCommitteeButton={handleSaveCommitteeButton}
                handleCloseCommitteeForm={handleCloseCommitteeListForm}
                farmer_dropdown={farmer_dropdown}
                handleSelectedFarmer={handleSelectedFarmer}
            />
            <ConfirmationBox
                open={is_open_confirmation_box}
                setOpen={setIsOpenConfirmationBox}
                dialog_title={dialog_title}
                dialog_content={dialog_content}
                confirm_label={confirm_label}
                cancle_label={cancle_label}
                handleConfirm={handleConfirm}
            />
        </div>
    )
}