import React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
// import DialogActions from '@mui/material/DialogActions'
import Grid from '@mui/material/Grid'
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
// import Autocomplete from '@mui/material/Autocomplete'

export default function RccEquipmentListForm({
    is_open,
    handleCloseEquipmentForm,
    form_data,
    handleSaveEquipmentForm,
    equipment_dropdown,
    setData,
    button_mode,
    isDisabled,
}) {
    return (
        <>
            <Dialog
                open={is_open}
                // onClose={handleCloseEquipmentForm}
                fullWidth
                aria-labelledby="AddUser-Dialog-title"
                aria-describedby="AddUser-Dialog-Content"
                sx={{
                    "& .MuiDialog-container": {
                        "& .MuiPaper-root": {
                            width: "100%",
                            maxWidth: "800px",  // Set your width here
                        },
                    },
                }}
            >
                <DialogTitle id="AddUser-Dialog-title">{button_mode === "add" ? "เพิ่มอุปกรณ์" : "แก้ไขอุปกรณ์"}</DialogTitle>
                <DialogContent id="AddUser-Dialog-Content">
                    <Grid container spacing={2} sx={{ mt: 1 }}>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-readonly-label">อุปกรณ์</InputLabel>
                                <Select
                                    labelId="select-label"
                                    id="equipment_id"
                                    value={form_data?.equipment_id || 0}
                                    variant="outlined"
                                    label="อุปกรณ์"
                                    fullWidth
                                    onChange={event => setData({
                                        target: {
                                            id: 'equipment_id',
                                            value: event.target.value
                                        }
                                    })}
                                    disabled={isDisabled}
                                >
                                    <MenuItem key={0} value={0} disabled>กรุณาเลือกอุปกรณ์</MenuItem>
                                    {
                                        equipment_dropdown.map((item, index) => {
                                            return (<MenuItem key={index + 1} value={item.id}>{item.name}</MenuItem>)
                                        })
                                    }
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                id="equipment_quantity"
                                label="จำนวน"
                                type="number"
                                variant="outlined"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={form_data?.equipment_quantity || ''}
                                placeholder={'0'}
                                onChange={event => setData(event)}
                                disabled={isDisabled}
                            />
                        </Grid>
                        {/* <Grid item xs={6}>
                            <TextField
                                fullWidth
                                id="equipment_registation_year"
                                label="ได้รับเมื่อปี"
                                type="number"
                                variant="outlined"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={form_data.equipment_registation_year}
                                onChange={event => setData(event)}
                            />
                        </Grid> */}
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                id="equipment_acquired_project"
                                label="ได้รับการสนับสนุนจากโครงการ"
                                variant="outlined"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={form_data?.equipment_acquired_project || ''}
                                onChange={event => setData(event)}
                                disabled={isDisabled}
                            />
                        </Grid>
                    </Grid>

                    <Grid
                        container
                        spacing={2}
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="center"
                        sx={{ mt: 2 }}
                    >
                        <Grid item ></Grid>
                        <Grid item display={isDisabled && 'none'}>
                            <Button
                                variant="contained"
                                color="success"
                                onClick={handleSaveEquipmentForm}
                                disabled={isDisabled}
                            >
                                {button_mode === "add" ? "เพิ่มอุปกรณ์" : "แก้ไขอุปกรณ์"}
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                variant="contained"
                                color="error"
                                onClick={handleCloseEquipmentForm}
                            >
                                ยกเลิก
                            </Button>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </>
    )
}