import React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import IconButton from '@mui/material/IconButton';
import { Edit, Visibility, Delete } from '@mui/icons-material';

export default function RccEquipmentList({
    rows,
    row_count,
    page_count,
    handlePageSizeChange,
    handlePageChange,
    handleDeleteEquipmentList,
    handleEditEquipmentList,
    isDisabled
}) {
    const columns = [
        {
            field: 'index',
            headerName: 'ลำดับ',
            headerAlign: 'center',
            maxWidth: 55,
            flex: 1,
            align: 'center',
        },
        {
            field: 'equipment_name',
            headerName: 'อุปกรณ์/เครื่องจักรกลการเกษตร',
            minWidth: 75,
            flex: 1
        },
        {
            field: 'equipment_number',
            headerName: 'จำนวน',
            maxWidth: 75,
            flex: 1
        },
        {
            field: 'equipment_registation_year',
            headerName: 'ปีที่ได้รับ',
            maxWidth: 75,
            flex: 1
        },
        {
            field: 'equipment_acquired_project',
            headerName: 'โครงการที่จัดซื้อ',
            minWidth: 125,
            flex: 1
        },
        {
            field: "edit",
            headerName: isDisabled ? "ดู" : "แก้ไข",
            headerAlign: 'center',
            align: 'center',
            sortable: false,
            maxWidth: 55,
            flex: 1,
            disableClickEventBubbling: true,
            renderCell: (cellValues) => {
                return <IconButton
                    color="secondary"
                    aria-label="add an alarm"
                    onClick={(event) => {
                        handleEditEquipmentList(event, cellValues);
                    }} >
                    {isDisabled ? <Visibility sx={{ color: 'green' }} /> : <Edit sx={{ color: 'green' }}/>}
                </IconButton>
            }
        },
        {
            field: "delete",
            headerName: "ลบ",
            headerAlign: 'center',
            align: 'center',
            sortable: false,
            maxWidth: 55,
            flex: 1,
            hide: isDisabled,
            disableClickEventBubbling: true,
            renderCell: (cellValues) => {
                return <IconButton
                    color="secondary"
                    aria-label="add an alarm"
                    onClick={(event) => {
                        handleDeleteEquipmentList(event, cellValues);
                    }} >
                    <Delete sx={{ color: 'green' }}/>
                </IconButton>
            }
        }
    ];

    return (
        <div style={{ height: 400, width: '100%', marginTop: '0rem' }}>
            <DataGrid
                style={{ backgroundColor: "#FFFFFF" }}
                rows={rows}
                rowCount={row_count}
                columns={columns}
                pageSize={page_count}
                onPageSizeChange={(newPageSize) => handlePageSizeChange(newPageSize)}
                onPageChange={(newPage) => handlePageChange(newPage)}
                // rowsPerPageOptions={[]}
                disableColumnMenu
            />
        </div>
    )
}